.cart {

  &__banner {
    background-color: $banner;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center center;

    &__links {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 30px;

      @media all and (max-width: 1100px) {
        padding-top: 0;
      }

      &__text {
        color: $black;
        font-size: 13px;
        font-weight: 700;
      }

      &__title {
        color: $white;
        font-size: 50px;
        font-weight: 800;
        padding: 100px 0;

        @media all and (max-width: 1100px) {
          padding: 30px 0 20px;
          margin-top: 60px;
          font-size: 23px;
          line-height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {

    &__banner {

      &__links {
        padding: 90px 0 0;
      }
    }
  }

  &__aux {
    padding: 60px 0 0 0;
    margin: 0 auto 100px auto;

    @media all and (max-width: 1100px) {
      padding-top: 0;
    }

    &__row {
      @media all and (max-width: 1100px) {
        flex-direction: column-reverse !important;
      }
    }

    &__block {
      width: 70%;

      @media all and (max-width: 1100px) {
        padding-top: 30px;
      }

      &__login {
        margin-top: 60px;

        @media all and (max-width: 1100px) {
          margin-top: 0;
        }
      }

      @media all and (max-width: 1100px) {
        width: 100%;
      }

      &__login {
        display: block;

        &__active {
          display: none;
        }
      }

      &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 30px;
        color: $black;
        font-weight: 800;
        margin: 0;

        @media all and (max-width: 1100px) {
          font-size: 23px;
        }

        &__active {
          display: none;
        }
      }

      &__title-payment {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 30px;
        color: $black;
        font-weight: 800;
        margin: 0 0 20px 0;

        @media all and (max-width: 1100px) {
          font-size: 23px;
          /*  margin-top: -150px;
            padding-top: 150px;*/
        }

        &__active {
          display: none;
        }
      }

      &__title-resume {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 30px;
        color: $black;
        font-weight: 800;
        margin: 0 0 20px 0;

        @media all and (max-width: 1100px) {

          &__wrapper {
            padding-top: 150px;
            margin-top: -150px;
          }
        }

        &__active {
          display: none;
        }
      }


      &__image {
        margin: 0 15px 0 15px;
      }

      &__create {
        display: block;
        padding-top: 15px;

        &__active {
          display: none;
        }

        &__title {
          margin: 35px 0 15px 0;
          font-size: 16px;
          font-weight: 700;
          color: $dark-border;
        }

        &__form {
          width: 100%;

          &__input {
            display: flex;
            width: 100%;

            @media all and (max-width: 1100px) {
              flex-direction: column;
            }

            .advanced-input {
              padding-bottom: 0;
              width: 100%;
              margin: 15px 15px 0 0;
              border-radius: 10px;
            }

            .advanced-masked-input {
              padding-bottom: 0;
              width: 100%;
              margin: 15px 15px 0 0;
              border-radius: 10px;
            }
          }

          &__upload {
            // margin: 60px 0 0 0;

            &__title {
              color: $black;
              font-size: 30px;
              font-weight: 700;
              margin: 0;

              &__left {
                padding-left: 10px;
              }
            }

            &__message {
              padding: 15px 0;

              &__button {
                padding-right: 15px;
              }
            }

            &__text {
              font-size: 16px;
              font-weight: 400;
              color: $gray-product;
              margin: 10px 0;
              width: 100%;
            }

            .ant-upload.ant-upload-select.ant-upload-select-text {
              width: 100% !important;
              padding-right: 15px;
            }

            button.ant-btn.ant-btn-icon-only {
              width: 100%;
            }
          }

          &__atention {
            padding: 1px 14px;
            background-color: $gray-cart;
            border-radius: 18px;
            width: calc(100% - 15px);
            margin: 30px 0;

            &__phrase {
              display: block;
              margin-top: -15px;
              padding-bottom: 30px;
            }

            &__title {
              color: $gray-atention;
              font-size: 20px;
              font-weight: 700;

              img {
                margin: -4px 10px 0 0;
              }
            }

            &__description {
              color: $gray-atention;
              font-size: 13px;
              font-weight: 400;

              &__wrapper {
                display: none;
              }
            }
          }

          &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 30px 15px 0 0;

            @media all and (max-width: 1100px) {
              padding-right: 0;
            }

            &__left {

              @media all and (max-width: 1100px) {
                margin-top: -16px;
              }
            }

            &__next {
              background-color: $primary-color;
              padding: 8px 22px;
              border-radius: 4px;
              color: $white;
              font-weight: 500;
              font-size: 16px;
              text-transform: uppercase;
              cursor: pointer;

              @media all and (max-width: 1100px) {
                display: flex;
                flex-direction: row;
              }
            }
          }
        }
      }

      &__payment {
        display: block;

        @media all and (max-width: 1100px) {
          margin-top: -150px;
          padding-top: 150px;
        }

        &__active {
          display: none;
        }

        &__title {
          margin: 35px 0 15px 0;
          font-size: 16px;
          font-weight: 700;
          color: $dark-border;
        }

        &__form {
          width: 100%;

          &__input {
            display: flex;
            width: 100%;

            @media all and (max-width: 1100px) {
              width: 100%;
              flex-direction: column;
            }

            .advanced-input {
              padding-bottom: 0;
              width: 100%;
              margin: 15px 15px 0 0;
              border-radius: 10px;
            }

            select {
              height: 56px;
              margin: 15px 15px 0 0;
              width: 100%;
              border-radius: 3px;
              padding: 0 15px 0 15px;
              font-size: 18px;
            }
          }

          &__input-cep {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 80%;

            @media all and (max-width: 1100px) {
              display: block;
              width: 100%;
              padding-right: 15px;
            }

            &__cep {
              color: $primary-color;
              font-size: 14px;
              font-weight: 600;
              text-decoration-line: underline;
              margin: 0 0 0 30px;

              @media all and (max-width: 1100px) {
                margin: 5px 0 20px;
                display: block;
              }
            }
          }

          &__buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            margin: 30px 0 0 0;

            @media all and (max-width: 1100px) {
              width: 100%;
              padding-right: 0;
              display: flex;
              flex-direction: column;
              margin-top: 0;
            }

            &__next {

              @media all and (max-width: 1100px) {
                width: 100%;
              }

              &__error {
                font-size: 10pt;
                line-height: 10pt;
                text-align: center;
                padding-top: 5px;
              }
            }
          }

          &__obs {
            width: 100%;
            margin: 10px 0;
            font-size: 12px;
            font-weight: 400;
            color: $black;

            @media all and (max-width: 1100px) {
              text-align: center;
              width: 100%;
              padding-right: 15px;
            }
          }

          &__title-address {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 30px;
            color: $black;
            font-weight: 800;
            margin: 55px 0 0;

            @media all and (max-width: 1100px) {
              font-size: 23px;
            }
          }

          &__title-desc {
            margin-bottom: 30px;
          }
        }
      }

      &__resume {
        display: block;

        &__active {
          display: none;
        }

        &__block {
          margin: 0 0 30px 0;

          &__aux {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media all and (max-width: 1100px) {
              display: block !important;
              margin-bottom: 10px !important;
            }

            &__text {
              margin: 0 0 0 5px;
              font-size: 20px;
              font-weight: 300;
              color: $gray-product;

              @media all and (max-width: 1100px) {
                font-size: 15px;
                font-weight: 20px;
              }
            }
          }
        }

        &__buttons {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          width: 80%;
          margin: 50px 0;

          @media all and (max-width: 1100px) {
            width: 100%;

            .advanced-button {
              width: 300px !important;
            }
          }
        }
      }

      &__buttons {
        display: flex;
        justify-content: flex-start;
        margin: 45px 0 0 0;

        @media all and (max-width: 1100px) {
          justify-content: center;
          margin-top: 15px;
        }

        &__active {
          display: none;
        }

        &__login {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 65px;
          background-color: $primary-color;
          color: $white;
          border-radius: 10px;
          font-size: 15px;
          margin: 0 20px 0 0;
          border: none;
          font-weight: 800;
          cursor: pointer;

          @media all and (max-width: 1100px) {
            height: 45px;
          }
        }

        &__create {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 65px;
          background-color: $white;
          color: $primary-color;
          border-radius: 10px;
          font-size: 15px;
          margin: 0 20px 0 0;
          border: 1px solid $primary-color;
          font-weight: 800;
          cursor: pointer;

          @media all and (max-width: 1100px) {
            height: 45px;
          }
        }
      }

      &__checkout {
        margin: 35px 0 0 0;

        &__blue {
          background-color: #32719A;
          border: none;
          border-radius: 10px;
          padding: 30px;

          &__title {

            &__label {
              display: flex;
              align-items: flex-start;
              cursor: pointer;
            }

            &__checkbox {
              width: 28px;
              height: 27px;
              border-radius: 5px;
              border: none;
              margin: 2px 10px 0 0;
            }

            &__text {
              color: $white;
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;

              @media all and (max-width: 1100px) {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }

          &__description {
            display: flex;
            margin: 0 0 0 30px;

            &__white {
              color: $white;
              font-size: 16px;
              line-height: 19px;
              margin: 0 2px;

              @media all and (max-width: 1100px) {
                font-size: 15px;
                line-height: 20px;
              }
            }

            &__gold {
              color: $gold;
            }

            &__link {
              text-decoration: underline;
              color: $white;
              font-weight: 600;
              margin: 20px 0 0 30px;
              font-size: 16px;
            }
          }
        }

        &__white {
          border: 2px solid $gray-cart;
          border-radius: 10px;

          &__inner {
            padding: 0 30px 30px;

            @media all and (max-width: 1100px) {
              padding-top: 0;
            }
          }

          .amount-selection__actions {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 132px;
            height: 42px;
            border-radius: 200px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 1px 0 0 0;
            background: $white;
          }

          button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-containedSizeLarge.MuiButton-sizeLarge {
            background: transparent;
            color: black;
            width: 0;
            border: none;
            box-shadow: none;
            margin: -9px;
          }

          .MuiButton-containedSizeLarge {
            padding: 0;
            font-size: 22px;
            font-weight: 700;
          }

          input.amount-selection__amount__value__input {
            border: none;
            padding: 0;
            text-align: center;
          }

          &__product {
            border-bottom: 2px solid $gray-cart;
            display: flex;
            justify-content: space-between;

            @media all and (max-width: 1100px) {
              display: block;
            }

            &__aux {
              padding: 30px 0;

              &__first {
                display: flex;

                @media all and (max-width: 1100px) {
                  justify-content: space-between;
                  text-align: right;
                }

                &__img {
                  width: 50px !important;
                  height: 100px !important;
                  display: block !important;
                  background-size: contain;
                  background-position: center center;
                  background-repeat: no-repeat;
                }

                &__aux {
                  margin: 0 0 0 30px;

                  @media all and (max-width: 1100px) {
                    .amount-picker {
                      margin: 0 0 0 auto !important;
                    }
                  }

                  &__text {
                    font-size: 18px;
                    font-weight: 300;
                    color: $gray-product;
                  }
                }
              }

              &__second {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                padding: 30px 0;

                @media all and (max-width: 1100px) {
                  padding: 0;
                }

                @media all and (max-width: 1100px) {
                  display: block;
                  text-align: center;
                }

                &__values {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  &__old {
                    padding-right: 15px;

                    @media all and (max-width: 1100px) {
                      padding: 0;
                      text-align: right;
                    }
                  }

                  @media all and (max-width: 1100px) {
                    padding: 0 0 15px 0;
                    text-align: right;
                  }

                  &__old-value {
                    font-size: 18px;
                    font-weight: 500;
                    color: $dark-border;
                    margin: 0 15px 0 0;
                    text-decoration: line-through;

                    @media all and (max-width: 1100px) {
                      margin-right: 0;
                    }
                  }

                  &__new-value {
                    font-size: 18px;
                    font-weight: 700;
                    color: $dark-border;
                    margin: 0;
                  }
                }

                &__remove {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border: 1px solid $gray-product;
                  border-radius: 17px;
                  width: 108px;
                  height: 33px;
                  padding: 0 15px;
                  font-size: 12px;
                  font-weight: 700;
                  color: $gray-product;
                  background-color: white;
                  cursor: pointer;

                  @media all and (max-width: 1100px) {
                    display: block !important;
                    margin: 25px 0 0 auto !important;
                    width: 95px;
                    font-size: 11px;

                    & > img {
                      position: relative;
                      top: -1px;
                      right: -5px
                    }
                  }
                }
              }
            }
          }

          &__subtotal {
            border-bottom: 2px solid $gray-cart;
            padding: 30px 0;

            @media all and (max-width: 1100px) {
              padding-top: 0;
            }

            &__aux {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &__coupon {
                text-align: right;

                &__button {
                  padding-top: 10px;

                  &--empty {
                    padding-bottom: 20px;
                  }
                }
              }

              @media all and (max-width: 1100px) {
                display: block;
              }

              &__text {
                font-size: 18px;
                font-weight: 300;
                color: $dark-border;

              }

              &__text-value {
                font-size: 18px;
                font-weight: 500;
                color: $dark-border;
                position: relative;

                &__remove {
                  position: absolute;
                  top: 7px;
                  right: -20px;
                  border: none;
                  background-color: $red;
                  color: white;
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                  outline: 0;
                  text-align: center !important;
                  font-weight: bold;
                  padding: 0;
                  cursor: pointer;

                  &__inner {
                    position: relative;
                    top: -5px;
                  }
                }

                @media all and (max-width: 1100px) {
                  margin-top: -20px;
                }
              }
            }
          }

          &__total {
            padding: 30px 0 0;

            &__aux {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              &__text {
                font-size: 18px;
                font-weight: 400;
                color: $dark-border;
              }

              &__text-value {
                font-size: 50px;
                font-weight: 500;
                color: $dark-border;
                margin-bottom: 0;

                @media all and (max-width: 1100px) {
                  font-size: 23px;
                }
              }

              &__text-installments-value {
                font-size: 15px;
                font-weight: 500;
                color: $dark-border;
                margin-bottom: 0;

                &--small {
                  font-size: 13pt;
                }

                @media all and (max-width: 1100px) {
                  font-size: 23px;
                }
              }

              &__text-value-cash {
                font-size: 20px;
                font-weight: 500;
                color: $dark-border;
                margin-bottom: 0;

                @media all and (max-width: 1100px) {
                  font-size: 23px;
                }
              }
            }
          }
        }
      }

      &__two {
        width: 100%;

        @media all and (min-width: 1100px) {
          padding-top: 60px;
        }

        &__title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 30px;
          color: $black;
          font-weight: 800;

          @media all and (max-width: 1100px) {
            font-size: 23px;
            margin-top: 30px;
          }
        }
      }
    }
  }

  .disable {
    background-color: $button-next;
    padding: 8px 22px;
    border-radius: 4px;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    cursor: none;
    pointer-events: none;
  }

  .disable-confirm {
    background-color: $white;
    padding: 0;
    color: $white;
    cursor: none;
    pointer-events: none;
  }

  .none {
    display: none;
  }
}
