.order-bump {
    padding-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__banner {
        position: relative;
        max-width: 1265px;
        width: 60%;
        min-width: 1000px;

        @media all and (max-width: 1100px) {
            min-width: 100px;
            width: 90%;
        }
        

        &__img {
            width: 100%
        }

        &__ghost-button {
            cursor: pointer;
            width: 34%;
            height: 53px;
            position: absolute;
            top: 60%;
            left: 62%;

            @media all and (max-width: 1100px) {
                width: 100%;
                height: 22%;
                top: 77%;
                left: 0%;
            }
        }
    }
}