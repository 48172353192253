.recurrence {
  padding: 0;

  &__header {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../../assets/jpg/img_banner_recurrence.jpg);
    height: 800px;
    position: relative;
    margin-bottom: 60px;
    background-position: center right;
    background-color: #bac7cb;

    @media all and (max-width: 1100px) {
      height: auto;
      background-image: none;
    }

    &__phrase {
      background-color: #8BA2AB;

      &__inner {
        font-family: 'Montserrat', sans-serif;
        font-size: 13pt;
        text-align: center;
        color: white;
        padding: 25px 0;
        text-transform: uppercase;

        @media all and (max-width: 1100px) {
          font-size: 12px;
          padding: 15px;
        }
      }
    }

    &__inner {

      &__row {
        @media all and (max-width: 1100px) {
          display: flex;
          flex-direction: column-reverse;
        }
      }

      &__text {
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__img {
          padding-top: 30px;

          &__img {
            width: 100%;
          }
        }

        @media all and (max-width: 1100px) {
          height: auto;
          padding-top: 30px;
        }

        &__title {
          font-family: 'Roboto', sans-serif;
          font-size: 45px;
          line-height: 55px;
          color: #131D33;
          font-weight: bold;

          @media all and (max-width: 1100px) {
            font-size: 23px;
            line-height: 33px;
          }
        }

        &__desc {
          font-family: 'Roboto', sans-serif;
          font-size: 13pt;
          color: #3E3E3E;
          padding: 30px 0;
          white-space: pre-wrap;

          @media all and (max-width: 1100px) {
            white-space: initial;
            font-size: 15px;
            line-height: 20px;
          }
        }

        &__button {
          padding: 30px 0;

          @media all and (max-width: 1100px) {
            padding-bottom: 60px;
          }
        }
      }

      &__image {
        height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media all and (max-width: 1100px) {
          height: auto;
          padding-top: 30px;
        }

        &__img {
          max-width: 100%;
        }
      }
    }
  }


  &__banner {
    background-image: url('../../../assets/jpg/banner_recurrence.jpg');
    width: 100%;
    height: 65vw;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 3vw 0 0;

    &__links {
      margin: 0 0 0 50px;

      &__link {
        font-size: 16px;
        color: $black;
        font-weight: 700;
        margin: 0;
      }
    }

    &__title {
      margin: 0 auto 0 auto;
      color: $dark-blue;
      font-size: 60px;
      text-align: center;
    }

    &__description {
      width: 30%;
      margin: 0 auto;
      color: $text-product;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      font-style: italic;
    }

    &__button {
      margin: 20px auto 0 auto;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
      background: $button-recurrence;
      width: 260px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 18px;
      font-weight: 700;
      border-radius: 10px;
    }
  }


  @media screen and (max-width: 995px) {

    &__banner {
      height: 75vw;
      background-size: contain;
      padding: 5px 0 0;

      &__title {
        font-size: 30px;
        margin: 30px 0 0 0;
      }

      &__description {
        width: 96%;
        font-size: 14px;
      }

    }

    &__blue-banner {
      text-align: center;
      padding: 18px 20px;
    }
  }

  &__blue-banner {
    background-color: $blue-banner;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-weight: 300;
  }

  &__percent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 70px 0;
    width: 87%;
    margin: 0 auto;

    &__aux-title {
      width: 25%;

      &__title {
        font-weight: 700;
        font-size: 32px;
        color: $blue-banner;
        margin: 0 0 20px 0;
        line-height: 37px;
      }

      &__description {
        font-weight: 300;
        font-size: 15px;
        margin: 0;
        color: $product-cart;
      }
    }

    &__checkbox {
      width: 70%;
      margin: 0 0 0 50px;
      position: relative;

      &__element {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 190px;
        border-radius: 10px;
        border: 4px solid $light-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 22px;
        font-weight: 700;
        background-color: $background-checkbox;
        border: 2px solid $background-third-hair-kit;
        margin: 0 30px 0 0;
        transition: all 0.3s ease-out;
        text-align: center;
        padding: 0 20px;
      }

      &__title {
        font-size: 70px;
        font-weight: 700;
        color: $dark-blue;
      }

      &__description {
        font-size: 20px;
        font-weight: 400;
        color: $dark-blue;
      }
    }
  }

  @media screen and (max-width: 995px) {

    &__percent {
      flex-direction: column;
      width: 90%;

      &__aux-title {
        width: 100%;
      }

      &__checkbox {
        width: 100%;
        margin: 0 auto;

        &__element {
          height: auto;
          margin: 30px 0;
        }
      }
    }
  }

  &__blue {
    background-color: #EEF0F2;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    text-align: center;

    @media all and (max-width: 1100px) {
      display: block;
      padding: 60px 0;
      margin: 50px 0;
    }

    &__title {
      font-weight: 700;
      font-size: 50px;
      color: #0F172A;
      margin: 0 70px 0 0;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        text-align: center;
        margin: 0;
      }
    }

    &__description {
      font-weight: 300;
      font-size: 18px;
      color: #3E3E3E;
      margin: 20px 0;
      text-align: center;
      white-space: pre-wrap;

      @media all and (max-width: 1100px) {
        font-size: 15px;
        line-height: 20px;
      }
    }

    &__description-bottom {
      font-weight: 300;
      font-size: 16px;
      color: #3E3E3E;
    }

    &__description-small {
      font-weight: 300;
      font-size: 12.5px;
      color: #3E3E3E;
    }
  }

  &__experience {
    padding: 60px 0;
    margin: 0 auto;

    &__title {
      font-family: 'Montserrat', sans-serif;
      font-size: 50px;
      font-weight: 700;
      color: $blue-banner;
      text-align: center;
      margin-bottom: 30px;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        line-height: 30px;
      }

      &__blue {
        color: #82A0B3;
        font-size: 23px;
      }
    }

    &__item {

      &__single {
        padding: 0 15px;

        @media all and (max-width: 1100px) {
          padding: 0;
        }

        &__image {

          &__img {
            width: 100%;
          }
        }

        &__title {
          font-family: 'Montserrat', sans-serif;
          font-weight: bold;
          font-size: 20px;
          padding: 15px 0;

          @media all and (max-width: 1100px) {
            font-size: 23px;
            line-height: 30px;
          }
        }

        &__desc {
          font-size: 16px;

          @media all and (max-width: 1100px) {
            font-size: 15px;
            line-height: 20px;
            padding-bottom: 30px;
          }
        }
      }
    }

    &__bottom {
      text-align: center;
      padding-top: 80px;

      &__title {
        font-size: 16px;
        color: #575757;
        padding-bottom: 15px;

        @media all and (max-width: 1100px) {
          font-size: 15px;
          line-height: 20px;
        }
      }

      &__link {

        &__button {
          font-size: 20px;
          font-weight: bold;
          background-color: transparent;
          display: inline-block;
          margin: auto;
          border: none;
          outline: 0;
          color: #2E5BC4;
          text-decoration: underline;
          transition: color ease-in-out 200ms;
          cursor: pointer;

          &:hover {
            color: lighten(#2E5BC4, 10);
          }
        }
      }
    }
  }

  &__products {
    padding: 60px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__cart {
      padding: 0;
      border: 1px solid $border-cart;
      border-radius: 10px;
      box-sizing: border-box;
      background-color: $white;
      height: auto;

      &__header {
        background-color: $header-cart;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        &__check {
          background: $white;
          border: 1px solid #CFCFCF;
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          width: 16px;
          height: 16px;
        }

        &__text {
          color: $dark-border;
          font-size: 18px;
          margin: 0 0 0 10px;
          font-weight: 500;
        }
      }

      &__body {
        padding: 33px;

        &__title {
          font-weight: 300;
          font-size: 15px;
          color: $dark-border;
          border-bottom: 1px solid $gray-text;
          padding: 0 0 5px 0;
        }

        &__block {
          padding: 10px 0 30px 0;
          border-bottom: 1px solid $gray-text;

          &__aux {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            &__name {
              font-size: 22px;
              font-weight: 300;
              color: $product-cart;
              margin: 0;
            }

            &__values {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              &__old {
                margin: 0 0 0 15px;
                color: $old-value;
                font-size: 15px;
                font-weight: 500;
                text-decoration: line-through;
              }

              &__current {
                margin: 0 0 0 15px;
                color: $old-value;
                font-size: 20px;
                font-weight: 700;
              }

              &__close {
                margin: 0 0 0 15px;
                cursor: pointer;
                color: $old-value;
                font-size: 15px;
                font-weight: 400;
              }
            }
          }
        }

        &__total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 25px 0 40px 0;
          border-bottom: 1px solid $gray-text;

          &__title {
            font-size: 22px;
            font-weight: 300;
            color: $product-cart;
            margin: 0;
          }

          &__values {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &__old {
              margin: 0 0 0 15px;
              color: $dark-border;
              font-size: 15px;
              font-weight: 500;
              text-decoration: line-through;
            }

            &__current {
              margin: 0 0 0 15px;
              color: $dark-border;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }

        &__freight {
          padding: 30px 0;
          border-bottom: 1px solid $gray-text;

          &__title {
            font-size: 16px;
            color: $product-cart;
            margin: 0 0 10px 0;
            font-weight: 300;
          }

          &__aux {
            display: flex;
            justify-content: space-between;
            align-items: center;

            input[type=text] {
              box-shadow: inset 1px 5px 7px -1px rgba(0, 0, 0, 0.15);
              border-radius: 10px;
              border: 1px solid $border-input;
              width: 335px;
              height: 58px;
            }

            input[type=button] {
              background-color: $primary-color !important;
              width: 135px;
              height: 58px;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $white;
              font-size: 16px;
              font-weight: 700;
              border: none;
              margin: 0 0 0 15px;
              cursor: pointer;
            }
          }
        }

        &__atention {
          padding: 40px 0 0 0;

          &__aux {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__description {
              color: $blue;
              font-size: 16px;
              font-weight: 500;
              margin: 0 0 0 10px;
            }
          }

          &__aux-input {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 15px 0 0 0;

            &__checkbox {
              background: $white;
              border: 1px solid #CFCFCF;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;
              width: 22.5px !important;
              height: 20px !important;
              margin: 4px 0px;
              border: none;
            }

            &__text {
              color: $blue;
              font-size: 16px;
              font-weight: 400;
              margin: 0 0 0 20px;
            }
          }

          &__button {
            width: 100%;
            height: 58px;
            margin: 40px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            background-color: $primary-color;
            font-size: 18px;
            font-weight: 700;
            box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
          }

          &__description {
            color: $gray-text;
            font-size: 12px;
            font-weight: 400;
            margin: 15px 0;
          }
        }
      }
    }

    &__blocks {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 0 0 60px;

      &__item {
        background-color: $background-item;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35.6%;
        height: auto;
        padding: 30px;
        margin: 0 20px 20px 20px;
        transition: all 0.5s ease-out;
        transform-style: preserve-3d;
        position: relative;

        &__aux {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          margin: 0;
          transform-style: preserve-3d;

          &__active {
            display: none;
          }

          &__title {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 300;
            font-size: 30px;
            margin: 0 0 15px 0;

            &__value {
              color: $dark-border;
              font-weight: 700;
              font-size: 24px;
            }
          }

          &__amount {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;

            &__i {
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              background-color: $white;
              width: 50px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              font-size: 30px;
              color: $black;
              font-family: 'Aleo';
              margin: 0 0 5px 0;
              position: absolute;
              bottom: -5%;
              right: -4%;
            }
          }

          img {
            width: 35%;
          }
        }

        &__aux-two {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          height: 100%;
          margin: 0;
          transition: all 0.5s ease-out;
          overflow: visible;
          opacity: 1;
          position: relative;
          z-index: 50;
          transition: transform 0.8s;
          padding: 15px;

          &__active {
            overflow: hidden;
            opacity: 0;
            position: absolute;
            transition: all 0.5s ease-out;
            z-index: -50;
            display: none;
          }

          &__title {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 300;
            font-size: 30px;
            margin: 0 0 15px 0;
            background: transparent;

            &__value {
              color: $dark-border;
              font-weight: 700;
              font-size: 24px;
            }
          }

          &__description {
            width: 100%;
            background-color: transparent;
            padding: 0;
          }

          &__amount {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__i {
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              background-color: $white;
              width: 45px;
              height: 45px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              font-size: 30px;
              color: $black;
              font-family: 'Aleo';
              margin: 18px 30px 5px 0;
              position: absolute;
              bottom: -5%;
              right: -15%;
            }
          }
        }
      }

      .amount-selection__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 132px;
        height: 42px;
        border-radius: 200px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 1px 0 0 0;
        background: $white;
      }

      button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-containedSizeLarge.MuiButton-sizeLarge {
        background: transparent;
        color: black;
        width: 0;
        border: none;
        box-shadow: none;
        margin: -9px;
      }

      .MuiButton-containedSizeLarge {
        padding: 0;
        font-size: 22px;
        font-weight: 700;
      }

      input.amount-selection__amount__value__input {
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 995px) {

    &__products {
      flex-direction: column;

      &__blocks {
        width: 100%;
      }

      &__cart {
        width: 100%;

        &__body {

          &__block {

            &__aux {
              flex-direction: column;
            }
          }

          &__freight {

            &__aux {
              flex-direction: column;

              input[type="button"] {
                width: 100%;
                margin: 20px 0;
              }
            }
          }
        }
      }
    }
  }

  &__ask-hair {
    width: 60%;
    margin: 60px auto;
    padding: 70px;
    border: 1px solid $border-ask;
    border-radius: 45px;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $title-ask;
      font-weight: 700;
      font-size: 55px;
      margin: 0;
      line-height: 75px;

      @media all and (max-width: 1100px) {
        text-align: center;
      }

      img {
        margin: 15px 0;
      }
    }

    .ant-collapse.ant-collapse-icon-position-left {
      width: 100%;
      border: none;
      background: transparent;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: $white;
      color: $title-ask;
      font-size: 16px;
      font-weight: 600;

    }

    .ant-collapse-content-box {
      background-color: $white;
    }
  }

  @media screen and (max-width: 995px) {

    &__ask-hair {
      width: 90%;
      margin: 40px auto 60px auto;
      padding: 20px;

      &__title {
        font-size: 23px;
        line-height: 33px;
      }
    }
  }
}

