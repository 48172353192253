.feature-section-full {
  padding: 180px 0;
  margin: 130px 0;
  position: relative;

  @media all and (min-width: 1100px) and (max-width: 1680px) {
    padding-bottom: 0;
  }

  @media all and (max-width: 1100px) {
    padding: 30px 0 45px;
    background-image: none !important;
    background-color: $feature-bg;
    margin: 0;
  }

  &__container {
    margin: 0 0 0 auto;
    padding-right: 0;

    &__wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      &__img {
        width: 70%;
        height: auto;
        display: block;
        margin: 15px auto 30px;
      }
    }

    &__inner {
      z-index: 50;

      &__title {
        font-size: 40pt;
        text-transform: uppercase;
        font-weight: bold;
        color: $dark-blue;

        @media all and (max-width: 1100px) {
          font-size: 25px;
          line-height: 36px;
        }
      }

      &__description {
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 18pt;
        padding-bottom: 60px;
        position: relative;
        z-index: 50;

        @media all and (max-width: 1100px) {
          font-size: 13px;
          padding-bottom: 30px;
        }
      }

      &__buttom {

        &__link {

          &__inner {
            font-size: 14pt;
            font-weight: bold;
            background-color: $primary-color;
            color: white;
            text-align: center;
            border-radius: 5px;
            padding: 12px 30px;
            transition: all ease-in-out .2s;

            @media all and (max-width: 1100px) {
              font-size: 12px;
            }

            &:hover {
              background-color: lighten($primary-color, 10);
            }
          }
        }
      }
    }

    @media screen and (max-width: 995px) {

      &__inner {
        position: relative;
        z-index: 50;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
  }
}
