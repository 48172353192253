.carousel-banner-home {
  width: 100.208vw;
  height: 62.5vh;
  background: linear-gradient(180deg, rgba(250,244,237,1) 0%, #FEFEFE 100%);
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 1100px) {
    height: 50%;
  }

  &__title {
    margin-top: 10vw;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;

    @media all and (max-width: 1100px) {
      margin-top: 44px;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }

  &__subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #000000;
    width: 60%;
    text-align: center;
    margin-bottom: 50px;

    @media all and (max-width: 1100px) {
      font-size: 14px;
      width: 67%;
      margin-bottom: 25px;
    }
  }

  &__button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #FFFFFF;
    background-color: #000000;
    border-radius: 50px;
    padding: 18px 110px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      padding: 15px 100px;
    }

    &:hover {
      color: #FFFFFF;
      background-color: #1D6EA3;
    }
  }

  &__button-chocosono {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #FFFFFF;
    background-color: #8B265D;
    border-radius: 50px;
    margin-bottom: 130px;

    @media all and (max-width: 1100px) {
      font-size: 4vw;
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 15vw;
      margin-bottom: 0;
      margin-top: 3%;
    }

    &:hover {
      color: #FFFFFF;
      opacity: 0.9;
    }
  }

  &__button-chocosono-desk {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #FFFFFF;
    background-color: #000000;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 132px;
    width: 27.2vw;
    height: 4.2vw;

    &:hover {
      color: #FFFFFF;
      opacity: 0.9;
    }
  }


  &__subtext {
    margin-top: 13px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    display: flex;
    color: #000000;
    margin-bottom: 176px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      margin-bottom: 0;
    }
  }
}