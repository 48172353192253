.subscription {
  width: 100%;
  display: flex;

  &__left {
    width: 68%;

    &__carousel {
      width: 100%;
      height: 80vh;

      @media all and (max-width: 1600px) {
        height: 70vh;
      } 

      &__image-container {
        height: 100%;

        &__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__clickable {
        display: flex;
        margin-left: 2rem;

        &__item {
          width: 3vw;
          height: 3vw;
          margin: 0 0.5rem;      
          margin-top: -10vh;

          &__img {
            width: 100%;
            height:100%;
            border-radius: 50%;
            cursor: pointer;
            image-rendering: -webkit-optimize-contrast;

            &--selected {
              box-shadow: 0px 4px 4px 0px #00000040;
              border: solid 1px white;
            }
          }
        }
      }
    }

    &__content-one {
      width: 100%;
      padding: 5vw 5.5vw;
      padding-bottom: 80px;
      background-color: #F8F8F8;

      &__image-container {

        &__img {
          width: 100%;
          image-rendering: -webkit-optimize-contrast;
        }
      }

      &__text {
        display: flex;
        justify-content: space-between;

        &__img {
          width: 100%; 
        }
      }

      &__button {
        margin: 0 auto;
        margin-top: 56px;
        width: 36%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 0.9vw;
        line-height: 27px;
        background-color: #000;
        border-radius: 50px;
        color: #fff;
        text-align: center;
        padding: 2% 0;
        cursor: pointer;
      }
    }

    &__content-two {
      padding: 4vw 5.5vw;
      padding-right: 0;

      &__title {
        font-size: 1.5vw;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        color: #000000;
        margin-bottom: 2.5vw;
      }

      &__subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        color: #474747;
        font-size: 1.1vw;
        margin-bottom: 1vw;
      }
      
      &__slider {

        &__item {
          width: 100%!important;
          padding-right: 2vw;
  
          &__img {
            width: 100%!important;
          }

          &__text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 1vw;
          }
        }

        .slick-list {
          padding-left: 0 !important;
        }

        .slick-dots > li.slick-active > button:before {
          color: black!important;
        }

        .slick-dots {
          text-align: left;
          bottom: -50px;

          li {
            width: 13px;
            height: 13px;
            margin-right: 3px;

            button {
              width: 13px;
              height: 13px;

              &:before {
                width: 13px;
                height: 13px;  
                line-height: 0;
                font-size: 13px !important;
              }
            }
          }
        }
      }

      &__slider-next {
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 40px;
        margin-right: 10%;
        height: 2.8vw;


        &__img {
          position: relative;
          z-index: 10;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    &__content-three {

      margin-top: 3vw;

      &__title {
        font-size: 1.5vw;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        color: #000000;
        margin-bottom: 2.5vw;
        text-align: center;
        margin-bottom: 0.2vw;
      }

      &__subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        color: #474747;
        font-size: 1vw;
        text-align: center;
        margin-bottom: 3vw;
      }

      &__dropdown {
        border-top: 1px solid #AEAEAE;
        margin: 0 5.5vw;

        &__item {
          padding: 1vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #AEAEAE;

          &__title {
            margin-bottom: 0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            color: #000;
            font-size: 1vw;
          } 

          &__text {
            margin-top: 1vw;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 0.9vw;
            color: #000;
          }

          &__img {
            width: 35%;
            padding: 0 1vw;
            padding-left: 4vw;
            margin-bottom: auto;
          }

          &__arrow {
            margin-bottom: auto;

            &--active {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &__content-four {
      width: 100%;
      margin-top: 5vw;

      &__img {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    &__money-back {
      position: relative;

      &__img {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
      }

      &__four {
        position: absolute;
        color: transparent;
        bottom: 10%;
        left: 44.5%;

        &:hover {
          color: transparent;
        }
      }
    }

    &__content-five {
      width: 100%;
      
      &__img {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    &__content-six {
      padding: 0 5.5vw;
      padding-bottom: 3vw;
      box-shadow: -3px 4px 24px 1px rgba(0, 0, 0, 0.1);

      &__title {
        font-size: 1.5vw;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        color: #000000;
        margin-bottom: 2.5vw;
      }

      &__slider {
        display: flex;
        justify-content: space-between;

        &__item {
          width: 32%;
          position: relative;

          &__img {
            width: 100%;
          }
          
          &__text {
            margin-top: 1vw;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 1.1vw;
            color: #000000;
          }

          a {
            position: absolute;
            bottom: -10px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 0.9vw;
            text-decoration-line: underline;
            color: #DFB485;
          }
        }
      }
    }
  }

  &__right {
    width: 32%;
    padding-top: 1vw;
    position: relative;
    box-shadow: -3px 4px 24px 1px rgba(0, 0, 0, 0.1);

    &__inner {
      padding: 10%;
      position: sticky;
      padding-bottom: 0;
      top: 0;

      &__top {
        padding-bottom: 2.5vw;

        &__title {
          display: flex;
          align-items: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 0.9vw;
          color: #9E9E9E;
          padding-bottom: 3px;
          margin-bottom: 0.7vw;

          &__bold {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 1.5vw;
            display: flex;
            align-items: center;
            padding-right: 10px;
            color: #2B2B2B;
            padding-bottom: 0;
          }

          &__star {
            font-size: 0.8vw;
            padding-left: 4px;
            color: #FFD704;
          }
        }

        &__price {
          margin-bottom: 1.7vw;

          &__text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 1vw;
            display: flex;
            align-items: center;
            color: #474747;
            margin-bottom: 0;
          }

          &__value {
            margin-bottom: 2vw;
            padding-bottom: 1vw;
            border-bottom: 1px solid #9E9E9E;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            display: flex;
            font-size: 1.3vw;
            align-items: center;
            color: #474747;
            margin-bottom: 0;
          }
        }

        &__button {
          width: 100%;
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 0.83vw;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          text-align: center;
          background: #025175;
          padding: 0.5vw 0;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
          cursor: pointer;

          svg {
            width: 1vw;
            margin-left: 0.5vw;
            padding-bottom: 0.6vw;

            path {
              fill: #fff;
            }
          }
        }
      }

      &__mid {
        padding-bottom: 2.5vw;
        margin-bottom: 2.5vw;
        border-bottom: 1px solid #9E9E9E;
        
        &__image-container {
          width: 100%;
          margin-bottom: 1.2vw;
          
          &__img {
            width: 100%;
          }
        }

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          color: #474747;
          font-size: 0.9vw;
          margin-bottom: 0;
        }

        &__anvisa {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          color: #474747;
          font-size: 0.9vw;
          margin-top: 1.2vw;
          margin-bottom: 1.4vw;
        }

        &__safe-buy {
          width: 80%;

          &__img {
            width: 100%;
            image-rendering: -webkit-optimize-contrast;
          }
        }
      }
      .product__info__right__inner__calc {
        border: none;
        padding-top: 0!important;
        margin-top: 0!important;
      }
    }

    .cart-modal {

      margin-top: 50px;
      padding: 0 10px;

      &__close {

        &__button {
          text-align: right;
          font-size: 1.2rem;
          margin-bottom: 10px;
          margin-right: 5px;
          cursor: pointer;

          @media all and (min-width: 1600px) {
            font-size: 1.3rem;
          }
        }
      }

      &__text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        color: #474747;

        
        @media all and (min-width: 1600px) {
          font-size: 16px;
        }
      }

      &__confirm {
        margin-top: 25px;
        width: 100%;
        text-align: center;
        background: #1D6EA3;
        border-radius: 50px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        color: #FFFFFF;
        padding: 15px 0;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
        cursor: pointer;

        @media all and (min-width: 1600px) {
          font-size: 13px;
        }
      }

      &__deny {
        margin-top: 15px;
        width: 100%;
        text-align: center;
        border-radius: 50px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        color: #626262;
        border: 1px solid #626262;
        padding: 15px 0;
        cursor: pointer;

        @media all and (min-width: 1600px) {
          font-size: 13px;
        }
      }
    }
  }
}

.subscribe-bottom {

  .product__faq__title {
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    color: #000000;
    margin-bottom: 2.5vw;
  }

  .ant-collapse-content-box {
    font-size: 16px!important;
    font-family: Montserrat;
  }

  .ant-collapse-header {
    font-family: Montserrat;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
  }
}

.subscription-mobile {
  &__carousel {

    &__main {

      &__prev {
        width: 40vw;
        height: 61vw;
        display: block;
        position: relative;
        margin-top: -61vw; 
        display: flex;
        align-items: center;

        &__icon {
          margin-left: 6px;
        }
      }

      &__next {
        width: 40vw;
        height: 61vw;
        display: block;
        position: relative;
        margin-top: -61vw;
        margin-left: 58vw;
        display: flex;
        align-items: center; 
        justify-content: end; 

        &__icon {
          margin-left: auto; 
        }
 
      }

      &__image {
        width: 100%;
        height: 100%
      }
    }

    &__indicator {
      background: rgba(71, 71, 71, 0.5);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 1vw 1.2vw;
      width: fit-content;
      position: relative;
      transform: translate(0px, -8vw);
      margin-bottom: -8vw;

      &__icon {
        width: 2vw;
        height: 2vw;
        margin: 1vw 0.8vw;
        display: block;
        background-color: #9E9E9E;
        border-radius: 50%;
        cursor: pointer;

        &--active {
          background-color: #FFF;
        }
      }
    }
  }

  &__buy {
    margin: 0 3vw;
    margin-top: 7vh;
    
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4vh;
      flex-wrap: wrap;

      &__text {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.5rem;
        padding-right: 5px;
        display: flex;

        &__bold {
          font-weight: bold;
          padding-right: 3px;
        }
      }

      &__rating {
        color: #9E9E9E;
        font-family: Roboto;
        font-style: normal;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &__star {
          padding-left: 2px;
          color: #FFD704;
        }
      }
    }

    &__pricing {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__top {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #474747;
        margin-bottom: 1vw;
      }

      &__bottom {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #2B2B2B;
        letter-spacing: 0px;
      }   
    }

    &__add-to-cart {
      margin-top: 3vh;
      margin-bottom: 4vh;

      &__button {
        background: #025175;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3vw 0;
        font-family: 'Montserrat';
        color: #FFF;
        font-weight: 600;
        font-size: 0.8rem;
        letter-spacing: 0.03rem;

        svg {
          width: 0.8rem;
          padding-bottom: 4px;

          path {
            fill: #FFF;
          }
        }
      }
    }

    &__qtd {
      margin-top: 5vh;
      display: flex;
      justify-content: center;

      &__container {
        width: 90%;

        img {
          width: 100%;
        }
      }
    }

    &__product-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #474747;
      width: 85%;
      margin: 0 auto;
      margin-top: 3vh;

      p {
        margin-bottom: 0;
      }
    }

    &__anvisa {
      margin: auto;
      margin-top: 2vh;
      text-align: center;
      color: #474747;
      line-height: 19px;
      font-family: Montserrat;
      font-weight: 400;
      padding-bottom: 4.5vh;
      border-bottom: 1px solid #9E9E9E;
    }
  }

  &__content-one {
    padding-top: 50px;
    margin-bottom: 100px;

    &__title {
      padding: 0 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;
    }

    &__subtitle {
      padding: 0 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #474747;
      margin-bottom: 50px;
    }

    &__slider {
      padding-left: 50px;

      .slick-list {
        padding-left: 0 !important;
      }

      &__item {
        padding-right: 24px;

        &__img {
          width: 100%;
          image-rendering: -webkit-optimize-contrast;
          padding-bottom: 22px;
        }
  
        &__text {
          width: 100%;
          image-rendering: -webkit-optimize-contrast;
        }
      }
    }
  }

  &__content-two {
    padding-left: 50px;
    
    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin-bottom: 7px
    }

    &__subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #474747;
      margin-bottom: 28px;
      padding-right: 50px;
    }

    &__slider {

      .slick-list {
        padding-left: 0 !important;
      }

      &__item {
        padding-right: 13px;

        img {
          width: 100%;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #474747;
        }
      }
    }
  }

  &__content-three {
    margin-top: 70px;

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      margin: 0 45px;
      margin-bottom: 5px;
    }

    &__subtitle {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #474747;
      margin: 0 45px;
      margin-bottom: 40px;
    }

    &__dropdown {
      margin: 0 34px;
      border-top: 1px solid #AEAEAE;

      &__item {
        padding: 20px;
        border-bottom: 1px solid #AEAEAE;

        &__top {
          display: flex;
          justify-content: space-between;

          &__title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }

          &__arrow {
            display: flex;
            align-items: start;
            margin-bottom: auto;

            &--active {
              transform: rotate(180deg);
            }
          }
        }

        &__bottom {
          margin-top: 20px;

          &__img {
            width: 100%;
            margin-bottom: 20px;
          }

          &__text {
            font-family: Montserrat;
            font-style: normal;
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
    }
  }

  &__content-four {
    margin-top: 100px;
    background-color: #17526E;

    &__subtitle {
      padding-top: 95px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #A2BECE;
      margin-bottom: 0;
    }

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 36px;
    }

    &__slider {
      padding-left: 36px;
      padding-bottom: 87px;
      
      .slick-list {
        padding-left: 0 !important;
      }

      &__item {
        padding-right: 12px;

        img {
          image-rendering: -webkit-optimize-contrast;
          width: 100%;
        }
      }
    }
  }

  &__content-five {

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #000000;
      margin-bottom: 43px;
    }

    &__slider {
      padding-left: 22vw;

      .slick-list {
        padding-left: 0 !important;
      }

      &__item {
        padding-right: 27px;

        &__stars {
          color: #FFD704;
          text-align: center;
          font-size: 20px;
          margin-bottom: 15px;
        }

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #2B2B2B;
        }

        &__name {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #1D6EA3;
          margin-top: 20px;
        }
      }
    }
  }

  &__content-six {
    margin-top: 100px;
    padding-left: 50px;

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
      margin-bottom: 24px;
    }

    &__slider {

      .slick-list {
        padding-left: 0 !important;
      }

      &__item {
        padding-right: 18px;

        img {
          width: 100%;
          margin-bottom: 14px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #000000;
        }

        a {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-decoration-line: underline;
          color: #DFB485;
        }
      }
    }
  }

  &__faq {
    margin-top: 100px;
    padding: 0 36px;

    &__title {
      margin-bottom: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: #2B2B2B;
    }

    &__dropdown {

      &__item {
        padding: 20px 0;
        border-bottom: 1px solid #AEAEAE;

        &__top {
          display: flex;
          justify-content: space-between;

          &__title {
            margin-bottom: 0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #474747;
          }

          &__arrow {

            &--active {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }

        &__bottom {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 16px;
          color: #474747;
          padding-top: 20px;
        }
      }
    }
  }

  &__phrase {
    margin-top: 100px;
    padding: 0 35px;
    margin-bottom: 60px;

    &__img {
      width: 100%;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  &__recomended {
    padding-left: 34px;
  }

  &__money-back {
    position: relative;
    margin-bottom: 50px;

    &__img {
      width: 100%;
      image-rendering: -webkit-optimize-contrast;
    }

    &__ghost {
      position: absolute;
      color: transparent;
      bottom: 5%;
      left: 33.5%;

      &:hover {
        color: transparent;
      }
    }
  }
}
