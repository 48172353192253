.checkout-chocosono {
  background: linear-gradient(180deg, #E1F3FF 0%, rgba(235, 226, 218, 0) 100%);
  padding: 25px 10px 25px 25px;

  @media all and (max-width: 1100px) {
    padding: 25px 10px 25px 20px;
  }

  &__container {
    display: flex;

    &__img {
      &__image {
        border-radius: 10px;
        width: 97px;
        height: 110px;
        border: 2px solid #000;

        @media all and (max-width: 1100px) {
          width: 78px;
          height: 107px;
        }
      }
    }

    &__input {
      align-items: center;
      display: flex;
      margin-right: 15px;

      @media all and (max-width: 1100px) {
        margin-right: 5px;
      }

      input[type='radio'] {
        display: grid;
        place-content: center;
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 50%;
        border: 1px solid #a3a3a3;
      }

      input[type='radio']::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        color: #000;
        background-color: #000;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #000;
      }

      input[type='radio']:checked::before {
        transform: scale(1);
      }
    }
    &__label {
      margin-left: 15px;
      padding-top: 12px;

      @media all and (max-width: 1100px) {
        margin-left: 8px;
        padding-top: 4px;
      }
      &__tag {
        background: #0F6CA8;
        border-radius: 5px;
        color: white;
        padding: 3px 7px;
        font-weight: 700;
        font-size: 14px;
        font-family: 'PrimeformPro';

        @media all and (max-width: 1100px) {
          font-size: 10px;
          line-height: 136.65%;
        }
      }

      &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 3px;
        padding-top: 5px;
        font-family: 'PrimeformProMedium';

        @media all and (max-width: 1100px) {
          font-size: 13px;
          line-height: 15px;
          padding-top: 2px;
        }

        &__bold {
          display: contents;
          font-family: 'PrimeformPro';

          &__value {
            display: contents;
            letter-spacing: -2px;
            font-family: 'PrimeformPro';
          }
        }
      }

      &__sign {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        font-family: 'PrimeformProMedium';

        @media all and (max-width: 1100px) {
          font-size: 10px;
          line-height: 15px;
        }
      }
    }
  }
}
