.transparent-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: 0 !important;
  width: auto;
  height: auto;

  &:disabled {
    cursor: default;
  }
}

//fonts

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

//font-weight: 700
@font-face {
  font-family: 'PrimeformPro';
  src: url('../fonts/PrimeformPro-Bold.otf') format('opentype');
}

//font-weight: 600
@font-face {
  font-family: 'PrimeformProSemiBold';
  src: url('../fonts/PrimeformPro-SemiBold.otf') format('opentype');
}

//font-weight: 500
@font-face {
  font-family: 'PrimeformProMedium';
  src: url('../fonts/PrimeformPro-Medium.otf') format('opentype');
}

//font-weight: 400
@font-face {
  font-family: 'PrimeformProRegular';
  src: url('../fonts/PrimeformPro-Regular.otf') format('opentype');
}

//font-weight: 900
@font-face {
  font-family: 'PrimeformProHeavy';
  src: url('../fonts/PrimeformPro-Heavy.otf') format('opentype');
}

html,
body {
  font-family: 'PrimeformProRegular', sans-serif !important;
  font-size: 1em;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

.ant-collapse-content {
  border-top: none;
}

.MuiTouchRipple-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -50;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  background: transparent;
  transition: none;
  width: 0.1px;
}

.mobile-hidden {

  @media all and (max-width: 1100px) {
    display: none !important;
  }
}

.mobile-visible {
  display: none !important;

  @media all and (max-width: 1100px) {
    display: block !important;
  }
}

.mobile-visible-prescription-feedback {
  display: none !important;

  @media all and (max-width: 1100px) {
    display: flex !important;
  }
}

.mobile-no-padding {
  @media all and (max-width: 1100px) {
    padding: 0 !important;
  }
}

.mobile-no-margin {
  @media all and (max-width: 1100px) {
    margin: 0 !important;
  }
}

.MuiDrawer-paper {
  width: 100%;

  &>div {
    width: 100%;
    text-align: left;

    .MuiList-padding {
      align-items: flex-start;
      padding: 0 30px;
    }
  }
}

.no-padding {
  padding: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.full-width {
  width: 100%;
  display: block;
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: #8d8d8d;
}

.ant-upload-list-item-info:before {
  top: 0;
  left: 0;
}

@media all and (max-width: 1100px) {

  .ant-modal-content {
    border-radius: 0 !important;
  }
}

#blip-chat-container #blip-chat-open-iframe {
  right: 15px !important;
  bottom: 15px !important;
  z-index: 999 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}


.no-padding-right-mobile {

  @media all and (max-width: 1100px) {
    padding-right: 0 !important;
  }
}

.no-padding-mobile {

  @media all and (max-width: 1100px) {
    padding: 0 !important;
  }
}

.slick-dots {
  margin-top: 15px;

  &>li {

    &>button {

      &:before,
      &:after {
        font-size: 15px !important;
        color: #C4C4C4 !important;
        opacity: 0.8 !important;
      }
    }
  }

  &>li.slick-active {

    &>button {

      &:before,
      &:after {
        opacity: 1 !important;
        color: #2D5B72 !important;
      }
    }
  }
}

@media all and (max-width: 1100px) {

  .rccs {
    width: 100%;

    &__card {
      width: 100%;
    }
  }
}

@media all and (min-width: 1100px) {
  .rccs {
    margin-left: 0;
  }
}

.ant-popover-buttons {

  .ant-btn-primary {
    background-color: #5db95d;
    border-radius: 5px;
    border: none !important;
  }
}