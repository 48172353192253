.register__profile {

  &__drop {
    display: flex;
    justify-content: space-between;
    padding: 82px 0px 0px;

    @media all and (max-width: 1100px) {
      width: 100%;
      display: inline-block;
      padding: 33px 0px 0px;
    }
  }

  &__dropdown {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 1100px) {
      width: 100%;
      display: inline-block;
    }

    &__address-container {
      width: 49%;

      @media all and (max-width: 1100px) {
        width: 100%;
      }

      &__edit-button {
        margin-top: 2vh;
        width: 5vw;

        @media all and (max-width: 1100px) {
          width: 18vw;
        }
      }

      &__item {

        &__left {
          padding-left: 2vh;
        }

        &__title {
          margin-bottom: 0;
          font-family: 'Montserrat';
          color: #000;
          font-size: 28px;
          font-weight: 700;
        }

        &__text {
          font-family: 'Montserrat';
          font-size: 20px;
          font-weight: 600;
          color: #7d7d7d;
          font-style: normal;
          display: grid;

          @media all and (max-width: 1100px) {
            font-size: 13px;
          }

          &__subitem {
            padding-top: 2vh;
          }


          &__user-item {
            font-size: 18px;
            color: #0c1931;
            font-weight: 400;
            font-family: 'Montserrat';
            padding-left: 13px;
            border: none;
            width: 80%;
            background-color: #ffffff;

            @media all and (max-width: 1100px) {
              font-size: 13px;
            }
          }
        }


        &__arrow {
          margin-right: 20px;
          margin-bottom: auto;
          cursor: pointer;

          &--active {
            transform: rotate(180deg);
          }
        }

        &__edit {
          padding-top: 2vh;
          color: #000000;
          font-weight: 600;
          font-size: 1.5vh;
          font-family: 'Montserrat';

          @media all and (max-width: 1100px) {
            font-size: 1.7vh;
          }
        }
      }
    }
  }



  .ant-collapse.ant-collapse-icon-position-left {
    width: 100%;
    border: none;
    background: transparent;
    margin-bottom: 35px;
  }

  .ant-collapse-item {
    background-color: $white;
    border-radius: 25px;
  }

  .ant-collapse-content-box {
    background-color: $white;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 44px;
    padding-top: 0;
    border-radius: 25px !important;

    @media all and (max-width: 1100px) {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 27px;
    }
  }

  .ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 25px;
  }

  .ant-collapse>.ant-collapse-item {
    border-radius: 25px;
    border: none;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-family: 'Montserrat';
    font-size: 28px;
    font-weight: 700;
    padding: 3vh 40px;

    @media all and (max-width: 1100px) {
      font-size: 13px;
      font-weight: bold;
      padding: 3vh 15px;
    }
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    top: 3.5vh;
    right: 30px;
    left: initial;
    font-size: 15pt;

    @media all and (max-width: 1100px) {
      right: 18px;
      font-size: 10px;
    }
  }

  .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      padding: 0 !important;
    }
  }
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-left: 15px;
}

.underline {
  border: 1px solid #6da7cb;
  margin-top: 0;
  margin-bottom: 44px;
  width: 4vw;

  @media all and (max-width: 1100px) {
    border: 1px solid #6da7cb;
    margin-bottom: 15px;
    width: 18vw;
  }
}