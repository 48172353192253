.page-header {
  z-index: 1;
  position: sticky;
  background: linear-gradient(180deg, #1d6ea3 0%, #025175 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 69px;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

  @media all and (max-width: 1100px) {
    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.11);
    position: sticky;
    top: 0;
    padding-bottom: 0;
  }

  &--fixed {
    position: sticky;
    height: 69px;
    z-index: 2;
    width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

    @media all and (max-width: 1600px) {
      max-height: 22vh;
    }
  }

  &__inner {
    padding: 7px 0 7px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 0.58rem;

    &__left {
      margin-right: auto;
    }

    &__right {
      display: flex;
      align-items: center;

      &__dropdown {
        margin: 0 22px;

        @media all and (max-width: 1450px) {
          margin: 0 20px;
        }

        @media all and (max-width: 1100px) {
          margin: 0 13px;
          padding: 15px 0;
        }

        &__link {
          font-family: 'PrimeformPro';
          font-weight: 700;
          text-transform: capitalize;
          cursor: pointer;
          font-size: 17px;
          color: #aadefe;
          line-height: 27px;

          &--active {
            color: #aadefe;
          }

          &:hover {
            color: white;
          }
        }
      }
    }

    &__logo {
      @media all and (max-width: 1100px) {
        text-align: initial;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 20px 10px;
        align-items: center;
      }

      &__img {
        & path {
          fill: #3b5c7a;
        }

        @media all and (max-width: 1100px) {
          height: 40px;
        }

        &--filled {
          @media all and (max-width: 1100px) {
            height: 40px;
          }

          & path {
            fill: #3b5c7a;
          }
        }
      }

      &__mobile {
        &__inner {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          &__item {
            &__link {
              display: block;
              position: relative;

              &__img {
                display: block;
                width: 25px;

                &--profile {
                  width: 20px;
                  position: relative;
                  top: 3px;
                  margin-right: 30px;
                }
              }

              &__amount {
                font-size: 12px;
                position: absolute;
                top: -8px;
                right: -8px;
                background-color: black;
                color: white;
                border-radius: 50%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                line-height: 0;
                letter-spacing: 0;
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 1100px) {
      &:after {
        content: '';
        width: 40px;
        height: 40px;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
          273deg,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-linear-gradient(
          273deg,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0) 100%
        );
        background: linear-gradient(
          273deg,
          rgba(255, 255, 255, 1) 10%,
          rgba(255, 255, 255, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    &__menu {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media all and (max-width: 1100px) {
        white-space: nowrap;
        max-width: 100%;
        overflow-x: scroll;
        justify-content: flex-start;
        position: relative;
      }

      &__item {
        margin: 0 22px;

        @media all and (max-width: 1450px) {
          margin: 0 20px;
        }

        @media all and (max-width: 1100px) {
          margin: 0 13px;
          padding: 15px 0;
        }

        &--last {
          @media all and (max-width: 1100px) {
            padding-right: 40px;
          }
        }

        &__popover {
          &__container {
            display: flex;
            flex-direction: row;
            padding: 5% 10%;

            &__column {
              padding-right: 50px;

              &__row {
                &__title {
                  font-family: Montserrat, sans-serif;
                  font-weight: bold;
                  padding-bottom: 15px;
                  color: #506b7e;
                  font-size: 16px;

                  &--no-padding {
                    padding-bottom: 6px;
                  }
                }

                &__link {
                  font-family: Montserrat, sans-serif;
                  padding-bottom: 15px;

                  &--padding-bottom {
                    padding-bottom: 50px;
                  }

                  &--doc-icon {
                    padding-bottom: 11px;
                  }

                  &__text {
                    color: #626262;

                    &__doctor {
                      color: #6da7cb;
                    }

                    &:hover {
                      color: #6da7cb;
                    }
                  }
                }
              }
            }
          }
        }

        &__link {
          font-family: 'PrimeformPro';
          line-height: 27px;
          font-style: normal;
          font-weight: 700;
          color: white;
          letter-spacing: 2px;
          border-bottom: 2px solid transparent;
          padding-bottom: 2px;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          align-items: center;
          font-size: 17px;

          &:hover {
            color: white;

            @media all and (max-width: 1100px) {
              border-bottom: 2px solid transparent;
            }
          }

          &--cart {
            font-family: 'PrimeformProRegular';
            line-height: 27px;
            font-weight: 400;
            color: white;
            letter-spacing: 2px;
            border-bottom: 2px solid transparent;
            padding-bottom: 2px;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            font-size: 17px;
            margin-bottom: -2px;

            &:hover {
              color: white;
            }
          }

          &--active {
            color: white;

            &__icon {
              font-size: 15px;
              margin-right: 5px;
            }

            &:hover {
              color: white;
            }
          }

          &__icon {
            font-size: 15px;
            margin-right: 5px;

            &__amount {
              font-size: 12px;
              position: absolute;
              top: -8px;
              right: -8px;
              background-color: black;
              color: #626262;
              border-radius: 50%;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 18px;
              width: 18px;
              line-height: 0;
              letter-spacing: 0;
            }

            &__img {
              width: 15px;
              position: relative;
              top: 3px;

              & path {
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}

/* PopOver reescreve estilos do popover do dropdown do header */
.ant-popover-placement-bottom {
  left: 0 !important;
  top: 59px !important;

  & .ant-popover-inner {
    -webkit-box-shadow: none;
    box-shadow: none;

    &-content {
      padding: 0;
    }
  }
}

.page-header-mobile {
  position: sticky;
  top: 0;
  z-index: 1000;
  -webkit-box-shadow: 0 1px 10px 0 rgba(119, 83, 83, 0.11);
  -moz-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.11);

  .top-bar__logo__wrapper {
    font-size: 7px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-bottom: 4px;
    background: linear-gradient(180deg, #1D6EA3 0%, #025175 100%);

    &__dropdown {
      margin-left: auto;

      &__item {
        &__link {
          color: white;

          &:visited,
          &:active,
          &:hover {
            color: white;
          }

          &__icon {
            font-size: 20px;
          }
        }
      }
    }

    &__cart {
      margin-left: auto;

      &__img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 100% !important;

  @media all and (max-width: 1100px) {
    width: 100%;
    max-width: 100% !important;
  }
}
