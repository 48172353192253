.lp-sleep-gummy {

  &__inner {
    width: 100vw;
    position: relative;

    &__img {
      width: 100%;
    }

    &__container {
      position: absolute;
      top: 0;
      left: 0;

      &__links {
        padding-top: 3vw;
        display: flex;
        margin-left: 70vw;

        &__link {
          color: #442565;

          &__paragraph {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            font-size: 1.2vw;
            color: #442565;
            display: flex;
            align-items: center;

            &__img {
              margin-right: 0.6vw;
              height: 1.2vw;

              &__question {
                margin-left: 1.7vw;
                margin-right: 0.3vw;
                height: 1.2vw;
              }
            }
          }
        }
      }

      &__content {
        width: 67vw;
        line-height: 2.6vw;
        margin-left: 10vw;

        &__title {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 800;
          font-size: 1.5vw;
          color: #663898;
          margin-top: 10vw;
          padding-left: 0.2vw;

          @media all and (max-width: 1100px) {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 2.6vw;
            line-height: 51px;
            height: 44px;
            margin-top: 5vw;
          }
        }

        &__subtitle {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 800;
          font-size: 2.3vw;
          color: #442565;

          @media all and (max-width: 1100px) {
            font-family: 'Mulish';
            font-size: 3.8vw;
            line-height: 4vw;
            font-weight: 400;
            width: 47vw;
          }
        }

        &__text {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 1.2vw;
          line-height: 1.5vw;
          color: #474747;
        }

        &__button {
          background-color: #FF4059;
          width: 21.4vw;
          border-radius: 5.2vw;
          font-family: 'Mulish';
          font-weight: 800;
          font-size: 1.2vw;
          height: 4.2vw;
          text-align: center;
          color: #FFFFFF;
          border: none;
          margin-top: 1.7vw;
          cursor: pointer;

          @media all and (max-width: 1100px) {
            width: 55vw;
            height: 10vw;
            font-size: 2.6vw;
            background-color: #FB3B3D;
            margin-top: 5px;
          }
        }
      }
    }
  }

  &__container-m {
    margin-top: 77px;
    margin-bottom: 35px;

    &__two {
      margin-top: 7vw;
    }
  }

  &__container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    &__inner {
      width: 100%;
      height: 22.4vw;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11vw;

      @media all and (max-width: 1100px) {
        display: flex;
        flex-wrap: wrap;
        width: 36vw;
        height: 100%;
        gap: 0;
      }

      &__icon {
        width: 9.5vw;
        height: 8.7vw;

        @media all and (max-width: 1100px) {
          height: 20.4vw;
          width: 20.2vw;
          margin-left: 3vw;
          margin-right: 3vw;
        }
      }
    }
  }

  &__gif {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9.1vw;
    margin-bottom: 10.9vw;
    gap: 3vw;

    &__video {
      width: 20vw;
      height: 18vw;
      mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg preserveAspectRatio='none' data-bbox='25 35.5 150 129' xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='25 35.5 150 129' data-type='shape'%3E%3Cg%3E%3Cpath d='M60.737 49.182c8.275-.674 16.937 1.138 26.609.273 15.963-1.427 31.182-7.908 46.56-12.017 6.51-1.74 13.434-2.768 19.956-1.072 8.065 2.097 12.642 8.203 15.303 15.732 7.508 21.244 6.428 37.716 4.132 60.133-1.29 12.597-5.185 27.884-18.721 32.255-10.643 3.437-22.453-.292-33.448 1.681-19.478 3.496-40.779 23.034-61.08 17.279-40.197-11.397-45.26-80.282-18.052-105.9 6.208-5.845 12.36-7.844 18.741-8.364z'/%3E%3C/g%3E%3C/svg%3E%0A");
      mask-position: 0% 0%;
      mask-size: 100% 100%;
      mask-repeat: no-repeat;
    }

    &__content {
      width: 34.8vw;
      text-align: justify;

      @media all and (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__video {
        width: 20vw;
        height: 18vw;
        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg preserveAspectRatio='none' data-bbox='25 35.5 150 129' xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='25 35.5 150 129' data-type='shape'%3E%3Cg%3E%3Cpath d='M60.737 49.182c8.275-.674 16.937 1.138 26.609.273 15.963-1.427 31.182-7.908 46.56-12.017 6.51-1.74 13.434-2.768 19.956-1.072 8.065 2.097 12.642 8.203 15.303 15.732 7.508 21.244 6.428 37.716 4.132 60.133-1.29 12.597-5.185 27.884-18.721 32.255-10.643 3.437-22.453-.292-33.448 1.681-19.478 3.496-40.779 23.034-61.08 17.279-40.197-11.397-45.26-80.282-18.052-105.9 6.208-5.845 12.36-7.844 18.741-8.364z'/%3E%3C/g%3E%3C/svg%3E%0A");
        mask-position: 0% 0%;
        mask-size: 100% 100%;
        mask-repeat: no-repeat;

        @media all and (max-width: 1100px) {
          height: 140px;
          width: 190px;
        }

      }

      &__title {
        font-family: 'Mulish';
        font-weight: 800;
        font-size: 2.1vw;
        color: #442565;

        @media all and (max-width: 1100px) {
          font-family: 'Mulish';
          font-weight: 800;
          font-size: 25px;
          line-height: 30px;
          width: 223px;
          text-align: center;
        }
      }

      &__paragraph {
        font-family: 'Montserrat';
        font-size: 1.1vw;
        color: #474747;
        width: 33vw;

        @media all and (max-width: 1100px) {
          font-family: 'Montserrat';
          font-size: 18px;
          line-height: 25px;
          margin-top: 23px;
          text-align: center;
          width: 328px;
        }
      }

      &__img {
        width: 100vw;
        padding: 50px;
      }
    }
  }

  &__benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 45.7vw;
    background-color: #F1F7F8;

    @media all and (max-width: 1100px) {
      height: 100%;
    }

    &__image {
      width: 63.6vw;

      @media all and (max-width: 1100px) {
        padding-top: 74px;
      }
    }

    &__title {
      color: #442565;
      font-family: 'Mulish';
      font-weight: 800;
      font-size: 2.1vw;
      margin-top: 10.3vw;
      margin-bottom: 3.7vw;

      @media all and (max-width: 1100px) {
        margin-top: 75px;
      }
    }

    &__button {
      background-color: #FF4059;
      width: 21.4vw;
      border-radius: 5.2vw;
      font-family: 'Mulish';
      font-weight: 800;
      font-size: 1.2vw;
      height: 4.2vw;
      text-align: center;
      color: #FFFFFF;
      border: none;
      margin-top: 5.8vw;
      cursor: pointer;

      @media all and (max-width: 1100px) {
        margin-bottom: 95px;
        margin-top: 75px;
        height: 50px;
        width: 266px;
        font-size: 13px;
        background-color: #FB3B3D;
      }
    }
  }

  &__sunset {
    position: relative;
    width: 100vw;
    display: flex;

    &__image {
      width: 100%;
      image-rendering: -webkit-optimize-contrast;

      @media all and (max-width: 1100px) {
        height: 500px;
      }
    }

    &__container {
      width: 100vw;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      &__content {
        display: flex;
        flex-direction: column;
        position: absolute;
        text-align: center;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 1vw;
        color: #FFFFFF;
        width: 314px;

        @media all and (max-width: 1100px) {
          font-size: 13px;
          width: 358px;
          padding: 48px;
        }

        &__title {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 800;
          font-size: 2.2vw;

          @media all and (max-width: 1100px) {
            font-size: 25px;
          }
        }
      }
    }
  }

  &__passaport {
    height: 65.6vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 1100px) {
      height: 100%;
      display: flex;
      justify-content: center;
    }

    &__content {
      width: 29vw;
      margin-right: 6.6vw;
      text-align: justify;

      @media all and (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        margin-right: 0;
      }

      &__image-woman {
        width: 299px;
        margin-top: 92px;
      }

      &__title {
        font-family: 'Mulish';
        font-weight: 800;
        font-size: 2.1vw;
        color: #442565;
        line-height: 2.6vw;
        width: 26vw;

        @media all and (max-width: 1100px) {
          margin-top: 45px;
          font-family: 'Mulish';
          font-weight: 800;
          font-size: 25px;
          line-height: 35px;
          width: 313px;
        }
      }

      &__paragraph {
        font-family: 'Montserrat';
        color: #474747;
        font-size: 1vw;
        line-height: 1.5vw;
        text-align: justify;
        width: 304px;

        @media all and (max-width: 1100px) {
          text-align: center;
          font-size: 18px;
          line-height: 25px;
          width: 318px;
        }
      }

      &__button {
        background-color: #FF4059;
        width: 17.5vw;
        border-radius: 5.2vw;
        font-family: 'Mulish';
        font-weight: 800;
        font-size: 1.2vw;
        height: 4.2vw;
        text-align: center;
        color: #FFFFFF;
        border: none;
        margin-top: 2.3vw;
        cursor: pointer;

        @media all and (max-width: 1100px) {
          width: 334px;
          height: 75px;
          font-size: 20px;
          margin-bottom: 109px;
        }
      }
    }

    &__image {
      width: 26.3vw;
      height: 26.3vw;

      &__woman {
        width: 100%;
        height: 100%;

        @media all and (max-width: 1100px) {
          padding-top: 10px;
          margin-left: 25px;
        }
      }
    }

  }

  &__comparation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F3F0F8;
    padding-top: 8.2vw;

    &__image {
      width: 7.4vw;
      height: 11.4vw;

      @media all and (max-width: 1100px) {
        height: 222px;
        width: 141px;
      }
    }

    &__title {
      font-family: 'Mulish';
      font-weight: 800;
      font-size: 2.1vw;
      color: #442565;
      text-align: center;
      margin-bottom: 4.1vw;

      @media all and (max-width: 1100px) {
        font-size: 25px;
      }
    }

    &__others {
      display: flex;
      gap: 1vw;
    }

    &__container {
      display: flex;
      margin-top: 1.6vw;

      @media all and (max-width: 1100px) {
        display: flex;
      }

      &__melatonin {

        @media all and (max-width: 1100px) {
          margin-right: 6vw;
          padding-left: 2vw;
          border-left: 1px solid #FFCCD4;
        }

        @media all and (max-width: 360px) {
          margin-right: 0;
        }

        &__title {
          color: #AB67B3;
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 1.3vw;
          padding-left: 3vw;

          @media all and (max-width: 1100px) {
            font-size: 18px;
          }

          @media all and (max-width: 632px) {
            padding-left: 6vw;
            padding-left: 10px;
          }
        }
      }

      &__others {
        padding-right: 3.1vw;
        border-right: 1px solid #FFCCD4;


        @media all and (max-width: 1100px) {
          padding-left: 4vw;
          padding-right: 6vw;
        }

        &__title {
          color: #919191;
          text-align: end;
          padding-right: 0.2vw;
          font-family: 'Montserrat';
          font-weight: 700;
          font-size: 1.3vw;

          @media all and (max-width: 1100px) {
            font-size: 18px;
          }
        }
      }

      &__list-others {
        list-style: none;
        text-align: right;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 1.1vw;

        @media all and (max-width: 1100px) {
          font-size: 14px;
        }

        @media all and (max-width: 400px) {
          padding-left: 18px;
        }

        @media all and (max-width: 360px) {
          padding-left: 6px;
        }
      }

      &__list-melatonin {
        list-style: none;
        text-align: left;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 1.1vw;

        @media all and (max-width: 1100px) {
          font-size: 14px;
          width: 172px;
        }

        @media all and (max-width: 632px) {
          padding-left: 6vw;
          padding-left: 10px;
        }
      }
    }

    &__x {
      margin-top: 5vw;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 2.3vw;
      color: #AB67B3;

      @media all and (max-width: 1100px) {
        font-size: 45px;
        margin-top: 121px;
      }

      @media all and (max-width: 414px) {
        padding-right: 1vw;
      }

      @media all and (max-width: 375px) {
        margin-right: 5vw;
      }
    }

    &__arrow {
      margin-top: 6.2vw;
      height: 1.6vw;
      margin-bottom: 6.9vw;

      @media all and (max-width: 1100px) {
        height: 35px;
      }

      @media all and (max-width: 515px) {
        margin-right: 1vw;
      }

      @media all and (max-width: 375px) {
        margin-right: 5vw;
      }
    }
  }

  &__product {
    width: 100vw;
    position: relative;

    &__sky {
      width: 100%;

      @media all and (max-width: 1100px) {
        height: 1103px;
      }
    }

    &__container {
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      &__content {
        position: absolute;

        @media all and (max-width: 1100px) {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 364px;
        }

        &__product-m {
          width: 328px;
          height: 309px;
          margin-top: 21px;
          margin-bottom: 23px;
        }

        &__image {
          width: 47.7vw;
          height: 25.4vw;

          @media all and (max-width: 1100px) {
            height: 394px;
            width: 321px;
            margin-bottom: 44px;
          }
        }

        &__ghost-button {
          cursor: pointer;
          width: 14.2vw;
          height: 3.4vw;
          position: absolute;
          top: 19vw;
          left: 26vw;
        }
      }
    }
  }

  &__satisfaction {
    width: 100vw;
    height: 48.5vw;
    background-color: #F5F1F8;
    text-align: center;

    @media all and (max-width: 1100px) {
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
    }

    &__image {
      width: 65.4vw;
      image-rendering: -webkit-optimize-contrast;

      @media all and (max-width: 1100px) {
        margin-top: 97px;
      }
    }

    &__title {
      font-family: 'Mulish';
      font-weight: 800;
      font-size: 2.1vw;
      color: #442565;
      padding-top: 10.2vw;
      margin-bottom: 3.7vw;

      @media all and (max-width: 1100px) {
        font-family: 'Mulish';
        font-weight: 400;
        font-size: 25px;
        line-height: 31px;
      }
    }
  }

  &__phrase {
    width: 100vw;

    &__image {
      width: 62.2vw;
      margin-left: 19.4vw;
      margin-top: 12.1vw;
      margin-bottom: 10.5vw;
    }
  }

  &__faq {
    margin: 0 7vw;
    margin-top: 9rem;

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 2.2vw;
      color: #2B2B2B;
      margin-bottom: 2rem;
      margin-top: 10.5vw;
    }

    &__container {

      &__item {
        margin-top: 1rem;
        border-bottom: 1px solid #000000;
        cursor: pointer;

        &__title {
          display: flex;
          justify-content: space-between;

          p {
            margin-bottom: 1rem;
            color: #000;
            font-size: 1.4vw;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
          }

          span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 2.8vw;
            color: #000;
            line-height: 2vw;
          }
        }

        &__text {
          margin-bottom: 1rem;
          color: #000;
          font-size: 1.4vw;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 300;
        }
      }
    }
  }

  &__recomended {
    margin-top: 10vw;
    margin-left: 7vw;
  }
}