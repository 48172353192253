.top-bar-panel {
  background-color: $primary-color;
  padding: 30px 0;
  display: flex;
  justify-content: center;

  &__title {
    text-align: center;
    color: $white;
    font-size: 48px;
    font-weight: 400;
  }
}
