.offer {
  margin-left: 15.25rem;

  @media all and (max-width: 1100px) {
    margin-top: 1rem;
    margin-left: 1.5rem;
  }

  &__description {
    margin-top: 0.5rem;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.8rem;

    @media all and (max-width: 1100px) {
      font-size: 0.5rem;
      margin-left: 0.6rem;
    }
  }

  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0;
    animation: flasher 1.2s infinite;

    @keyframes flasher {
      0% {
        color: #000;
      }

      100% {
        color: #F2F2F2;
      }
    }

    @media all and (max-width: 1100px) {
      font-size: 1.8vw;
      margin-left: 0.5rem;
    }

    &__span {
      font-weight: 700;
      font-size: 0.75rem;

      @media all and (max-width: 1100px) {
        font-size: 1.8vw;
      }
    }
  }
}