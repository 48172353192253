.mobile-cart {
  bottom: 0;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 2;
  width: 100%;
  padding-right: 10px;

  &__items {
    &__icon {
      text-align: center;
    }
  }

  &__products {
    animation: slide-in 5s; /* Duração da animação: 5 segundos */
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }
}

.accordion-content {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.accordion-content[aria-expanded='true'] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
