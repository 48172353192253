.footer {
  background: linear-gradient(180deg, #3B5C7A 0%, #1F3548 100%);
  padding: 50px 0 10px 0;

  &__top {
    margin-top: 268px;
    margin-bottom: 192px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 1100px) {
      margin-top: 108px;
      margin-bottom: 79px;
    }

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #474747;
      margin-bottom: 27px;

      @media all and (max-width: 1100px) {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 13px;
      }
    }

    &__form {
      position: relative;
      margin-bottom: 24px;

      @media all and (max-width: 1100px) {
        margin-bottom: 13px;
      }

      &__input {
        width: 711px;
        height: 107px;
        border: 1px solid #ACACAC;
        border-radius: 60px;
        padding-left: 51px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #474747;

        @media all and (max-width: 1100px) {
          width: 350px;
          height: 65px;
          padding-left: 23px;
        }
  
      }

      &__input:focus-visible {
        outline: none;
      }

      &__input::placeholder {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #474747;

        @media all and (max-width: 1100px) {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
        }
      }

      &__button {
        width: 87px;
        height: 87px;
        border-radius: 50%;
        position: absolute;
        background: #025175;
        border: none;
        transform: translate(-99px, 9px);
        cursor: pointer;

        @media all and (max-width: 1100px) {
          width: 55px;
          height: 55px;
          transform: translate(-62px, 5px);
        }

        svg {
          margin-top: 5px;
        }
      }
    }

    &__text-politic {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.03em;
      color: #6B6B6B;
      width: 484px;

      @media all and (max-width: 1100px) {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.03em;
        width: 367px;
        text-align: center;
      }

      @media all and (max-width: 600px) {
        padding: 0 20px;
      }

      a {
        text-decoration: underline;
        color: #6B6B6B;
      }
    }
  }

  &__bottom {
    background: linear-gradient(180deg, #1D6EA3 0%, #025175 100%);
    padding-top: 155px;

    &__links {
      display: flex;
      @media all and (min-width: 2000px) {
        justify-content: center;
      }
      

      &__medical {
        margin-left: 100px;
        margin-right: 80px;
        @media all and (max-width: 1800px) {
          margin-left: 80px;
          margin-right: 60px;
        }

        &__link {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          width: 434px;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #FFFFFF;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
          border-radius: 50px;

          @media all and (max-width: 1800px) {
            width: 400px;
            height: 60px;
            font-size: 14px;
          }

          @media all and (max-width: 1400px) {
            width: 350px;
            height: 50px;
            font-size: 12px;
          }
        }

        &__link:hover {
          color: #000000;
        }
      }

      &__products {
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.11em;
        margin-right: 80px;

        @media all and (max-width: 1800px) {
          font-size: 13px;
          margin-right: 60px;
        }

        @media all and (max-width: 1400px) {
          font-size: 11px;
          margin-right: 40px;
        }

        &__title {
          font-weight: bold;
          color: #FFF;
          margin-bottom: 20px;

          @media all and (max-width: 1800px) {
            margin-bottom: 15px;
          }

          @media all and (max-width: 1400px) {
            margin-bottom: 10px;
          }
        }

        &__link {
          color: #FFF;

          &__text {
            &--sold-off {
              text-decoration: line-through;
            }

            margin-bottom: 20px;

            @media all and (max-width: 1800px) {
              margin-bottom: 15px;
            }
    
            @media all and (max-width: 1400px) {
              margin-bottom: 10px;
            }
          }
        }
      }

      &__institucional {
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.11em;
        margin-right: 80px;

        @media all and (max-width: 1800px) {
          font-size: 13px;
          margin-right: 60px;
        }

        @media all and (max-width: 1400px) {
          font-size: 11px;
          margin-right: 40px;
        }

        &__title {
          font-weight: bold;
          color: #FFF;
          margin-bottom: 20px;

          @media all and (max-width: 1800px) {
            margin-bottom: 15px;
          }

          @media all and (max-width: 1400px) {
            margin-bottom: 10px;
          }
        }

        &__link {
          color: #FFF;
          
          p {
            margin-bottom: 20px;

            @media all and (max-width: 1800px) {
              margin-bottom: 15px;
            }
    
            @media all and (max-width: 1400px) {
              margin-bottom: 10px;
            }
          }
        }
      }

      &__aux {

        &__social {
          margin-bottom: 42px;

          &__title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.03em;
            margin-bottom: 14px;
            color: #FFFFFF;

            @media all and (max-width: 1800px) {
              font-size: 14px;
            }
    
            @media all and (max-width: 1400px) {
              font-size: 12px;
            }
          }

          &__icons {

            a {
              margin-right: 20px;

              @media all and (max-width: 1800px) {
                margin-right: 15px;
              }
      
              @media all and (max-width: 1400px) {
                margin-right: 10px;
              }

              img {
                width: 40px;
                height: 40px;

                
                @media all and (max-width: 1800px) {
                  width: 35px;
                  height: 35px;
                }
        
                @media all and (max-width: 1400px) {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }

        &__talk {
          margin-bottom: 52px;

          &__title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.03em;
            margin-bottom: 14px;
            color: #FFFFFF;

            @media all and (max-width: 1800px) {
              font-size: 14px;
            }
    
            @media all and (max-width: 1400px) {
              font-size: 12px;
            }
            
          }

          &__icons {

            a {
              margin-right: 20px;

              @media all and (max-width: 1800px) {
                margin-right: 15px;
              }
      
              @media all and (max-width: 1400px) {
                margin-right: 10px;
              }

              img {
                width: 40px;
                height: 40px;

                @media all and (max-width: 1800px) {
                  width: 35px;
                  height: 35px;
                }
        
                @media all and (max-width: 1400px) {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }

        &__payment {

          &__title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.03em;
            margin-bottom: 14px;
            color: #FFFFFF;

            @media all and (max-width: 1800px) {
              font-size: 14px;
            }
    
            @media all and (max-width: 1400px) {
              font-size: 12px;
            }
          }
          div img {

            @media all and (max-width: 1800px) {
              width: 90%;
            }

            @media all and (max-width: 1400px) {
              width: 80%;
            }
          }
        }
      }
    }

    &__info { 
      margin: 0 110px;
      margin-top: 134px;
      margin-bottom: 25px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.11em;
      color: #FFFFFF;

      @media all and (max-width: 1800px) {
        font-size: 10px;
      }

      @media all and (max-width: 1400px) {
        font-size: 8px;
      }
    }

    &__rights {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.11em;
      color: #FFFFFF;
      padding-bottom: 55px;

      @media all and (max-width: 1800px) {
        font-size: 10px;
      }

      @media all and (max-width: 1400px) {
        font-size: 8px;
      }
    }
  }

  @media all and (max-width: 1100px) {
    padding: 33px 0 0;
  }

  &__title {
    color: $white;
    margin: 0 auto;
    text-align: center;
    font-size: 25px;
    font-weight: 700;

    @media all and (max-width: 1100px) {
      font-size: 14px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 0;

    &__item {
      font-weight: 300;
      box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    &__input-left {
      width: 220px;
      height: 58px;
      background-color: $white;
      border: none;
      border-radius: 10px 0 0 10px;
      margin: 0 15px 0 0;
      padding: 15px;
    }

    &__input {
      width: 430px;
      height: 58px;
      background-color: $white;
      border: none;
      padding: 15px;
    }

    &__button {
      background-color: $button-footer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 16px;
      font-weight: 700;
      border-radius: 0px 10px 10px 0px;
      margin: 0 0 0 15px;
      width: 170px;
      height: 58px;
      border: none;
      cursor: pointer;
      transition: all ease-in-out 300ms;

      &:hover {
        background-color: lighten($button-footer, 10);
      }

      @media all and (max-width: 1100px) {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 995px) {

    &__form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
        margin: 8px 0;
        border-radius: 10px;
      }

      &__button {
        border-radius: 10px;
        margin: 5px 0 0 0;
        width: 100%;
      }
    }
  }

  &__text-politic {
    font-size: 12px;
    color: $white;
    margin: 7px 0 0 0;
    text-align: center;

    @media all and (max-width: 1100px) {
      border-bottom: 1px solid $light-gray;
      padding-bottom: 30px;
    }
  }

  &__socials {
    @media all and (max-width: 1100px) {
      text-align: center;
      margin: 0 auto 30px;
    }

    &__cards {

      @media all and (max-width: 1100px) {
        width: 95%;
        display: block;
        margin: auto;
      }
    }

    &__title {
      text-align: left;
      color: $white;
      font-size: 14px;
      font-weight: 400;

      @media all and (max-width: 1100px) {
        text-align: center;
      }
    }

    &__icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 15px auto;

      @media all and (max-width: 1100px) {
        justify-content: center;

        &--last {

          & > img {
            margin-right: 0 !important;
          }
        }
      }

      img {
        width: 40px;
        height: 40px;
        margin: 0 20px 0 0;
      }
    }
  }

  &__links {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: 150px 0 0 0;

    @media all and (max-width: 1100px) {
      margin-top: 30px;
    }

    &__aux {

      p {
        font-weight: 300;
        letter-spacing: 2px;
        color: $white;
        font-size: 15px;
        margin: 0 0 25px;
      }

      &__title {
        font-weight: 700;
        color: $white;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0 0 20px 0;
      }

      &__safe {
        width: 180px;

        @media all and (max-width: 1100px) {
          display: block;
          margin: auto;
        }

        &__link {

          &__img {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 995px) {

    &__links {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__aux {
        text-align: center;
        margin: 0 0 30px 0;
      }
    }
  }

  &__rodape {
    margin: 60px auto 0;

    @media all and (max-width: 1100px) {
      margin-top: -15px;
      padding-bottom: 30px;
    }

    &__text {
      text-align: center;
      color: $white;
      font-weight: 400;
      line-height: 15px;
      font-size: 12px;
    }

    &__span {
      text-align: center;
      color: $white;
      font-weight: 400;
      line-height: 15px;
      font-size: 12px;
      display: flex;
      justify-content: center;
    }
  }
}

.footer-mobile__bottom {
  background: linear-gradient(180deg, #1D6EA3 0%, #025175 100%);
  padding: 58px 35px;

  &__links {

    &__medical {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52.09px;
      width: 100%;
      max-width: 400px;
      background: #FFFFFF;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
      border-radius: 50px;
      margin-bottom: 50px;

      &__link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
    }

    &__products {
      padding-top: 20px;
      padding-bottom: 15px;
      border-top: 1px solid #D1D1D1;
      padding-left: 15px;
      padding-right: 15px;

      &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      &__link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        margin-bottom: 0px;

        &__text {
          &--sold-off {
            text-decoration: line-through;
          }
        }
      }
    }

    &__institucional {
      padding-top: 20px;
      padding-bottom: 15px;
      border-top: 1px solid #D1D1D1;
      padding-left: 15px;
      padding-right: 15px;

      &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      &__link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        margin-bottom: 0px;
      }
    }

    &__aux {
      border-top: 1px solid #D1D1D1;
      padding-top: 41px;
      padding-left: 15px;

      &__social {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;

        &__title {
          color: #FFFFFF;
          margin-bottom: 13px;
        }

        &__icons {

          a {
            margin-right: 15px;

            img {
              width: 31px;
              height: 31px;
            }
          }
        }
      }
      
      &__talk {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;

        &__title {
          color: #FFFFFF;
          margin-bottom: 13px;
        }

        &__icons {

          a {
            margin-right: 15px;

            img {
              width: 31px;
              height: 31px;
            }
          }
        }
      }

      &__payment {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        margin-bottom: 30px;

        &__title {
          color: #FFFFFF;
          margin-bottom: 13px;
        }

        div {

          img {
            height: 30px;
          }
        }
      }
    }
  }
}
