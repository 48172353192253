$title-colors: (
  ED: rgba(76, 156, 156, 0.25),
  MELATONIN: rgba(131, 113, 185, 0.25),
  HAIR_LOSS: rgba(109, 167, 203, 0.25)
);

.container-feedback {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #F2F2F2;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #fff;
    
    width: 62.25rem;
    height: 37.063rem;
    
    margin-top: 3rem;
    border-radius: 1.563rem;
    
    @media screen and (max-width: 1100px) {
      width: 20.125rem;
      height: 26.5rem;
    }

    @mixin title($type) {
      background-color: map-get($title-colors, $type);
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      width: 100%;
      height: 8.563rem;
      padding: 3rem;

      border-radius: 1.563rem 1.563rem 0 0;

      @media screen and (max-width: 1100px) {
        height: 4.188rem;
        align-items: center;
      }

      &.ED {
        @include title(ED);
      }
      &.MELATONIN {
        @include title(MELATONIN);
      }
      &.HAIR_LOSS {
        @include title(HAIR_LOSS);
      }
      
      h1 {
        font-family: 'PrimeformPro', sans-serif;
        font-weight: 700;
        font-size: 1.25em;

        @media screen and (max-width: 1100px) {
          font-size: 0.875rem;
        }
      }

    }

    &__description {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      width: 49.25rem;
      padding: 3rem;

      line-height: 1.696rem;
      font-size: 1.25em;

      @media screen and (max-width: 1100px) {
        width: 16.563em;
        padding: 1rem;
        display: flex;
        justify-content: unset;
        text-align: center;
        font-size: 1.184em;
        height: 75.95%;
        word-break: break-word;
      }

      h2 {
        font-weight: 700;

        @media screen and (max-width: 1100px) {
          font-size: 0.875em;
          font-family: 'PrimeformPro', sans-serif;
          line-height: 1.184rem;
          width: 95%;
        }
      }

      p {
        text-align: center;
        font-family: 'PrimeformProMedium', sans-serif;

        @media screen and (max-width: 1000px) {
          font-size: 0.875em;
          line-height: 1.188rem;
        }
      }

      &__reserve-button {
        button {
          all: unset;
          width: 24rem;
          height: 5rem;
          
          background-color: #1D6EA3;
          border-radius: 0.938rem;
          
          color: #FFF;
          font-family: 'Montserrat';
          text-align: center;
          font-size: 1em;
          font-weight: 700;
  
          cursor: pointer;
  
          &:hover {
            opacity: 0.9;
            transition: 0.3s;
          }
  
          @media screen and (max-width: 1000px) {
            width: 15.75rem;
            height: 3.438rem;
            border-radius: 0.938rem;
            font-size: 0.813rem;
          }
        }
      }

    }

    &__back-to-home {
      text-decoration: underline;
      margin: 3.5rem;
    }
  }
}