.rotative-banner-home {
    display: flex;
    align-items: center;

    &__container {
        margin: 0 auto;
        display: flex;
        align-items: center;

        @media all and (max-width: 1100px) {
            flex-wrap: wrap;
            justify-content: center;
        }
        

        &__img {
            height: 60px;
            margin: 0 30px;

            @media all and (max-width: 1100px) {
                height: 24px;
                margin: 0 9px;
                margin-bottom: 32px;
            }
        }
    }
}

.rotative-banner-home-header {
    margin-top: 240px;
    margin-bottom: 3rem;
    text-align: center;

    @media all and (max-width: 600px) {
        margin-top: 100px;
        margin-bottom: 50px;
    }

    &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 43px;

        @media all and (max-width: 1100px) {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0;
        }
    }

}
