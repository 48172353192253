.advanced-radio {

  .MuiFormControlLabel-root {
    display: inline-block;
  }

  @media all and (max-width: 1100px) {

    .MuiFormControlLabel-label {
      font-size: 12px;
    }

    .MuiFormControlLabel-root {
      display: flex;
      flex-direction: row;
      margin-bottom: 0 !important;
    }

    &__inner {

      &__group {

        &--inline {
          display: flex;
          flex-direction: row;
        }

        &__label {
          margin-bottom: 15px;
        }

        @media all and (max-width: 1100px) {
         flex-direction: column !important;
        }
      }
    }
  }
}
