.login {

  &__row {
    margin: 30px auto;
  }

  &__form {
    display: flex;
    height: auto;
    justify-content: center;
    flex-direction: column;
    padding: 80px 0 150px 0;
    position: relative;

    &__close {
      position: absolute;
      top: 30px;
      right: 30px;

      &__button {
        outline: 0;
        background-color: transparent;
        font-size: 20px;
        border: none;
        cursor: pointer;
      }
    }

    &__inner {

      &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__logo {
        display: block;
        margin: 30px auto;
      }

      &__login {
        background-image: url(../../../assets/svg/ic_panel_login.svg) !important;
        background-repeat: no-repeat !important;
        padding: 0 0 0 45px;
        background-position-x: 5% !important;
        background-position-y: 45% !important;
        background-color: $white;
        margin: 0 0 30px;
        border: none;
        border-radius: 5px;
        height: 58px;
        width: 100%;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      &__password {
        background-image: url(../../../assets/svg/ic_panel_password.svg) !important;
        background-repeat: no-repeat !important;
        padding: 0 0 0 45px;
        background-position-x: 5% !important;
        background-position-y: 45% !important;
        background-color: $white;
        margin: 0 0 30px;
        border: none;
        border-radius: 5px;
        height: 58px;
        width: 100%;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }

      &__message {
        text-align: center;

        &__title {
          display: block;
          padding: 15px 0;
          font-size: 30px;
          font-weight: 800;
          color: $dark-blue;
          margin: 0;
          text-transform: uppercase;
        }

        &__message {
          text-align: center;
          padding-bottom: 15px;
          font-size: 20px;
          font-weight: 400;
          color: $black;
          margin: 0 0 20px 0;
          display: block;
          line-height: 24px;
        }
      }

      &__box-form {
        background-color: $white;
        width: 390px;
        padding: 30px 0;
        border-radius: 10px;

      }

      &__bottom {
        text-align: center;
        margin: 10px;
        color: $black;
      }

      &__register {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px auto;

        &__text-register {
          text-align: center;
          margin: 0;
        }

        &__text-link {
          text-align: center;

        }
      }
    }

    &__button {
      padding-top: 60px;
      width: 80%;
    }
  }

  .mobile-width {
    @media all and (max-width: 1100px) {
      width: 100% !important;
    }
  }

  .mobile-hidden{
    @media all and (min-width: 1099px) {
      width: 100%;
    }
  }
}
