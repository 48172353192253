.vertical-box-slider {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;

  &__item {

    &__inner {
      background-size: cover;
      height: 600px;
      background-position: center center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &__box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 45px;

        &__content {
          width: 70%;

          &__title {
            font-family: 'Poppins', sans-serif;
            font-size: 35pt;
            font-weight: bold;
            color: $dark-blue;
          }

          &__description {
            background-color: rgba(255, 255, 255, 0.3);
            color: $dark-blue;
            padding: 0;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 1368px) {
      
      &__inner {
        height: 435px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

  }

  .vertical-dots {
    right: 10px;
    list-style: none;
    display: flex!important;
    position: absolute;
    bottom: 0%;
    margin-top: -10px;
    text-align: center;
    left: 45%;
  }

  .vertical-dots li {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .vertical-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 30px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  .vertical-dots li button:hover,
  .vertical-dots li button:focus {
    outline: none;
  }

  .vertical-dots li button:hover:before,
  .vertical-dots li button:focus:before {
    opacity: 1;
  }

  .vertical-dots li button:before {
    font-family: 'slick', sans-serif;
    font-size: 15px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    content: '•';
    text-align: center;

    opacity: .20;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .vertical-dots li.slick-active button:before {
    opacity: .75;
    color: white;
  }
}
