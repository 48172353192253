.password-recovery {
  padding: 60px 0;
  display: block;

  &__form {
    display: flex;
    height: auto;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0 0 0;
    padding-bottom: 40px;

    &__inner {

      &__logo {
        display: block;
        margin: 30px auto;
      }

      &__message {
        text-align: center;

        &__title {
          display: block;
          padding: 15px 0;
        }

        &__message {
          text-align: center;
          padding-bottom: 15px;
        }
      }

      &__button {
        padding-top: 15px;
      }

      &__bottom {
        text-align: left;
        padding-top: 15px;
      }
    }

    &__active {
      display: none;
    }
  }

  &__code {
    display: flex;
    height: auto;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0 0 0;
    padding-bottom: 20px;

    &__active {
      display: none;
    }
  }
}
