.header-panel {

  &__items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background-color: $gray-item;
      border-radius: 10px;
      width: 100%;

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: $black; 
        margin: 0;
      }

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: $red;
        margin: 0;
    }
  }
}