.login-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;

  &--visible {
    display: flex;
    flex-direction: row-reverse;
  }

  &__inner {
    background-color: #fff;
    height: 100vh;
    width: 671px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 1100px) {
        width: 100vw;

      }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;

      &__buttonClose {
        background-color: #fff;
        color: black;
        border: none;
        position: absolute;
        top: 0;
        right: 1rem;
        font-size: 20px;
        cursor: pointer;
      }

      &__title {
        font-weight: bolder;
        font-size: 1.7rem;
        line-height: 2rem;
        margin-top: -3rem;
        color: #000;
        font-family: 'Montserrat';

        @media all and (max-width: 1100px) {
          margin-top: 80px;
          font-size: 30px !important;

        }
      }

      &__welcome {
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        font-family: 'Montserrat';
        width: 300px;
      }

      &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 26rem;

        &__label {
          margin-bottom: -1px;
          margin-top: 2rem;
          font-weight: 400;
          font-size: 16px;
          color: #626262;
          font-family: 'Montserrat';

          &__input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #626262;
            outline: none;
            width: 25rem;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            @media all and (max-width: 1100px) {
              width: 80vw;
    
            }
          }
        }

        &__button {
          width: 25rem;
          height: 68px;
          background-color: #025175;
          font-weight: 700;
          font-size: 1rem;
          color: #fff;
          border: none;
          border-radius: 3.5rem;
          margin-top: 3rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          font-family: 'Montserrat';

          &:hover {
            opacity: 0.9;
            transition: 0.3s;
          }

          @media all and (max-width: 1100px) {
            width: 80vw;
          }
        }
      }

      &__account {
        font-weight: 400;
        font-size: 16px;
        margin-top: 3rem;
        font-family: 'Montserrat';
      }

      &__register {
        color: #000;
        font-weight: bolder;
        text-decoration: underline;
        font-family: 'Montserrat';

        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}

@media all and (max-width: 1100px) {
  .login-modal {
    &__inner__container__account, &__inner__container__register {
      font-size: 15px;
    }
  }
}