.panel-content-header {
  
  &__inner {
    
    &__menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 50px 0 0 0;

      &__first {
        background-color: $white;
        box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        width: 197px;
        height: 161px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          color: $black;
          margin: 20px 0 0 0; 
        }

        &__aux {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 90%;

          p {
            margin: 0;
          }

          &__profile {
            height: 50px;
            width: 50px;
          }
        }
      }

      &__inner {
        display: flex;
        flex-direction: column;
        padding: 0;

        &__item {
          display: flex;
          list-style: none;
          margin: 30px 0 0 0;
          position: relative;
          align-items: center;
          justify-content: center;
          width: 167px;
          height: 75px;

          &__active {
            display: flex;
            list-style: none;
            margin: 30px 0 0 0;
            position: relative;
            align-items: center;
            justify-content: center;
            width: 167px;
            height: 75px;
            background-color: $primary-color;
            border-radius: 5px;
          }

          &__link {
            font-size: 20px;
            color: $primary-color;
            letter-spacing: 3px;
            text-align: center;
            font-weight: 500;

            &__active {
              color: $white;
              font-size: 15px;
              letter-spacing: 3px;
              text-align: center;
              font-weight: 500;
            }

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
