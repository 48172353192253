.feature-section {
  padding: 75px 0 0;
  width: 90%;
  margin: auto;

  @media all and (max-width: 1100px) {
    padding: 0 0 30px;
    min-height: calc(120vw);

    &--last {
      padding-bottom: 0;
      min-height: 0;

      .feature-section__container__inner__buttom {
        padding-bottom: 0;
      }
    }
  }

  & > .row {
    @media all and (max-width: 1100px) {
      flex-direction: column-reverse !important;
    }
  }

  &__container {
    margin: 0 0 0 auto;
    padding-right: 0;

    &__wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }

    &__inner {
      width: 60%;
      margin: 0 auto;

      @media all and (max-width: 1100px) {
        width: 100%;
        padding: 0 30px;
      }

      &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        text-transform: uppercase;
        margin-bottom: 20px;

        @media all and (max-width: 1100px) {
          font-size: 25px;
          line-height: 36px;
          padding-top: 15px;
        }
      }

      &__description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 75px;

        @media all and (max-width: 1100px) {
          font-size: 13px;
          padding-bottom: 30px;
        }
      }

      &__buttom {

        @media all and (max-width: 1100px) {
          padding-bottom: 35px;
          border-bottom: 1px solid $light-gray;

          &--no-border {
            border-bottom: none;
          }
        }

        &__link {

          &__inner {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            background-color: $primary-color;
            color: white;
            text-align: center;
            border-radius: 5px;
            padding: 12px 30px;
            transition: all ease-in-out .2s;
            text-transform: uppercase;

            &:hover {
              background-color: lighten($primary-color, 10);
            }

            @media all and (max-width: 1100px) {
              background-color: transparent;
              color: #1D6EA3;
              text-align: left;
              padding-left: 0;
              text-decoration-line: underline;
              font-size: 13px;
              line-height: 16px;
              text-transform: none;
            }
          }
        }
      }
    }

    &__image {
      width: 100%;
      height: 510px;
      background-position: center center;
      background-size: cover;
      border-radius: 10px;

      @media all and (max-width: 1100px) {
        height: 200px;
      }

      &__wrapper {

        @media all and (max-width: 1100px) {
          padding: 0 15px 15px;
        }
      }
    }
  }

  .col-lg-6 {
    padding: 0;
  }
}



