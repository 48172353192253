.profile-content-header {
  position: relative;
  background-color: #ffffff;
  height: 13vh;

  &__inner {
    padding-left: 150px;
    padding-top: 55px;
    display: flex;
    align-items: center;
    position: relative;

    @media all and (max-width: 1100px) {
      padding-left: 0;
      padding-top: 2vh;
      flex-direction: column;
    }

    &__group-low {
      @media all and (max-width: 1100px) {
        padding-top: 20px;
      }
    }

    &__item {
      display: flex;
      align-items: center;

      @media all and (max-width: 1100px) {
        display: flex;
      }

      &__register {
        margin: 0 10px;

        @media all and (max-width: 1100px) {
          margin: 0;
        }

        &__link {
          font-family: Montserrat, sans-serif;
          text-transform: capitalize;
          font-size: 2vh;
          color: $black;
          letter-spacing: 0.05em;
          font-weight: 400;
          background-color: #ffffff;
          padding: 12px 20px;
          border-radius: 50px;

          @media all and (max-width: 1100px) {
            padding: 6px 14px;
            font-size: 1.5vh;
          }


          &--active {
            background-color: $black;
            border-radius: 50px;
            color: #ffffff;
            font-weight: 700;
            padding: 12px 20px;

            @media all and (max-width: 1100px) {
              padding: 6px 14px;
            }
          }

          &:hover {
            background-color: $black;
            border-radius: 50px;
            color: #ffffff;
            font-weight: 700;
            padding: 12px 20px;

            @media all and (max-width: 1100px) {
              padding: 6px 14px;
            }
          }
        }
      }
    }
  }
}

.image {
  height: 100px;
  width: 100px;
}