.mobile-header-menu {
    width: 100vw;
    max-height: 80vh;

    &__lista {
        padding:20px 30px 0!important;
    }

    &__container {
        padding-bottom: 15px;

        &__top {
            display: flex;
            align-items: center;

            &__icon {
                margin-right: 8px;
            }

            &__text {
                font-size: 12px;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;

                &__link{
                    color: #626262!important;
                    &:hover {
                        color: #626262!important;
                    }
                }                
            }

            &--last{
                padding-bottom: 0;
            }

            &__close {
                margin-left: auto;

                &__icon {
                    color:#626262;
                    font-size:18px!important;
                }
            }
        }

        &__row {
            padding-bottom: 10px;

            &__title {
                font-size: 15px;
                line-height: 1;
                color: #506B7E;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold; 
                padding-bottom: 15px;   
            }
            
            &__link {
                font-size: 13px;
                line-height: 1;
                color: #626262;
                font-family: 'Montserrat', sans-serif;
                padding-bottom: 15px;   
                
                &__text{
                    color: #626262!important;

                    &--consultorio {
                        color: #6DA7CB!important;   
                    }

                    &:hover {
                        color: #626262!important;
                    }
                }
            }
        }
    }
}