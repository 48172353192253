.advanced-file-uploader {
  display: block;
  width: 100%;

  &__preview{
    width: 150px;
    height: 150px;
    padding: 20px;
    margin-top: -5px;
    
    &__img{ 
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }
  }

  &__upload {
    display: block;
    width: 100%;

    .ant-upload-list {
      display: none !important;
    }

    .ant-upload {
      display: block;
      width: 100%;
      position: relative;
    }

    &__button {
      position: absolute;
      top: 25px;
      right: 0;

      .ant-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--remove {
        top: 26px;
        right: 8px;

        .ant-btn {
          small {
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }

  &__preview {
    

    &__inner {
      padding: 0 !important;
      display: block !important;
      width: 50px !important;
      height: 50px !important;
      border-radius: 5px !important;
      border: none !important;

      &__preview {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        background-size: cover;
        background-position: center center;
        position: relative;

        &__button {
          position: absolute !important;
          right: 10px !important;
          bottom: 10px !important;
          background-color: $primary-color;
          color: white;
          border-radius: 5px;
          padding: 5px 10px;
        }
      }
    }
  }

  &__preview-modal {
    outline: 0 !important;

    &__close-icon {
      background-color: $primary-color;
      color: white;
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: relative;
      top: 7px;
      right: 0;

      .anticon {
        position: relative;
        top: -13px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
    }
  }
}
