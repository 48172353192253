.home-box-slider {
  width: 100%;
  overflow: hidden;
  border-radius: 30px;

  &__item {
    width: 100%;
    display: block;
    outline: 0 !important;

    &__inner {
      width: 100%;
      height: 600px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      &--one {
        background-image: url("../../assets/jpg/the-mens-home-banner-1.jpg");
        background-color: #a8bac6;
      }
      
      &--two {
        background-image: url("../../assets/jpg/image_banner_anti_aging.jpg");
        background-color: #ebddcf;
      }

      @media all and (min-width: 1100px) and (max-width: 1500px) {
        background-size: contain;
      }
    }
  }
}

.home-dots {
  right: 10px;
  list-style: none;
  display: flex !important;
  position: absolute;
  bottom: 0;
  margin-top: -10px;
  text-align: center;
  left: 45%;

  @media all and (max-width: 1100px) {
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;
    text-align: center;
    justify-content: center;
  }
}

.home-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.home-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.home-dots li button:hover,
.home-dots li button:focus {
  outline: none;
}

.home-dots li button:hover:before,
.home-dots li button:focus:before {
  opacity: 1;
}

.home-dots li button:before {
  font-family: 'slick', sans-serif;
  font-size: 15px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  content: '•';
  text-align: center;

  opacity: .20;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-dots li.slick-active button:before {
  opacity: .75;
  color: white;
}

.slick-list {
  margin-bottom: -9px !important;
}
