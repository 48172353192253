.product-display {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  overflow: hidden;
  margin: 0 15px;

  &__top {
    font-family: 'Roboto', sans-serif;
    color: $text-product;

    &__title {
      font-size: 16px;
      font-family: 'Roboto', sans-serif;

      &__link {
        color: $text-product;

        &:hover {
          color: lighten($text-product, 10);
          text-decoration: underline;
        }
      }
    }

    &__price {
      font-weight: bold;
    }
  }

  &__bottom {
    padding: 30px 0 0;
    position: relative;

    &__image {
      width: 50%;
      margin: auto;

      &__link {

        &__img {
          height: 150px;
          display: block;
          margin: 0 auto 30px;
        }
      }
    }

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__add {

        &__button {
          font-size: 16px;
          border: none;
          border-radius: 10px;
          background-color: #355572;
          color: white;
          width: 100%;
          font-weight: bold;
          padding: 11px 10px;
          box-shadow: $default-shadow;
          cursor: pointer;
          outline: 0;
        }

        &__img {
          height: 15px;
        }
      }
    }
  }
}
