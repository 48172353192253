.approved {
    padding: 20px 0;
    
    &__banner {
        background-color: $background-approved;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__links {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 30px 120px;

            &__text {
               color: $black;
               font-size: 13px;
               font-weight: 700; 

            }

            &__title {
                padding: 50px 120px;
                color: $white;
                font-size: 50px;
                font-weight: 800;
            }
        }
    }

    @media screen and (max-width: 9995px){

        &__banner {

            &__links {
                padding: 20px;

                &__title {
                    font-size: 35px;
                    padding: 0 30px;
                }
            }
        }
    }

    &__request {
        border: 2px solid $gray-cart;
        border-radius: 15px;
        padding: 60px 80px;
        margin: 120px auto;
        width: 800px;
        height: auto;

        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid $gray-cart;
            padding: 0 0 40px 0;
            color: $black;
            font-size: 30px;
            margin: 0 0 40px 0;
        }

        &__description {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: $gray-product;
        }
    }

    @media screen and (max-width: 995px){

        &__request {
             width: 90%;
             padding: 30px;
             margin: 40px auto;
        }
    }

    &__products {
        margin: 0 auto 100px auto;
        width: 80%;

        &__title {
            color: $dark-blue;
            font-size: 27px;
            font-weight: 700;
            text-align: center;
            margin: 0 auto 40px auto;
        }

        &__aux {
            display: flex;
            justify-content: space-around;
            align-items: center;

            &__block {
                width: 25%;
                height: auto;
                padding: 15px 20px;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                background-color: $background-block;

                &__title {
                    font-size: 27px;
                    color: $text-product;
                    margin: 0;
                    font-weight: 400;
                }

                &__description {
                    font-size: 17px;
                    color: $text-product;
                    margin: 0 0 10px 0;
                    font-weight: 300;
                }

                &__value {
                    font-size: 20px;
                    color: $text-product;
                    margin: 0 0 15px 0;
                    font-weight: 700;
                }

                &__image {
                    margin: 25px auto;
                    text-align: center;
                    display: flex;
                    justify-content: center;

                }

                &__aux {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 0 10px 0;

                    &__button {
                        background-color: $background-third-hair-kit;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                        width: 70%;
                        height: 54px;
                        color: $white;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 900;
                    }

                    &__cart {
                        width: 20%;
                        height: 54px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 10px;
                        background-color: $white;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 995px) {
        
        &__products {
            flex-direction: column;

            &__aux {
                flex-direction: column;

                &__block {
                    width: 100%;
                    margin: 20px 0;
                }
            }
        }
    }

    &__blog {
        width: 80%;
        margin: 150px auto;

        &__aux {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;

            &__link{
                width: 350px;
                min-width: 350px;  
                    
                &__block {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    cursor: pointer;
                    width: 90%;

                    &__product {
                        font-family: Poppins;
                        font-size: 20px;
                        color: $blog-title;
                        font-weight: 400;
                        margin: 0 0 6px 0;
                    }

                    &__title {
                        margin: 10px 0 0 0;
                        color: $dark-blue;
                        font-family: Poppins;
                        font-weight: 700;
                        font-size: 25px;
                        line-height: 37px;
                    }

                    &__article {
                        margin: 20px 0 0 0;
                        font-weight: 300;
                        text-decoration: underline;
                        color: $title-about;
                        font-size: 22px;
                        font-family: Poppins;
                    }
        
                }
            }
            
            &__link-two {
                margin: 150px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            
                &__text {
                    font-family: Poppins;
                    font-weight: 400;
                    font-size: 18px;
                    color: $article;
                    margin: 20px 0 0 0;
                }
            }
            
            &__border {
                width: 1px;
                background-color: $border-blog;
                height: 500px;
                margin: 0;
            }
        }
    }
}
