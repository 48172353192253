.checkout {
  padding: 30px 30px 90px;

  @media all and (min-width: 1100px) {
    padding: 30px 30px 90px;
  }

  @media all and (max-width: 1100px) {
    padding: 30px 30px 50px;
  }

  @media all and (min-width: 1100px) {
    padding: 50px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__go-back {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #868686;

    @media all and (min-width: 1100px) {
      padding-bottom: 30px;
    }

    &__text {
      font-size: 15px;
      margin-left: 5px;
      position: relative;
      top: 1px;
      text-transform: uppercase;
      font-family: 'PrimeformProMedium';
    }
  }

  &__coupon-form {
    padding-top: 15px;
  }

  &__upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 1100px) {
      margin-top: 0px;
    }

    &__done {
      font-family: 'Montserrat', sans-serif;
      color: #0fb916;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 30px;

      @media all and (max-width: 1100px) {
        padding-top: 30px;
      }

      &__icon {
        margin-left: 5px;
        position: relative;
        top: -4px;
      }
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__icon {
        font-size: 20px;
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 20px;
      }

      &__text {
        font-family: 'PrimeformPro', sans-serif;
        font-size: 17px;
        font-weight: bold;
        padding-bottom: 20px;
        letter-spacing: 0.05em;

        @media all and (max-width: 1100px) {
          font-size: 16px;
          padding-top: 30px;
        }

        &--padded {
          @media all and (max-width: 1100px) {
            padding-top: 0;
          }
        }
      }

      &__form {
        background-color: white;
        border-radius: 10px;
        padding: 30px;

        &__label {
          font-family: 'PrimeformPro', sans-serif;
          font-size: 1.375em;
          color: #000;

          @media all and (max-width: 1100px) {
            padding-bottom: 35px;
            font-size: 14px;
          }
        }

        &__desc {
          padding-bottom: 15px;
        }

        &__item {
          &__input {
            @media all and (max-width: 1100px) {
              padding-bottom: 15px;
            }
          }
        }
      }
    }

    &__clear {
      &__button {
        font-size: 13px;
      }
    }
  }

  &__loading {
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.91);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__inner {
      &__img {
        width: 80px;
        height: auto;
      }
    }
  }

  &__go-back {
  }
}
