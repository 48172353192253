.sleep-gummy {

  &__hero {
    background-color: #AD95C8;
    background: linear-gradient(180deg, #AD95C8 0%, #CCBCDE 60.38%, #DEA4A5 100%);
    position: relative;
    z-index: 2;
    display: grid;
    place-items: center;
    width: 100%;
    padding-top: 4vw;
    padding-bottom: 0;

    @media all and (max-width: 1100px) {
      padding-bottom: 150px;
    }

    &__logo {
      width: 10vw;
      margin-bottom: 3vw;
      image-rendering: -webkit-optimize-contrast;
      cursor: pointer;

      @media all and (max-width: 1100px) {
        width: 97px;
        height: 72px;
        margin-bottom: 26px;
      }
    }

    &__text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 2.7vw;
      color: #FFF;
      text-align: center;
      width: 70vw;
      margin-bottom: 3vw;

      @media all and (max-width: 1100px) {
        width: 306px;
        font-size: 15px;
      }
    }

    &__yt {
      margin-left: -37vw;
      margin-top: 2vw;
      width: 36vw;
      aspect-ratio: 16/9;
      border-radius: 20px;

      @media all and (max-width: 1100px) {
        margin-left: 0vw;
        width: 301px;
        height: 166px;
        margin-top: 27px;
        aspect-ratio: none;
        border-radius: 15px;
      }
    }

    &__button {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 1.2vw;
      text-transform: uppercase;
      margin-bottom: 6vw;
      padding: 1.2vw 2.2vw;
      cursor: pointer;
      border-radius: 90px;
      background: #FB3B3D;
      color: #FFFFFF;

      &--mobile {
        margin-top: 26px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        background: #FB3B3D;
        border-radius: 90px;
        padding: 15px 46px
      }
    }

    &__hero {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;

      @media all and (min-width: 1100px) {
        top: 32vw;
      }

      @media all and (max-width: 1100px) {
        bottom: -50px;
      }
    }
  }

  &__content {
    margin-top: 18vw;

    &__one {
      width: 100%;
      padding-left: 26vw;
      padding-right: 25vw;

      @media all and (max-width: 1100px) {
        padding: 0 13px;
        margin-bottom: 100px;
      }
    }

    &__two {
      width: 100%;
      margin-top: 6rem;
      padding-left: 18vw;
      padding-right: 18vw;
      image-rendering: -webkit-optimize-contrast;

      @media all and (max-width: 1100px) {
        padding: 0 35px;
        padding-left: 25px;
        margin-bottom: 100px;
      }
    }

    &__three {
      margin: 0 4vw;
      margin-top: 8rem;

      &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 2.9vw;
        text-align: center;
        color: #442565;
        margin-bottom: 4rem;

        @media all and (max-width: 1100px) {
          font-style: normal;
          font-weight: 800;
          font-size: 25px;
          line-height: 31px;
          margin-bottom: 40px;
        }
      }

      &__container {
        display: flex;
        margin: 0 8vw;
        margin-left: 10vw;
        justify-content: space-between;

        @media all and (max-width: 1100px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 65px;
        }

        &__item {
          display: flex;
          flex-direction: column;
          width: 29vw;
          padding-left: 2vw;

          @media all and (max-width: 1100px) {
            width: auto;
            margin-bottom: 40px;
            padding-left: 0;
          }

          &__img {
            width: 17vw;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 36px;
            margin-bottom: 2rem;

            @media all and (max-width: 1100px) {
              width: 100%;
            }
          }

          &__title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 1.8vw;
            color: #000;
            margin-bottom: 1rem;

            @media all and (max-width: 1100px) {
              font-style: normal;
              font-weight: 800;
              font-size: 22px;
            }
          }

          &__text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 1.2vw;
            color: #757575;

            @media all and (max-width: 1100px) {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
            }
          }
        }
      }
    }

    &__five,
    &__six {}

    &__five {
      width: 100%;
      padding: 0 19.6vw;
      margin-top: 9rem;

      @media all and (max-width: 1100px) {
        margin-top: 3rem;
      }
    }

    &__six {
      width: 100%;
      padding: 0 10.1vw;
      margin-top: 9rem;

      @media all and (max-width: 1100px) {
        padding: 0 16vw;
      }
    }

    &__four {
      margin-top: 8rem;

      @media all and (max-width: 1100px) {
        width: 100%;
      }

      &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 3.2vw;
        text-align: center;
        color: #442565;
        margin-bottom: 1rem;
      }

      &__container {
        width: 100vw;
        position: relative;
        margin-bottom: 43vw;
        z-index: 2;

        &__text {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 1.4vw;
          color: #757575;
          position: absolute;
          top: 10vw;
          left: 45vw;
          line-height: 2vw;
        }

        &__price {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 2vw;
          color: #000000;
          position: absolute;
          top: 20.4vw;
          left: 45vw;
          line-height: 3vw;

          & span.bigger {
            color: #442565;
            font-size: 3.2vw;
          }

          & span.big {
            color: #442565;
          }
        }

        &__banner {
          top: 0;
          left: 0;
          width: 100%;
          position: absolute;
          z-index: -1;
        }

        &__button {
          color: #FFF;
          background-color: #B23A53;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 2vw;
          text-align: center;
          border-radius: 9px;
          top: 31.6vw;
          left: 45vw;
          position: absolute;
          padding: 0.2vw 3.2vw;
          cursor: pointer;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 1.2vw;
          text-transform: uppercase;
          margin-bottom: 6vw;
          padding: 1.2vw 2.2vw;
          cursor: pointer;
          border-radius: 90px;
          background: #FB3B3D;
          color: #FFFFFF;
        }
      }

      &__disclaimer {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 1.2vw;
        text-align: center;
        color: #C4C4C4;
        margin: 0 auto;
      }
    }

    &__faq {
      margin: 0 7vw;
      margin-top: 9rem;

      &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 2.2vw;
        color: #442565;
        margin-bottom: 2rem;

        @media all and (max-width: 1100px) {
          font-size: 25px;
        }
      }

      &__container {

        &__item {
          margin-top: 1rem;
          border-bottom: 1px solid #000000;
          cursor: pointer;

          &__title {
            display: flex;
            justify-content: space-between;

            p {
              margin-bottom: 1rem;
              color: #000;
              font-size: 1.4vw;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;

              @media all and (max-width: 1100px) {
                font-size: 18px;
              }
            }

            span {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 2.8vw;
              color: #000;
              line-height: 2vw;

              @media all and (max-width: 1100px) {
                font-size: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          &__text {
            margin-bottom: 1rem;
            color: #000;
            font-size: 1.4vw;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 300;

            @media all and (max-width: 1100px) {
              font-size: 18px;
            }
          }
        }
      }
    }

    &__recomended {
      margin-left: 1vw;
      margin-top: 9rem;
      margin-left: 1.5vw;

      @media all and (max-width: 1100px) {
        margin-left: 7vw;
      }

      & .recomended-products__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 2.2vw;
        color: #442565;
        margin-bottom: 2rem;
      }
    }
  }
}