.checkout-cart {

  &--finished {
  }

  &__purchase-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 1rem 0 0.5rem 0;

    @media all and (max-width: 1100px) {
      justify-content: space-around;
    }

    &__summary-text {
      font-family: 'PrimeformPro';
      font-weight: 700;
      font-size: 1.375em;

      img {
        width: 25px;
        height: 24px;
        margin: 0 0 0.4rem 0.3rem;
      }

      @media all and (max-width: 1100px) {
        font-size: 0.75em;

        img {
          width: 13px;
          height: 13px;
          margin: 0 0 0.2rem 0rem;
        }
      }
    }

    &__security-text {
      font-family: 'PrimeformPro';
      font-weight: 700;
      color: #C6C6C6;
      font-size: 0.875em;

      img {
        width: 17px;
        height: 21px;
        margin: 0 0.3rem 0.3rem 0;
      }

      @media all and (max-width: 1100px) {
        font-size: 0.75em;

        img {
          margin: 0 0 0.3rem 0.1rem;
        }
      }
    }

    &__laboratory-text {
      font-size: 1.125em;
      @media all and (max-width: 1100px) {
        font-size: 0.75em;
        color: #626262;

        img {
          margin: 0 0 0.3rem 0.1rem;
        }
      }
    }

    &__postal-service-text {
      font-size: 1.125em;
      @media all and (max-width: 1100px) {
        font-size: 0.75em;
        color: #626262;

        img {
          margin: 0 0 0.3rem 0.1rem;
        }
      }
    }
  
  }

  &__inner {
    background-color: #fff;
    border-radius: 1.563rem;
    width: 35.688rem;

    @media all and (max-width: 1100px) {
      width: 100%;
    }
  }

  &__details {
  }
}

.checkout--step-four__buy-button {
  text-align: center;
  background-color: #0f6cab;
  border-radius: 50px;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 3px 3px 20px #1d6ea3;
  margin-top: 50px;
  font-family: 'PrimeformPro';

  @media all and (max-width: 1100px) {
    margin: 0 10px 50px 10px;
  }

  &__item {
    display: flex;
    justify-content: center;

    &__text {
      padding: 25px 0;
      font-size: 20px;
      text-align: center;
      line-height: 27px;
      font-weight: 700;
      font-family: 'PrimeformPro';

      @media all and (max-width: 1100px) {
        padding: 21px 0;
        font-size: 15px;
      }
    }
  }
}

.checkout--step-four__buy-button-disabled {
  text-align: center;
  background-color: #b6afafc2;
  border-radius: 50px;
  color: #000000;
  cursor: pointer;
  box-shadow: 3px 3px 20px #b6afafc2;
  margin-top: 50px;
  font-family: 'PrimeformPro';

  @media all and (max-width: 1100px) {
    margin: 0 10px 50px 10px;
  }

  &__item {
    display: flex;
    justify-content: center;

    &__text {
      padding: 25px 0;
      font-size: 20px;
      text-align: center;
      line-height: 27px;
      font-weight: 700;
      font-family: 'PrimeformPro';

      @media all and (max-width: 1100px) {
        padding: 21px 0;
        font-size: 15px;
      }
    }
  }
}
