.whatsapp-help-info {
  font-family: 'PrimeformProMedium', sans-serif;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #474747;
  padding-top: 50px;
  align-items: center;
  background-color: #F2F2F2;

  @media all and (min-width: 1100px) {
    padding-bottom: 15px;
  }

  @media all and (max-width: 1100px) {
    display: inline-block;
  }

  &__icon {
    width: 35px;
    margin-right: 8px;

    @media all and (max-width: 1100px) {
      width: 30px;
    }
  }

  &__data {
    font-weight: 500;
    font-size: 1.1rem;

    @media all and (max-width: 1100px) {
      font-size: 0.8rem;
    }

    &__link {
      color: #46c057;
      text-decoration: underline;
    }
  }
}
