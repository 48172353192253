.cart-modal {
    margin-top: 50px;
    padding: 0 10px;

    &__close {

      &__button {
        text-align: right;
        font-size: 1.2rem;
        margin-bottom: 35px;
        margin-right: 5px;
        cursor: pointer;

        @media all and (min-width: 1600px) {
          font-size: 1.3rem;
        }
      }
    }

    &__text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: #474747;

      
      @media all and (min-width: 1600px) {
        font-size: 16px;
      }
    }

    &__confirm {
      margin-top: 25px;
      width: 100%;
      text-align: center;
      background: #1D6EA3;
      border-radius: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      color: #FFFFFF;
      padding: 15px 0;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
      cursor: pointer;

      @media all and (min-width: 1600px) {
        font-size: 13px;
      }
    }

    &__deny {
      margin-top: 15px;
      width: 100%;
      text-align: center;
      border-radius: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      color: #626262;
      border: 1px solid #626262;
      padding: 15px 0;
      cursor: pointer;

      @media all and (min-width: 1600px) {
        font-size: 13px;
      }
    }
  }

  .cart-modal-variant {

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #474747;
      margin-bottom: 10px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;

      &__item {
        border: 1px solid #925884;
        border-radius: 18px;
        margin-right: 25px;
        margin-bottom: 23px;
        width: 141px;
        position: relative;
        cursor: pointer;

        &--selected {
          padding-top: 2%;
          border-radius: 14px;
          border: 2px solid #925884;

          .cart-modal-variant__content__item__image {
            top: -4%;
            left: 0;
            width: 137px;
          }
        }

        &__radio {
          left: 7px;
          top: 7px;
          width: 21px;
          height: 21px;
          position: absolute;
          border-radius: 50%;
          z-index: 101;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          &__selected {
            background: #925884;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }

        &__image{
          top: -2%;
          left: -1px;
          width: 141px;
          position: relative;
          z-index: 100;
        }

        &__name {
          padding-bottom: 5px;
          padding-left: 10px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #474747;

          &__unit-price {
            font-size: 8px;
            margin-left: 4px;
          }
        }

        &__price {
          padding-left: 10px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #474747;
        }

        &__label {
          padding-left: 10px;
          padding-bottom: 8px;
          width: 70%;
          image-rendering: -webkit-optimize-contrast;
        }
      }
    }
  }