.carousel-banner-creatina {
  width: 100.208vw;
  height: 62.5vh;
  background: linear-gradient(
    180deg, #CACACA 
    0%,
    #FEFEFE 100%);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  @media all and (max-width: 1100px) {
    height: 50%;
  }

  &__inner {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;

    @media all and (max-width: 1100px) {
      justify-content: space-between;
      align-items: center;
    }
    
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media all and (max-width: 1100px) {
        width: 60%;
      }

      &__container { 
        width: 36.719vw;

        @media all and (max-width: 1100px) {
          width: 90%;
        }

        @media all and (min-width: 1920px) {
          display: flex;
          flex-direction: column;
        }

        &__subtitle {
          font-weight: 300;
          font-size: 1.75em;
          line-height: 43px;

          @media all and (max-width: 1100px) {
            font-size: 11px;
            line-height: 17px;
          }

          @media all and (min-width: 1920px) {
            font-size: 2.5em;
          }
        }

        &__title {
          font-family: 'PrimeformPro';
          font-weight: 700;
          font-size: 2.5em;
          line-height: 55px;
          color: #0B0F2B;

          @media all and (max-width: 1100px) {
            font-size: 1.25em;
            line-height: 27.14px;
          }

          @media all and (min-width: 1920px) {
            font-size: 3em;
          }
        }
      }
    }

    &__creatina-image {
      display: flex;
      align-items: end;

      @media all and (max-width: 1100px) {
        width: 45%;
        margin-top: 1.5rem;
      }

      &__image {
        width: 95%;
        height: 85%;

        img {
          width: 770px;
          height: 524px;
        }

        @media all and (max-width: 1100px) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__button-creatina {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #FFFFFF;
    background-color: #000000;
    border-radius: 50px;

    @media all and (max-width: 1100px) {
      font-size: 1em;
      width: 40vw;
      height: 3rem;
    }

    @media all and (min-width: 1920px) {
      width: 40vw;
      height: 3rem;
    }

    &:hover {
      color: #FFFFFF;
      opacity: 0.9;
    }
  }

  &__button-creatina-desk {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.563em;
    color: #FFFFFF;

    border-radius: 3.125rem;
    border: none;

    width: 22.813rem;
    height: 5rem;

    box-shadow: 1px 3px 3px 2px rgba(91, 245, 115, 0.48);
    background-color: #000000;

    margin-top: 2rem;
    cursor: pointer;

    @media all and (max-width: 1100px) {
      font-size: 13px;
      width: 45vw;
      height: 8vh;
    }

    &:hover {
      color: #FFFFFF;
      opacity: 0.9;
    }
  }
}