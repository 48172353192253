.breadcrumb {
  display: flex;
  flex-direction: row;

  @media all and (max-width: 1100px) {
    justify-content: center;
    padding-top: 30px;
  }

  &__item {
    padding-right: 15px;
    margin-right: 15px;
    font-weight: 300;
    color: black;
    position: relative;
    text-align: center;

    &:after {
      content: '/';
      position: absolute;
      top: 0;
      right: -2px;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }

    &:last-child:after {
      content: '';
    }

    &__link {
      text-transform: uppercase;
      color: black;

      &:hover {
        color: black;
        text-decoration: underline;
      }

      &--disabled {
        pointer-events: none !important;
        opacity: 0.8;
      }
    }
  }
}
