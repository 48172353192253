.chat-panel {
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  height: 83vh;
  margin: 20px 15px 30px -10px;
  width: 100%;

  @media all and (max-width: 1100px) {
    flex-direction: column;
    height: auto;
    margin: 0;
    padding: 0 0 30px;
  }

  &__loading {
    margin: auto 0;
    position: fixed;
    left: 50%;
    top: 50%;
  }

  &__left {
    width: 330px;
    overflow-x: scroll;
    background-color: $gray-chat-bg;
    padding: 15px 15px 0;
    border-radius: 8px;

    @media all and (max-width: 1100px) {
      display: flex;
      flex-direction: row;
      background-color: transparent;
      padding: 0;
    }

    &__message {
      border: 1px solid $gray-bg;
      border-radius: 8px;
      padding: 15px;
      background-color: $gray-chat-msg-bg;
      cursor: pointer;
      margin-bottom: 15px;
      transition: ease-in-out .2s;

      @media all and (max-width: 1100px) {
        margin-right: 15px;
      }

      &:hover {
        background-color: lighten($gray-chat-msg-bg, 1.3);
      }

      &__list {
        display: flex;
        flex-direction: row;

        &__info {
          display: flex;
          flex-direction: column;

          &__title {
            font-size: 12pt;
            font-weight: bold;
            color: black;
          }

          &__answer {
            font-size: 10pt;
            color: black;
          }

          &__date {
            font-size: 10pt;
            color: black;
          }
        }

        &__unread {
          margin-left: 100px;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex: 1;
    background-color: $gray-chat-bg;
    margin-left: 15px;
    border-radius: 8px;
    flex-direction: column;
    overflow: hidden;

    @media all and (max-width: 1100px) {
      min-height: 500px;
    }

    @media all and (max-width: 1100px) {
      margin: 0;
    }

    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px;

      &__left {

        &__name {
          font-size: 15pt;
          font-weight: bold;
        }

        &__data {
          display: flex;
          flex-direction: row;

          &__item {
            margin-right: 30px;
          }
        }
      }
    }

    &__messages {
      background-color: $dark-gray;
      display: flex;
      flex: 1;
      overflow-y: scroll;
      width: 100%;
      flex-direction: column;
      padding-top: 15px;

      &__single {
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-top: 25px;

        &__inner {
          padding: 15px;
          border-radius: 8px;
          margin: 0 15px 15px;
          max-width: 80%;

          &--other {

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 15px 15px;
              border-color: transparent transparent #ffffff transparent;
              position: absolute;
              left: -5px;
              top: calc(100% - 15px);
            }
          }

          &--mine {

            &:after {
              content: '';
              width: 0;
              height: 0;
              border-width: 15px 0 0 15px;
              border-color: transparent transparent transparent $primary-color;
              position: absolute;
              right: -5px;
              top: calc(100% - 15px);
            }
          }

          &__text {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            font-family: 'Montserrat';

            @media all and (max-width: 1100px) {
              font-size: 13px;
              line-height: 13px;
            }

            &__confirmation {

              &__text {}

              &__dates {
                display: flex;
                flex-direction: row;

                &__wrapper {}

                &__selected-date {
                  margin-right: 15px;
                  position: relative;
                  top: 5px;
                }

                &__hour {
                  background-color: transparent;
                  color: $blue;
                  padding: 3px 5px 1px;
                  border: 1px solid transparent;
                  margin-right: 15px;
                  outline: 0 !important;
                  border-radius: 5px;
                  font-size: 15px;
                  font-weight: bold;
                  cursor: text;
                }
              }
            }
          }

          &__date {
            color: #626262;
            font-size: 16px;
            min-width: 151px;
            margin-top: -40px;
            height: 35px;

            @media all and (max-width: 1100px) {
              font-size: 11px;
              min-width: 151px;
              margin-top: -34px;
              height: 32px;
            }
          }
        }
      }
    }

    &__answer-area {
      background-color: #fff;
      width: 70%;
      padding: 1rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 3.5rem;

      @media all and (max-width: 1100px) {
        height: 205px;
        padding: 0px 30px 10px 23px;
      }

      &__options {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        @media all and (max-width: 1500px) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 8px;
        }

        @media all and (max-width: 1100px) {
          align-items: flex-start;
          justify-content: center;
        }

        // @media all and (max-width: 900px) {
        //   flex-direction: column;
        //   align-items: flex-start;
        // }

        &--first {
          margin-right: 15px;

          @media all and (max-width: 1100px) {
            width: auto;
          }

          @media all and (max-width: 900px) {
            margin: 0;
            width: auto;
          }
        }
      }

      &__items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 18px;

        &__single {

          &--input {
            flex: 1;
            padding-right: 15px;
            position: relative;
            top: 8px;
          }
        }
      }

      &__click {
        font-size: 1.2em;
        font-family: 'Montserrat';
        background: blue;
        padding: 1rem;

        @media all and (max-width: 1100px) {
          font-size: 12px;
          line-height: 22px;
          padding-bottom: 9px;
        }
      }
    }
  }
}

.chat-margin {

  @media all and (max-width: 1100px) {
    margin-left: 0;
  }
}

.chat-profile {
  padding: 30px;
  padding-top: 89px;
  display: flex;
  background-color: #F2F2F2;
  padding-bottom: 700px;

  @media all and (max-width: 1100px) {
    padding: 0;
    padding-top: 31px;
    display: flex;
    background-color: #F2F2F2;
    padding-bottom: 150px;
  }

  &__title {
    color: #000000;
    font-weight: 700;
    font-size: 28px;
    font-family: 'MontSerrat';
    text-transform: uppercase;
    margin-bottom: 39px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  &__tabs {
    display: flex;
    color: #C4C4C4;
  }

  &__consult {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem 1rem 1rem 0;
    margin: 0.1rem;
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
    height: 74px;
    background-color: #ffffff;
    width: 300px;
    color: #000;
    cursor: pointer;

    &:nth-child(1) {
      margin-left: 0;
    }

    &--active {
      background-color: #E9E9E9;
      color: #000000;
      z-index: 1;
    }

    &__text {
      font-family: 'Montserrat';
      font-size: 1em;
      line-height: 18px;
      text-transform: uppercase;
      font-weight: 400;
      text-align: center;

      @media all and (max-width: 1100px) {
        font-size: 10px;
      }
    }

    @media all and (max-width: 1100px) {
      height: 4rem;
      width: 7rem;
      margin-left: 0px;
    }
  }

  &__item {
    width: 90vw;
    background-color: #ffffff;
    border-radius: 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;

    @media all and (max-width: 1100px) {
      width: 100%;
      height: 105vh;
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 43px;
      margin-top: 49px;
      margin-right: 112px;

      @media all and (max-width: 1100px) {
        margin-left: 23px;
        margin-top: 19px;
        margin-right: 32px;
      }

      &__doctor {
        color: #000000;
        font-size: 24px;
        font-weight: 600;
        font-family: 'Montserrat';
        line-height: 29px;

        @media all and (max-width: 1100px) {
          font-size: 12px;
        }
      }

      &__right {
        color: #ffffff;
        font-size: 17px;
        font-family: 'Montserrat';
        width: 284px;

        @media all and (max-width: 1100px) {
          font-size: 12px;
          width: 170px;
        }

        &__button {
          background-color: #1D70A3;
          width: 343px;
          font-weight: 600;
          height: 52px;
          border: none;
          border-radius: 10px;
          cursor: pointer;

          @media all and (max-width: 1100px) {
            height: 45px;
            width: 170px;
          }
        }
      }

      &__underline {
        border: 1px solid #acacac;
        margin-left: 43px;
        margin-right: 55px;
        margin-bottom: 19px;

        @media all and (max-width: 1100px) {
          border: 1px dashed #acacac;
          margin-left: 23px;
          margin-right: 32px;
          margin-bottom: 0px;
        }
      }
    }

    &__messages {
      display: flex;
      overflow-y: scroll;
      flex-direction: column;
      height: 100%;

      @media all and (max-width: 1100px) {
        padding-top: 0px;
        margin-right: 15px;
        padding-left: 23px;
      }
    }
  }
}

.chat-button {
  &__round {
    border-radius: 50px !important;
    font-size: 20px !important;
    color: #000000 !important;
    background-color: #e5e5e5 !important;
    font-weight: 500 !important;
    font-family: 'Montserrat' !important;

    @media all and (max-width: 1100px) {
      margin-bottom: 10px !important;
      font-size: 13px !important;
    }
  }
}

.chat-profile {
  &__doubt {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media all and (max-width: 1100px) {
      padding-top: 20px;
    }

    &__text {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      color: #0c1931;
      line-height: 22px;
      font-family: 'Montserrat';

      @media all and (max-width: 414px) {
        font-size: 11px;
        text-align: center;
      }

      @media all and (max-width: 1000px) {
        font-size: 13px;
      }
    }
  }

  &__doubt-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 10rem;
    height: 3rem;
    padding: 1rem;

    background-color: #61BE91;

    font-weight: 700;
    font-size: 1em;
    font-family: 'Montserrat';
    color: #fff;
    border: none;

    border-radius: 2rem;
    margin-top: 1rem;
    cursor: pointer;

    @media all and (max-width: 414px) {
      width: 100% !important;
      height: 58px;
      border-radius: 15px;
    }

    @media all and (max-width: 1000px) {
      width: 25vw;
      height: 60px;
      border-radius: 15px;
    }
  }

  &__no-chat {
    margin-top: 34px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'MontSerrat';
    font-size: 20px;

    @media all and (max-width: 1100px) {
      font-size: 12px;
    }
  }
}