.amount-picker {
  background-color: white;
  border-radius: 50px;
  box-shadow: $default-shadow;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100px;
  max-width: 100px;
  min-height: 32px;
  font-family: 'Montserrat';

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4px;

    &__button {
      font-family: sans-serif;
      font-weight: bold;
      font-size: 14pt;
      background-color: transparent;
      border: none;
      outline: 0 !important;
      cursor: pointer;
      color: black !important;
      user-select: none;

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          line-height: 14pt;
        }
      }
    }
  }

  &__value {
    text-align: center;
    color: black !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    user-select: none;
  }
}
