.newsletter-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.4);
  z-index: 1001;

  @media all and (max-width: 1100px) {
    align-items: center;
    justify-content: center;
  }

  &--hidden {
    display: none;
  }

  &--visible {
    display: flex;
  }
}

