.recomended-products {
    padding-left: 5.5vw;

    &__title {
        font-size: 1.5vw;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        color: #000000;
        margin-bottom: 1.5vw;
    }

    &__slider {

        .slick-list {
            padding-left: 0 !important;
        }

        &__item {
            padding-right: 3vw;
            position: relative;
            height: 35vw;

            &__sold-off {
                position: absolute;
                top: 10px;
                left: 5px;
                background: red;
                border-radius: 50px;
                padding: 4px 12px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                color: #FFF;
                font-size: 8px;

                @media all and (max-width: 1100px) {
                    font-size: 6px;
                    top: 43px;
                    left: 15px;
                    padding: 2px 8px;
                }
            }

            &__img {
                margin-bottom: 0.2vw;
                width: 100%;
            }

            &__stars {
                color: #2B2B2B;
                font-size: 1.9vw;
                padding-left: 0.8vw;
                margin-bottom: 0;
                padding-bottom: 0.8vw;
            }

            &__price {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 0.9vw;
                margin-bottom: 0;
                padding-left: 0.8vw;
                color: #2B2B2B;
            }

            &__name {
                font-family: Montserrat;
                font-style: normal;
                font-weight: bolder;
                font-size: 1.1vw;
                margin-bottom: 0.4vw;
                padding-left: 0.8vw;
                color: #2B2B2B;
            }

            &__text {
                font-family: Montserrat;
                font-style: normal;
                padding-left: 0.8vw;
                color: #2B2B2B;
                margin-bottom: 1.4vw;
                font-size: 0.8vw;
            }

            &__button {
                position: absolute;
                bottom: 0;
                margin-right: 3vw;
                font-family: Montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 0.8vw;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                width: -webkit-fill-available;
                border: 1px solid #000000;
                padding: 0.8vw;
                cursor: pointer;
                background-color: #FFFFFF;

                svg {
                    width: 0.9vw;
                    padding-bottom: 0.3vw;

                    path {
                        fill: #000000;
                    }
                }

                &:hover {
                    background-color: #000000;
                    color: #FFFF;

                    svg {

                        path {
                            fill: #FFFF;
                        }
                    }
                }
            }
        }
    }
}

.recomended-products-mobile {

    &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #2B2B2B;
        margin-bottom: 20px;
    }

    &__slider {

        .slick-list {
            padding-left: 0 !important;
        }

        &__item {
            padding-right: 33px;
            height: 500px;
            position: relative;
            margin-bottom: 40px !important;

            &__img {
                width: 100%;
                margin-bottom: 17px;
            }

            &__stars {
                font-size: 21px;
                padding-left: 3px;
                margin-bottom: 21px;
            }

            &__price {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 0;
            }

            &__name {
                font-family: Montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 17px;
            }

            &__text {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }

            &__button {
                position: absolute;
                bottom: -40px;
                width: -webkit-fill-available;
                margin-right: 33px;
                border: 1px solid #000000;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 15px;
                color: #474747;

                svg {
                    width: 14px;
                    padding-bottom: 4px;

                    path {
                        fill: #000000;
                    }
                }

                &:hover {
                    background-color: #000000;
                    color: #FFFF;

                    svg {

                        path {
                            fill: #FFFF;
                        }
                    }
                }
            }
        }
    }
}