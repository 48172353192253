.checkout {
  &__content {
    &__logged {
      &__hello {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 15px;
      }

      &__name {
        font-size: 15px;

        &__name {
          text-transform: capitalize;
        }

        &__logout-button {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    &__form {
      &__title {
        font-size: 20px;
        margin-bottom: 0;
      }

      &__change {
        padding-bottom: 30px;

        &__button {
          font-weight: bold;
          text-decoration: underline;
        }
      }

      &__choice {
        &__items {
          padding-top: 15px;

          @media all and (min-width: 1100px) {
            display: flex;
            flex-direction: row;
          }

          &__item {
            border: none;
            color: white;
            background-color: $primary-color;
            border-radius: 8px;
            display: block;
            width: 100%;
            padding: 10px 0;
            font-weight: bold;
            margin-bottom: 15px;

            @media all and (min-width: 1100px) {
              width: 200px;
              margin-right: 15px;
            }
          }
        }
      }

      &__item {
        &__label {
          color: black;
          font-size: 15px;

          &__input {
            font-family: 'PrimeformProRegular';
            padding: 0;

            &__desc {
              font-family: 'PrimeformPro';
              color: #626262;
            }

            &__button {
              font-weight: bold;
              color: white;
              background-color: #3b5c7a;
              border-radius: 5px;
              border: none;
              outline: 0;
              padding: 17px 30px;
              cursor: pointer;

              &:hover {
                background-color: lighten(#3b5c7a, 11);
              }
            }

            &--padded {
              @media all and (min-width: 1100px) {
                padding-left: 15px;
              }
            }

            padding-bottom: 15px;

            &--flex {
              display: flex;
              flex-direction: row;

              &__item {
                flex: 1 1 0;
                padding-right: 15px;

                &:last-child {
                  padding-right: 0;
                }
              }
            }

            &--freight {
              border-bottom: 1px solid #ededed;
              margin: 15px 0;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;

              &__item {
                padding-right: 30px;
              }
            }

            &__label {
              display: flex;
            }
          }

          &__inner {
            display: flex;
          }

          &__value {
            margin: 15px 10px;
            letter-spacing: -1px;
          }
        }
      }

      &__title {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 15px;
        padding-top: 30px;
      }

      &__upload {
        .ant-upload {
          width: 100%;
        }

        &__uploader {
          padding-bottom: 30px;

          &__button {
            width: 100%;
            display: block;
            min-height: 60px;
            padding: 0 20px;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #77b8e1;
            color: #626262;
            transition: all 200ms ease-in-out;

            &:hover {
              cursor: pointer;
              background-color: #77b8e1;
              color: white;
            }

            &__inner {
              font-family: 'Montserrat', sans-serif;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              &__text {
                @media all and (max-width: 1100px) {
                  white-space: pre-wrap;
                }
              }

              &__icon {
                font-size: 23px;
              }
            }
          }
        }
      }

      &__attention {
        color: #818181;

        &__title {
          display: flex;
          flex-direction: row;
          font-weight: bold;
          align-items: center;
          margin-bottom: 15px;

          &__phrase {
            font-size: 16px;
            margin-left: 8px;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    margin-top: 65px;
    background: rgba(74, 201, 89, 0.13);
    border: 3px solid #4ac959;
    border-radius: 50px;
    padding: 20px 46px 20px 33.5px;
    margin-right: 115px;

    @media all and (max-width: 1100px) {
      display: flex;
      margin-top: 26px;
      background: rgba(74, 201, 89, 0.13);
      border: 3px solid #4ac959;
      border-radius: 50px;
      padding: 7px 46px 7px 33.5px;
      margin-right: 0;
    }

    &__icon {
      margin-right: 16.5px;
      align-items: center;
      display: flex;
    }

    &__text {
      color: #000000;
      line-height: 27px;
      font-size: 17px;
      font-weight: 500;
      font-family: 'PrimeformProMedium';

      @media all and (max-width: 1100px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

.warning-limit-character {
  padding-left: 0.4rem;
  font-size: 0.7rem;
}
