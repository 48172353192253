.signature-profile {
  padding: 30px;
  padding-top: 89px;
  display: flex;
  height: auto;
  background-color: #F2F2F2;
  padding-bottom: 700px;

  @media all and (max-width: 1100px) {
    padding-top: 31px;
    padding-left: 0;
    padding-bottom: 500px;
    padding-right: 0;
  }

  &__content {
    padding: 0;
    display: flex;

    &__first {
      display: flex;

      &__img {
        height: 100px;
        margin-right: 20px;
        border-radius: 10px;

        @media all and (max-width: 1100px) {
          height: 90px;
        }
      }
    }
  }

  &__title {
    color: #000000;
    font-weight: 700;
    font-size: 28px;
    font-family: 'MontSerrat';
    text-transform: uppercase;
    margin-left: 20.5vw;
    margin-bottom: 18px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      margin-bottom: 9px;
      margin-left: 0;
    }
  }

  &__item {
    margin: 0 auto;
    width: 42vw;
    background-color: #ffffff;
    border-radius: 25px;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &__box {
      padding: 10px 88px 0px 45px;

      @media all and (max-width: 1100px) {
        padding: 10px 22px 0px 22px;
      }

      &__title {
        color: #000000;
        font-weight: 600;
        font-size: 24px;
        display: flex;
        font-family: 'MontSerrat';

        @media all and (max-width: 1100px) {
          font-size: 15px;
        }
      }
    }
  }

  &__aux {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;


    @media all and (max-width: 1100px) {
      flex-direction: column;
      align-items: initial;
    }

    &__requests {
      @media all and (max-width: 1100px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      &__aux {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        height: auto;
        border-radius: 10px;
        padding: 15px 0px;
        padding-right: 4vw;

        @media all and (max-width: 1100px) {
          width: 100%;
          padding: 8px 0;
        }
      }

      &__product {
        font-family: 'MontSerrat';
        line-height: 15px;
        font-size: 18px;
        color: #0c1931;
        font-weight: 400;

        @media all and (max-width: 1100px) {
          line-height: 15px;
          font-size: 13px;
        }
      }

      &__title {
        font-size: 16px;
        color: #7d7d7d;
        font-weight: 600;
        line-height: 20px;
        font-family: 'Montserrat';

        @media all and (max-width: 1100px) {
          line-height: 16px;
          font-size: 13px;
          margin-bottom: 0px;
        }
      }

      &__text {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        color: #0c1931;
        line-height: 22px;
        font-family: 'Montserrat';
        width: 10vw;

        @media all and (max-width: 1100px) {
          line-height: 16px;
          font-size: 1.5vh;
          width: 20vh;
        }

        &__active{
          color: #61BE91;
          font-weight: 700;
          line-height: 22px;
          font-family: 'Montserrat';
          font-size: 18px;
        }

        &__disabled{
          color: #000000;
          font-weight: 700;
          line-height: 22px;
          font-family: 'Montserrat';
          font-size: 18px;
        }
      }
    }
  }

  &__button {
    width: 15vw;
    height: 81px;
    background-color: #61BE91;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    border: none;
    border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-family: 'Montserrat';

    @media all and (max-width: 1100px) {
      width: 73vw;
      border-radius: 15px;
      height: 58px;
      font-size: 15px;
    }

    &:hover {
      color: #fff;
    }
  }
}

hr {
  border: 1px solid #ACACAC;
  margin: 32px 0px 15px 0px;

  @media all and (max-width: 1100px) {
    border: 1px dashed #ACACAC;
    margin: 13px 0px 15px 0px;
  }
}