.home {

  &__section {
      padding: 0;

    .col-lg-4 {
      padding: 0;
    }

    &__banner {
      width: 100vw;
      background: rgb(250,244,237);
      background: linear-gradient(180deg, #FAF4ED 0%, rgba(250, 244, 237, 0) 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        margin-top: 120px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        color: #000000;
        margin-bottom: 12px;
        text-align: center;

        @media all and (max-width: 1100px) {
          margin-top: 44px;
          font-size: 20px;
          margin-bottom: 0px;
        }
      }

      &__subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        color: #000000;
        width: 40%;
        text-align: center;
        margin-bottom: 50px;

        @media all and (max-width: 1100px) {
          font-size: 14px;
          width: 67%;
        }
      }

      &__button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        color: #FFFFFF;
        background-color: #000000;
        border-radius: 50px;
        padding: 18px 110px;

        @media all and (max-width: 1100px) {
          font-size: 15px;
          padding: 15px 100px;
        }

        &:hover {
          color: #FFFFFF;
          background-color: #1D6EA3;
        }
      }

      &__subtext {
        margin-top: 13px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        display: flex;
        color: #000000;
        margin-bottom: 130px;

        @media all and (max-width: 1100px) {
          font-size: 15px;
          margin-bottom: 80px;
        }
      }
    }

    &__product-display {

      @media all and (max-width: 1100px) {
        padding-top: 30px;
        display: flex;
        flex-direction: row;
      }

      &__row {
        @media all and (max-width: 1100px) {
          flex: 1 1 0 !important;
          width: 50%;

          &:first-child {
            padding-right: 7px;
          }

          &:last-child {
            padding-left: 7px;
          }
        }
      }

      &__col {
        @media all and (max-width: 1100px) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .col-lg-9 {
    flex: 0 0 80%;
    max-width: 78%;
  }

  .col-lg-3 {
    flex: 0 0 22%;
    max-width: 25%;
  }

  @media screen and (max-width: 995px) {

    .col-lg-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-lg-3 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

}
