.ghost-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  line-height: 17px !important;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  border: 1px solid #34526D;
  border-radius: 10px;
  color: #34526D;
  box-shadow: none;
  opacity: 1;
  padding: 17px 30px;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  display: inline-block;

  &:hover {
    color: black;
    box-shadow: $hover-shadow
  }
}
