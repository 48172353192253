.cart-content {
  background-color: #F2F2F2;

  &__boxed {
    @media all and (min-width: 1100px) {
      margin: auto;
    }
  }

  &__box {

    @media all and (min-width: 1100px) {
      max-width: 1140px !important;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @media all and (min-width: 1440px) {
      max-width: 1300px !important;
    }

    @media all and (min-width: 1680px) {
      max-width: 1440px !important;
    }

    &__content {
      margin-top: 0.5rem;

      @media all and (min-width: 1100px) {
        padding-right: 100px;
      }
    }

    &__cart {
      margin-top: 3rem;
    }
  }
}

.checkout__address {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, 100px);

  @media all and (max-width: 1100px) {
    transform: translate(0, 0);
    padding: 0 40px;
    padding-bottom: 20px;
  }

  &__icon {
    padding-right: 5px;

    @media all and (max-width: 1100px) {
      width: 35px;
      height: 35px;
      padding-right: 0px;
      margin-right: 20px;
    }
  }

  &__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #626262;
    font-size: 14px;

    @media all and (max-width: 1100px) {
      font-size: 10px;
    }
  }
}
