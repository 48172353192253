.checkout {
  &__hidden {
    display: none;
  }

  &__upper {
    &--three {
      @media all and (min-width: 1100px) {
        padding-top: 15px;
      }
    }

    &__coupon {
      &__title {
        cursor: pointer;
      }

      &__icon {
        margin-left: 10px;
      }
    }

    &__title {
    }
  }

  &__inner {
    background-color: white;
    border-radius: 25px;
    padding: 15px 30px 30px;
    z-index: 1;
    margin-top: 8px;

    @media all and (max-width: 1100px) {
      z-index: 1;
      position: sticky;
    }
  }

  &__content {
    &__item {
    }
    &__form {
      &__safe-icon {
        padding-bottom: 30px;
      }

      &__inner-title {
        font-size: 18px;
      }

      &__card-label {
        display: flex;
      }

      &__card-info {
        display: flex;

        @media all and (max-width: 1100px) {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        &--mobile-items {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }

    &__recurrent-info {
      margin-top: 15px;
      color: #28a745;

      @media all and (min-width: 1100px) {
        margin-top: 5px;
      }
    }

    &__info {
      padding: 0 15px;
      text-align: center;

      @media all and (min-width: 1100px) {
        padding: 45px 0 0 0;
      }

      &__logo {
        width: 61px;
        height: 61px;
        max-width: 33%;

        @media all and (max-width: 1100px) {
          width: 40px;
          height: 40px;
        }
      }

      &__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #000000;
        font-family: 'PrimeformProMedium';
        margin-top: 20px;

        @media all and (max-width: 1100px) {
          font-size: 16px;
        }
      }

      &__qr-code {
        &__code-text {
          width: 100%;
          margin: auto;
          line-break: anywhere;
          font-family: 'PrimeformPro';
        }

        &__title {
          @media all and (max-width: 1100px) {
            font-size: 15px;
            margin-top: 15px;
          }
        }

        &__copy {
          margin: 30px;
          font-family: 'PrimeformPro';
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          background-color: #0f6ca8;
          border-radius: 50px;
          color: #ffffff;
          padding: 19px;
          cursor: pointer;
          box-shadow: 3px 3px 20px #1d6eab;

          @media all and (max-width: 1100px) {
            margin: 30px 30px 0 30px;
          }
        }
      }
    }
  }
}

.checkout-security-buy {
  background: #0c1931;
  color: white;
  margin-top: -59px;
  text-align: center;
  height: 90px;
  padding-top: 65px;
  border-radius: 0px 0px 25px 25px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: 'PrimeformProSemiBold';

  &__icon {
    padding-right: 5px;
  }

  @media all and (max-width: 1100px) {
    font-size: 10px;
  }
}

.checkout--step-four__buy-button {
  text-align: center;
  background-color: #0f6cab;
  border-radius: 50px;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 3px 3px 20px #1d6ea3;
  margin-top: 50px;
  font-family: 'PrimeformPro';

  @media all and (max-width: 1100px) {
    margin: 0 10px 50px 10px;
  }

  &__item {
    display: flex;
    justify-content: center;

    &__text {
      padding: 25px 0;
      font-size: 20px;
      text-align: center;
      line-height: 27px;
      font-weight: 700;
      font-family: 'PrimeformPro';

      @media all and (max-width: 1100px) {
        padding: 21px 0;
        font-size: 15px;
      }
    }
  }
}

.checkout--step-four__buy-button-disabled {
  text-align: center;
  background-color: #b6afafc2;
  border-radius: 50px;
  color: #000000;
  cursor: pointer;
  box-shadow: 3px 3px 20px #b6afafc2;
  margin-top: 50px;
  font-family: 'PrimeformPro';

  @media all and (max-width: 1100px) {
    margin: 0 10px 50px 10px;
  }

  &__item {
    display: flex;
    justify-content: center;

    &__text {
      padding: 25px 0;
      font-size: 20px;
      text-align: center;
      line-height: 27px;
      font-weight: 700;
      font-family: 'PrimeformPro';

      @media all and (max-width: 1100px) {
        padding: 21px 0;
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .checkout__content__form__card-label {
    display: flex;
    flex-direction: column-reverse;
  }

  .checkout__content__card {
    margin-bottom: 20px;
  }
  .rccs__card {
    height: 156px;
    margin: 0 auto;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.rccs__card {
  height: 156px;
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.4s linear;
  width: 280px;
  margin-left: 10px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #000000 !important;
}

.MuiRadio-root {
  color: #000000 !important;
}

.PrivateSwitchBase-root-1 {
  padding: 0 0 25px 0 !important;

  @media screen and (max-width: 1100px) {
    padding: 0 0 10px 15px !important;
  }
}

.select__type
  .MuiFormGroup-root
  .advanced-radio__inner__group
  .MuiFormGroup-row {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  gap: 25px !important;
  font-family: 'PrimeformProSemiBold' !important;
}

.select__type__icon {
  margin: -13px 0 !important;

  @media screen and (max-width: 1100px) {
    margin: -10px 0 !important;
  }
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  transform: translate(14px, 28px) scale(1);
  font-family: 'PrimeformProRegular' !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.7) !important;
  font-family: 'PrimeformProRegular' !important;
}

.MuiTypography-body1 {
  font-family: 'PrimeformProSemiBold' !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  letter-spacing: -1px;
}
