.register-modal {
  display: flex;
  height: 92.6%;
  position: absolute;
  z-index: 2;
  width: 99.1%;
  background-color: #F2F2F2A6;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);

  @media all and (max-width: 1100px) {
    height: 100%;
    align-items: flex-start;
    padding-top: 9rem;
  }


  &__container {
    background: #fff;
    padding: 70px 80px;
    box-shadow: 1px 1px 18px #00000026;
    border-radius: 10px;

    @media all and (max-width: 1100px) {
      width: 90vw;
      height: 335px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__inner {

      &__logout {
        display: flex;
        justify-content: center;
        margin: 10px 0;
        
        &__link {
          font-family: 'Montserrat', sans-serif;
          font-size: 12px;
          font-weight: 700;
          color: #000;
          padding: 12px 20px;
          border-radius: 50px;

          @media all and (max-width: 1100px) {
            padding: 3px 7px;
            font-size: 1.4vh;
          }


          &--active {
            background-color: #025175;
            border-radius: 50px;
            color: #ffffff;
            font-weight: 700;
            padding: 12px 20px;

            @media all and (max-width: 1100px) {
              padding: 6px 14px;
            }
          }

          &:hover {
            background-color: #025175;
            border-radius: 50px;
            color: #ffffff;
            font-weight: 700;
            padding: 12px 20px;

            @media all and (max-width: 1100px) {
              padding: 6px 14px;
            }
          }
        }
        
      }

      &__content-title {

        &__title {
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 17px;
          color: #000;
          text-align: center;

          @media all and (max-width: 1100px) {
            font-size: 15px;
          }
        }
      }

      &__description {

        &__content {

          &__text {
            color: #000;
            font-weight: 400;
            text-align: center;
            font-size: 13px;
            width: 472px;
            margin-bottom: 0;
            font-family: 'Montserrat', sans-serif;

            @media all and (max-width: 1100px) {
              font-size: 0.65rem;
              width: 316px;
            }

            &__link {
              color: #038848;
              text-decoration: underline !important;

              &:hover {
                color: #038848;
              }
            }
          }
        }
      }

      &__container-input {
        margin-top: 2rem;
        display: flex;
        justify-content: center;

        &__content {
          display: flex;
          flex-direction: column;
          width: 60%;

          &__label {
            margin-bottom: -5px;
            color: #626262;
            font-size: 12px;
            font-family: 'Montserrat', sans-serif;
          }

          &__input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #8a8585;
            outline: none;
            background-color: transparent;
            margin-bottom: 2rem;
          }
        }
      }

      &__container-button {
        display: flex;
        justify-content: center;

        &__button {
          background-color: #025175;
          padding: 12px 109px;
          border-radius: 50px;
          border: none;
          color: #FFF;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 12px;
          box-shadow: 0px 5px 6px #0000004a;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;

          @media all and (max-width: 1100px) {
            padding: 12px 63px;
          }
        }

        &__recovery {
          display: flex;
          justify-content: center;
          margin-top: 20px;

          &__button {
            background-color: #025175;
            padding: 12px 109px;
            border-radius: 50px;
            border: none;
            color: #FFF;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 12px;
            box-shadow: 0px 5px 6px #0000004a;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            @media all and (max-width: 1100px) {
              padding: 12px 63px;
            }
          }
        }
      }

      &__container {
        display: flex;
        justify-content: center;

        &__content {
          display: flex;
          flex-direction: column;
          width: 55%;
        }
      }
    }
  }
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding: 26px 24px 0 24px;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  display: flex;
  justify-content: center;
}

.css-hlj6pa-MuiDialogActions-root {
  justify-content: center !important;
}

.css-hz1bth-MuiDialog-container {
  backdrop-filter: blur(0.5px);
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 26px;
}