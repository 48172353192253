.help-profile {
  padding-top: 89px;
  background-color: #F2F2F2;
  display: flex;
  padding-bottom: 342px;

  @media all and (max-width: 1100px) {
    padding: 31px 0;
    padding-bottom: 80px;
  }

  &__title {
    color: #000000;
    font-weight: 700;
    font-size: 28px;
    font-family: 'MontSerrat';
    text-transform: uppercase;
    margin-bottom: 21px;
    margin-left: 21.5vw;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      margin-bottom: 9px;
      margin-left: 0;
    }
  }

  &__item {
    padding-bottom: 50px;
    margin: 0 auto;
    width: 45vw;
    background-color: #ffffff;
    border-radius: 25px;

    @media all and (max-width: 1100px) {
      padding-bottom: 25px;
      width: 100%;
    }

    &__box {
      padding: 62px 88px 0px 45px;

      @media all and (max-width: 1100px) {
        padding: 29px 13px 0px 18px;
      }

      &__text {
        font-weight: 400;
        font-size: 24px;
        font-family: 'MontSerrat';
        color: #000000;
        align-items: center;
        line-height: 29px;

        @media all and (max-width: 1100px) {
          font-size: 13px;
          line-height: 16px;
        }
      }

      &__subtitle {
        font-weight: 400;
        font-size: 24px;
        font-family: 'MontSerrat';
        color: #000000;
        align-items: center;
        padding-top: 45px;

        @media all and (max-width: 1100px) {
          font-size: 13px;
          padding-top: 25px;
          line-height: 16px;
        }
      }

      &__button {
        width: 17vw;
        height: 81px;
        background-color: #61BE91;
        font-weight: 700;
        font-size: 20px;
        color: #fff;
        border: none;
        border-radius: 50px;
        margin-top: 43px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        font-family: 'Montserrat';

        @media all and (max-width: 1100px) {
          width: 83vw;
          border-radius: 15px;
          height: 58px;
          font-size: 15px;
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.help__address {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, 27px);

  @media all and (max-width: 1100px) {
    transform: translate(0, 59px);
    padding: 0 30px;
  }

  &__icon {
    padding-right: 5px;

    @media all and (max-width: 1100px) {
      width: 16px;
      height: 17px;
      padding-right: 0px;
      margin-right: 9px;
    }
  }

  &__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #626262;
    font-size: 16px;

    @media all and (max-width: 1100px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}