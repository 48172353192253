.panel-pagination {
  margin: 60px 0 0 0;

  &__menu {
    display: block;
    margin: 0;
    text-align: center;
    padding: 0 0 15px;

    &__item {
      display: inline-block;
      list-style: none;
      padding: 0 8px;

      &__button {
        color: $primary-color;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
        outline: 0 !important;

        &--disabled {
          cursor: default;
          opacity: 0.3;
        }

        &--active {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }
}
