.product-card {
  display: block;
  width: 288px;
  height: 298px;
  border-radius: 10px;
  overflow: hidden;
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  image-rendering: pixelated;

  @media all and (max-width: 1720px) {
    width: 268px;
    height: 278px;
  }

  @media all and (max-width: 1540px) {
    width: 240px;
    height: 243px;
  }

  @media all and (max-width: 1380px) {
    width: 220px;
    height: 222px;
  }

  @media all and (max-width: 1300px) {
    width: 200px;
    height: 202px;
  }

  @media all and (max-width: 1200px) {
    width: 180px;
    height: 182px;
  }

  @media all and (max-width: 1100px) {
    width: 150px;
    height: 188px;
    margin-left: 15px;
  }

  &:hover {
    .product-card__inner__top__icon {
      padding-right: 0;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__inner {

    &__top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 25px 15px 0;

      &--mobile {
        justify-content: center;
        padding-top: 15px;

        & .product-card__inner__top__title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
        }
      }

      &__title {
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 20px;
        text-transform: uppercase;
        color: black;

        &--center {
          color: white;
          text-align: center;
        }

        @media all and (max-width: 1100px) {
          font-size: 13px;
        }
      }

      &__icon {
        padding-right: 5px;
        transition: padding-right 100ms ease-in-out;

        @media all and (max-width: 1100px) {
          padding-left: 15px;
        }

        svg {
          path {
            fill: rgba(0,0,0,0.15);
          }
        }
      }
    }

    &__description {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 13px;
      padding: 0 15px;
      color: black;
    }
 
    &__button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      color: #000000;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      text-align: center;
      margin: 0 8px;
      padding: 4px 0;
      margin-top: 54px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__icon {
        width: 11px;
        padding-top: 4px;

        path {
          fill: #000000;
        }
      }
    }
  }

  &__mobile-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 800;

    &__button {
      font-size: 6px;
      outline: 0;
      background-color: white;
      border: none;
      border-radius: 7px;
      color: black;
      padding: 5px 10px;
      cursor: pointer;
      box-shadow: $default-shadow;

      &__icon {
        fill: #000000;
        width: 20px;
        height: 28px;
      }
    }
  }
}
