.page-content {

  &__box {

    @media all and (min-width: 1100px) {
      max-width: 1140px !important;
      margin: auto;
    }
  }
}
