.info-checkout-message {
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 7px 23px rgba(244, 180, 17, 0.2);
  border-radius: 25px;
  padding: 15px 40px 48px;
  height: 689px;
  margin-top: 20px;

  &__title {
    font-size: 30px;
    font-family: 'PrimeformPro';
    padding-bottom: 30px;

    @media all and (max-width: 950px) {
      font-size: 20px;
    }
  }

  &__text-one {
    font-size: 20px;
    font-family: 'PrimeformProRegular';
    line-height: 27px;

    @media all and (max-width: 950px) {
      font-size: 15px;
    }

    &--last{
      font-size: 20px;
      font-family: 'PrimeformProRegular';
      line-height: 27px;
  
      @media all and (max-width: 950px) {
        font-size: 15px;
        margin-bottom: 2rem;
      }
    }
  }

  &__text-two {
    font-size: 20px;
    font-family: 'PrimeformProHeavy';
    line-height: 27px;

    @media all and (max-width: 950px) {
      font-size: 15px;
    }
  }

  &__link {
    font-size: 20px;
    line-height: 27px;
    font-family: 'PrimeformProHeavy';
    color: #1BB7B7;
    text-decoration: underline !important;
    padding-right: 5px;

    @media all and (max-width: 950px) {
      font-size: 15px;
    }

    &:hover{
      color: #1BB7B7 !important;
      background-color: #87CEFA !important;
      padding: 5px 3px !important;
    }
  }

  &__image{
    margin-top: -50px;
    text-align: center;

    @media all and (max-width: 950px) {
      margin-top: 0;

    }

    &__icon-two{
      width: 60%;
    }
  }
}
