.advanced-button {

  &--disabled{
    background-color: #e0e0e0;
  }

  &__inner {
    display: flex;
    flex: 1;
    justify-content: space-between;

    &__start-icon,
    &__end-icon {
      width: 25px;
      height: 15px;
      margin-right: 10px;
    }

    &__text {
      align-self: center;
    }

    &__loading{
      align-self: center;
      padding: 5px;
    }
  }
}
