.checkout {
  position: relative;

  &__lower {
    font-size: 12px;
    padding-top: 15px;

    &--spaced {
      padding-top: 30px;
    }

    &__title {
      color: $primary-color;
      font-weight: bold;
    }
  }

  &__prescription-needed {
    font-size: 11px;
    margin-bottom: 15px;
    padding: 15px;
    text-align: center;
  }

  &__recurrent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #61be91;
    padding: 15px 30px;

    &__container {
      display: flex;

      &__input {
        padding: 5px 10px;

        input[type='radio'] {
          display: grid;
          place-content: center;
          appearance: none;
          background-color: #fff;
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 20px;
          height: 20px;
          border: none;
          border-radius: 50%;
          border: 1px solid #a3a3a3;
        }

        input[type='radio']::before {
          content: '';
          width: 9px;
          height: 9px;
          border-radius: 50%;
          color: #000;
          background-color: #000;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em #000;
        }

        input[type='radio']:checked::before {
          transform: scale(1);
        }
      }

      &__text {
        &__label {
          &__title {
            color: #ffffff;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 0.8rem;
            margin-bottom: 10px;

            &--space-top {
              margin-bottom: 0;
              margin-top: 5px;
            }
          }

          &__link {
            color: #ffffff;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 0.7rem;
            text-decoration-line: underline;
          }
        }
      }
    }
  }

  &__empty {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font-family: Montserrat;
    margin: 0 auto;
    width: 60vw;
    background-color: #fff;
    border-radius: 25px;
    margin-top: 100px;
    margin-bottom: 120px;

    @media all and (max-width: 1100px) {
      margin-top: 50px;
      padding-top: 25px;
      padding-bottom: 25px;
      width: 95vw;
    }

    &__title {
      font-size: 22px;
      color: #000;
      margin-bottom: 0;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: bold;

      @media all and (max-width: 1100px) {
        font-size: 18px;
      }
    }

    &__subtitle {
      color: #0c1931;
      margin-bottom: 50px;
      font-size: 18px;

      @media all and (max-width: 1100px) {
        font-size: 14px;
        margin-bottom: 25px;
      }
    }
  }

  &--step-one {
    display: block !important;

    @media all and (max-width: 1100px) {
      margin-top: 36px;
    }

    & > .checkout__upper {
      display: block !important;

      .checkout__upper__freight {
        padding-top: 80px;
        padding-bottom: 20px;

        &__title {
          font-family: 'Montserrat', sans-serif;
          font-size: 17px;
          font-weight: bold;
          padding-bottom: 5px;
        }

        &__container {
          background-color: white;
          border-radius: 25px;
          padding: 30px;
          margin-top: 15px;

          &__green {
            color: #61be91;
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 3px;
          }

          &__text {
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
          }
        }
      }

      .checkout__upper__title {
        display: block !important;

        &__text--padded {
          padding-top: 30px
        }
      }
      .checkout__upper__title__form {
        border-radius: 25px;
        padding-bottom: 40px;

        @media all and (max-width: 1100px) {
          padding: 20px;
        }

        &__item {
          margin-top: 3rem;
        }

        &__inner {
          display: flex;
          justify-content: space-between;
          gap: 15px;

          @media all and (max-width: 1100px) {
            display: flex;
            flex-direction: column;
          }
        }

        &__input {
          &--one {
            width: 50%;

            @media all and (max-width: 1100px) {
              width: 100%;
              margin-bottom: 15px;
              margin-top: -20px;
            }
          }

          &--two {
            width: 50%;

            @media all and (max-width: 1100px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.checkout__coupon-form {
  margin-top: 30px;

  &__title {
    font-family: 'PrimeformPro', sans-serif;
    letter-spacing: 0.05em;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 15px;

    @media all and (max-width: 1100px) {
      font-size: 13px;
    }
  }

  &__container {
    background-color: white;
    border-radius: 25px;
    padding: 30px;

    @media all and (max-width: 1100px) {
      padding: 15px;
    }

    &__area {
      display: flex;
      justify-content: space-between;
      gap: 4px;

      @media all and (max-width: 1100px) {
        height: 64px;
      }

      &__input {
        border: 1px solid #7d7d7d;
        border-radius: 15px;
        display: flex;
        flex: 1;

        input {
          outline: none;
          outline: none;
          border: none;
          background-color: transparent;
          padding: 32px;
          width: 100%;

          @media all and (max-width: 1100px) {
            padding: 20px 10px;
          }

          &::-webkit-input-placeholder,
          &:-ms-input-placeholder,
          &::placeholder {
            font-family: 'Montserrat', sans-serif;
            color: #626262;
          }

          &:focus,
          &:focus-visible {
            outline: none;
          }
        }

        &__button {
          font-family: 'PrimeformPro', sans-serif;
          background: #0f6cab;
          font-weight: 600;
          border-radius: 50px;
          color: #fff;
          padding: 0 35px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 13px;
          line-height: 18px;

          @media all and (max-width: 1100px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.checkout__content__form {
  background-color: white;
  border-radius: 25px;
  padding: 30px;
  padding-top: 40px !important;

  &__item__label {
    &__input {
      padding-top: 20px;

      &__button {
        margin-top: 20px;
      }
    }
  }

  .coupon-card {
    margin-top: 20px;
  }
}

.checkout--step-one__next-button {
  margin-top: 52px;
  text-align: center;
  background-color: #0f6cab;
  border-radius: 50px;
  color: #ffffff;
  cursor: pointer;
  box-shadow: 3px 3px 20px #1d6ea3;
  width: 100%;
  font-family: 'PrimeformPro';
  letter-spacing: 0.05em;

  @media all and (max-width: 1100px) {
    width: 100%;
  }

  &__item {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 29px;

    @media all and (max-width: 1100px) {
      padding: 15px;
    }

    &__text {
      font-size: 20px;
      text-align: center;
      line-height: 27px;
      font-weight: 700;
      margin-right: 15px;
      font-family: 'PrimeformPro';
      letter-spacing: 0.05em;

      @media all and (max-width: 1100px) {
        font-size: 15px;
      }
    }

    &__icon {
      font-size: 20px;

      @media all and (max-width: 1100px) {
        font-size: 15px;
      }
    }
  }
}

.checkout--step-one__next-button-disabled {
  margin-top: 65px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background-color: #b6afafc2;
  border-radius: 15px;
  color: #ffffff;
  padding: 19px;
  cursor: pointer;
}

.checkout__certificate-form {
  margin-top: 30px;

  &__title {
    font-size: 17px;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 30px;
    font-family: 'PrimeformPro', sans-serif;
    letter-spacing: 0.05em;

    @media all and (max-width: 1100px) {
      font-size: 13px;
    }
  }

  &__container {
    width: 100%;
    height: 125px;
    background: #ffffff;
    border-radius: 25px;
    display: flex;
    padding: 10px;

    @media all and (max-width: 1100px) {
      height: 85px;
    }
  }
}

.protection-certificate {
  width: 100%;
  height: auto;
}
