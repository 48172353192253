.clinic {

  &__header {
    width: 100%;
    height: 155px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    box-shadow: 0px 11px 8px rgba(0, 0, 0, 0.15);

    &__title {
      color: $title-clinic;
      font-size: 40px;
      text-align: center;
      font-weight: 800;
      margin: 0;

      @media all and (max-width: 1100px) {
        font-size: 23px;
      }
    }

    &__img {

      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  &__footer {
    background-color: $clinic-footer;
    height: 241px;
    width: 100%;
  }

  &__links {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    &__box {
      width: 100%;
      padding: 100px 0;
      border-bottom: 1px solid $border-box;
      margin: 0;
      display: flex;
      justify-content: center;
      position: relative;

      @media all and (max-width: 1100px) {
        padding: 15px 0;
      }

      &__title {
        color: $title-box;
        font-size: 35px;
        font-weight: 800;
        text-align: center;
        display: block;

        &__active {

          &__title-two {
            color: $title-done;
            font-size: 35px;
            font-weight: 800;
            text-align: center;
            display: flex;
            justify-content: center;
            margin: 0 20px 0 0;

            @media all and (max-width: 1100px) {
              font-size: 23px;
            }

            img {
              margin: 0 0 0 20px;

              @media all and (max-width: 1100px) {
                width: 20px;
              }
            }
          }
        }
      }

      &__active {
        background-image: url('../../../assets/jpg/img_medical_consultant.jpg');
        text-align: center;
        background-color: $banner-appointment;
        width: 100%;
        padding: 0;
        margin: 0;
        background-size: cover;
        background-position: top center;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -36px;
          margin: auto;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 36px 100px 0 100px;
          border-color: #F0F0F0 transparent transparent transparent;
          left: 50%;
          transform: translateX(-50%);
        }

        &__title {
          display: none;
        }

        @media all and (max-width: 1100px) {
          &__aux {
            padding: 90px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__description {
              margin: 0 0 0 100px;

              &__title {
                color: $name-clinic;
                font-size: 30px;
                font-weight: 700;
                margin: 0 0 15px 0;
              }

              &__subtitle {
                color: $name-clinic;
                font-size: 20px;
                font-weight: 500;
                margin: 0 0 20px 0;
              }

              &__list {
                color: $name-clinic;
                font-size: 20px;
                font-weight: 300;
                margin: 0 0 18px 0;
              }

              &__button {
                margin: 60px 0 0 0;
                background-color: $clinic-footer;
                width: 241px;
                height: 74px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                color: $white;
                border: none;
                font-weight: 700;
                font-size: 20px;
                cursor: pointer;
              }
            }
          }
        }

        &__aux {
          padding: 0px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          @media all and (max-width: 1100px) {
            align-items: center;
          }

          &__image {

            max-width: 550px;
            height: 100%;
          }

          &__description {
            margin: 50px 0;

            &__title {
              color: $name-clinic;
              font-size: 30px;
              font-weight: 700;
              margin: 0 0 15px 0;
            }

            &__subtitle {
              color: $name-clinic;
              font-size: 20px;
              font-weight: 500;
              margin: 0 0 20px 0;
            }

            &__list {
              color: $name-clinic;
              font-size: 20px;
              font-weight: 300;
              margin: 0 0 18px 0;
              text-align: start;

              &__bold {
                font-weight: bold;
                color: $black;
              }
            }

            &__button {
              margin: 60px 0 0 0;
              background-color: $clinic-footer;
              width: 241px;
              height: 74px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              color: $white;
              border: none;
              font-weight: 700;
              font-size: 20px;
              cursor: pointer;
            }

            @media all and (max-width: 1100px) {
              &__button {
                width: 100%;
              }
            }

          }
        }
      }
    }
  }

  @media all and (max-width: 1100px) {
    text-align: center;
    margin: 0;
  }

  &__questions {
    width: 100%;
    padding: 100px 0;
    border-bottom: 1px solid $border-box;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: $white;

    @media all and (max-width: 1100px) {
      padding: 15px 0;
    }

    &__active {
      background-color: $white;
      width: 100%;
      padding: 0;
      border-bottom: 1px solid $border-box;
      margin: 0;

      &__title {
        color: $title-done;
        font-size: 35px;
        font-weight: 800;
        text-align: center;
        display: block;
        margin: 60px 0 20px 0;

        @media all and (max-width: 1100px) {
          font-size: 23px;
        }
      }

      &__box {
        width: 75%;
        margin: 30px auto;

        &__next {
          padding-top: 30px;
        }

        &__description {
          color: $text-input;
          font-size: 25px;
          font-weight: 400;
          line-height: 29px;

          @media all and (max-width: 1100px) {
            font-size: 16px;
            line-height: 26px;
          }
        }

        &__description-two {
          color: $text-italic;
          font-size: 25px;
          font-weight: 400;
          line-height: 29px;
          font-style: italic;
          margin: 25px 0;

          @media all and (max-width: 1100px) {
            font-size: 16px;
            line-height: 26px;
          }
        }

        &__form {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin: 100px 0 0 0;

          @media all and (max-width: 1100px) {
            flex-direction: column;
            align-items: center;
          }

          .ant-upload-list.ant-upload-list-picture-card {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .ant-upload-list-picture-card-container {
            display: inline-block;
            width: 283px;
            height: 311px;
            margin: 0 8px 8px 0;
            vertical-align: top;
          }

          .ant-upload-list-picture-card .ant-upload-list-item {
            height: 100%;
            margin: 0;
            padding: 20px;
            background-color: $background-image;
            border-radius: 5px;
          }

          span.ant-upload {
            width: 285px;
            height: 42px !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            border: 2px solid #0C1931;
            border-radius: 4px;
            background-color: #ffffff;
            margin: 20px auto;
            font-weight: 500;

            img {
              margin: -2px 18px 0 0;
            }
          }

          .ant-upload.ant-upload-select-picture-card {
            width: 285px;
            height: 104px;
            margin-right: 8px;
            margin-bottom: 8px;
            text-align: center;
            vertical-align: top;
            background-color: #fff;
            border: navajowhite;
            border-radius: 2px;
            cursor: pointer;
            -webkit-transition: border-color 0.3s;
            transition: border-color 0.3s;

            @media all and (max-width: 1100px) {
              height: auto;
            }
          }

          &__aux {
            width: 20%;

            @media all and (max-width: 1100px) {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            &__title {
              margin: 0 auto 15px;
              text-align: center;
              color: $black;
              font-size: 17px;
              font-weight: 400;

              &__item {
                padding-top: 60px;
              }
            }

            @media screen and (max-width: 1100px) {

              &__title {
                font-size: 14px;
                margin-bottom: 0;
              }
            }
          }
        }

        &__button {
          display: flex;
          justify-content: space-around;
          align-items: center;
          background-color: $title-done;
          color: $white;
          width: 373px;
          height: 88px;
          border-radius: 12px;
          font-size: 25px;
          font-weight: 700;
          border: none;
          margin: 50px auto;
          cursor: pointer;
        }

        .aux {
          width: 65%;
        }
      }
    }

    &__title {
      color: $title-box;
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      display: block;
      margin: 60px 0 0 0;
    }

    &__title-two {
      color: $title-done;
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 0;

      @media all and (max-width: 1100px) {
        font-size: 23px;
      }

      img {
        margin: 0 0 0 20px;

        @media all and (max-width: 1100px) {
          width: 20px;
        }
      }
    }

    &__aux {
      padding: 0;
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .ant-steps-item-icon {
      display: none;
    }

    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background-color: $step-clinic;
      display: block;
      height: 9px;
      border-radius: 5px;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background-color: $title-done;
      display: block;
      height: 9px;
      border-radius: 0;
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background-color: $title-done;
      display: block;
      height: 9px;
      border-radius: 0;
    }

    .ant-steps-item-title {
      position: relative;
      display: inline-block;
      padding-right: 0 5px !important;
      color: $white !important;
      font-weight: 700;
      font-size: 0;
    }

    .ant-steps-item-title {
      padding-right: 0 !important;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      padding-left: 0 !important;
    }

    .steps-action {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin: 50px 0 100px 0;
      width: 51%;

      &__next {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: $title-done;
        color: $white;
        width: 180px;
        height: 59px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 700;
        border: none;
        margin: 0;
      }

      &__previous {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: $title-done;
        color: $white;
        width: 180px;
        height: 59px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 700;
        border: none;
        margin: 0 40px 0 0 !important;
      }
    }

    &__box {
      width: 100%;
      margin: 40px auto;

      &__title {
        color: $text-input;
        font-size: 31px;
        font-weight: 400;
        margin: 70px 0 10px 0;
        text-align: center;

        @media all and (max-width: 1100px) {
          font-size: 23px;
        }
      }

      &__input {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-wrap: wrap;

        input[type="radio"] + label {
          width: 276px;
          height: 190px;
          border-radius: 10px;
          border: 2px solid $light-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 400;
          background-color: transparent;
          margin: 0 30px 0 0;
          cursor: pointer;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;
        }

        input[type="radio"]:checked + label {
          font-weight: bold;
          width: 276px;
          height: 190px;
          border-radius: 10px;
          border: 4px solid $light-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          color: $text-input;
          font-size: 22px;
          font-weight: 700;
          background-color: $background-checkbox;
          margin: 0 30px 0 0;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;
        }

        input[type="radio"] {
          opacity: 0;
        }

        input[type="checkbox"] + label {
          font-weight: bold;
          width: 276px;
          height: 190px;
          border-radius: 10px;
          border: 2px solid $light-blue !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 400;
          background-color: transparent;
          margin: 0 30px 0 0;
          cursor: pointer;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;
        }

        input[type="checkbox"]:checked + label {
          font-weight: bold;
          width: 276px;
          height: 190px;
          border-radius: 10px;
          border: 4px solid $light-blue !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 700;
          background-color: $background-checkbox !important;
          margin: 0 30px 0 0;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;
        }

        @media all and (max-width: 1100px) {
          input[type="checkbox"] + label {
            margin: 0;
          }

          input[type="checkbox"]:checked + label {
            margin: 0;
          }

          input[type="radio"] + label {
            margin: 0;
          }

          input[type="radio"]:checked + label {
            margin: 0;
          }
        }

        input[type="checkbox"] {
          opacity: 0;
        }

      }

      textarea {
        width: 65%;
        margin: 40px auto 0 auto;
        height: 190px;
        box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;
        color: $text-input;
        font-style: italic;
        font-size: 21px;
        font-weight: 400;
        display: flex;
        text-align: center;
      }

      @media all and (max-width: 1100px) {
        textarea {
          width: 100%;
        }
      }
    }
  }

  &__payment {
    width: 100%;
    padding: 100px 0;
    border-bottom: 1px solid $border-box;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: $white;

    &__active {
      background-color: $white;
      width: 100%;
      padding: 0;
      border-bottom: 1px solid $border-box;
      margin: 0;

      &__title {
        color: $title-done;
        font-size: 35px;
        font-weight: 800;
        text-align: center;
        display: block;
        margin: 60px 0 20px 0;

      }

      &__box {
        width: 75%;
        margin: 90px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 1100px) {
          width: 100%;
          flex-direction: column-reverse;
        }

        &__aux {
          width: 45%;

          @media all and (max-width: 1100px) {
            width: 100%;
            padding: 0 15px;
          }

          &__title {
            font-size: 25px;
            font-weight: 700;
            color: $black;
            text-transform: uppercase;

            img {
              margin: 0 0 5px 20px;
            }
          }

          &__description {
            width: 90%;
            font-size: 12px;
            color: $black;
            margin: 10px 0;
          }

          &__form {
            width: 100%;
            
            &__spin {
              width:100%;
              position: relative;
              bottom: 2.3rem;
              left: 12rem;
            }

            &__input {
              display: flex;
              width: 100%;

              .advanced-input {
                padding-bottom: 0;
                width: 100%;
                margin: 15px 15px 0 0;
                border-radius: 10px;
              }
            }

            &__input-cep {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 80%;

              &__cep {
                color: $primary-color;
                font-size: 14px;
                font-weight: 600;
                text-decoration-line: underline;
                margin: 0 0 0 30px;
              }
            }

            &__buttons {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 87%;
              margin: 30px 0 0 0;
            }

            &__obs {
              width: 85%;
              margin: 10px 0;
              font-size: 12px;
              font-weight: 400;
              color: $black;
            }

            &__title-address {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 25px;
              color: $black;
              font-weight: 700;
              margin: 55px 0;
            }

            &__button {
              width: 100%;
              background-color: $button-payment;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              height: 83px;
              color: $white;
              margin: 50px 0;
              font-size: 19px;
              font-weight: 700;
              cursor: pointer;
            }
          }

          &__cart {
            border: 2px solid $dark-gray;
            padding: 35px;
            width: 90%;
            margin: 30px 0;
            border-radius: 15px;

            @media all and (max-width: 1100px) {
              width: 100%;
            }

            &__title {
              color: $text-product;
              font-size: 22px;
              font-weight: 700;
              margin: 0 0 20px 0;
            }

            &__aux {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0 0 30px 0;
              border-bottom: 1px solid $dark-gray;
              padding: 0 0 30px;

              &__item {
                color: $product-cart;
                font-size: 18px;
                font-weight: 300;
                margin: 0;
              }

              &__value {
                color: $text-product;
                font-size: 18px;
                font-weight: 700;
                margin: 0;
              }

              &__total {
                color: $product-cart;
                font-size: 18px;
                font-weight: 500;
                margin: 0;
              }

              &__value-total {
                color: $product-cart;
                font-size: 45px;
                font-weight: 700;
                margin: 0;
              }
            }
          }
        }
      }
    }

    &__title {
      color: $title-box;
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      display: block;
      margin: 60px 0 0 0;
    }

    &__title-two {
      color: $title-done;
      font-size: 35px;
      font-weight: 800;
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 0;

      @media all and (max-width: 1100px) {
        font-size: 23px;
      }

      img {
        margin: 0 0 0 20px;

        @media all and (max-width: 1100px) {
          width: 20px;
        }
      }
    }
  }

  .capture-cam {
    margin: 50px 0 20px 0;
    background-color: $primary-color;
    color: $white;
    font-weight: 500;
    padding: 0 30px;
    width: 285px;
    height: 42px;
    border-radius: 4px;
  }

  .capture-cam-two {
    margin: 0;
    background-color: $primary-color;
    color: $white;
    font-weight: 500;
    padding: 0 30px;
    width: 285px;
    height: 42px;
    border-radius: 4px;
  }

  .ant-steps {
    width: 70% !important;

    @media all and (max-width: 1100px) {
      width: 100% !important;
      flex-direction: row !important;
    }
  }

  .steps-content {
    width: 100%;
  }

  .disable {
    margin: 50px 0;
    background-color: $button-next;
    width: 373px;
    height: 88px;
    border-radius: 10px;
    color: $white;
    border-radius: 12px;
    font-size: 25px;
    font-weight: 700;
    border: none;
    margin: 50px auto;
    cursor: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
    display: none;
  }

}

.ant-modal-title {
  display: none !important;
}

.ant-modal-body {

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}


.ant-upload-list .ant-upload-list-picture-card {

}

.ant-modal {

  @media all and (max-width: 1100px) {
    top: 0 !important;
  }
}

.ant-upload-list-item-image {

  @media all and (max-width: 1100px) {
    image-orientation: from-image !important
  }
}
