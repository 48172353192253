.subscription-warning-modal {
  padding: 45px 30px;

  &__top {

    &__link {
      padding-bottom: 15px;

      &__button {
        font-size: 14px;
        background-color: transparent;
        color: #868686;
        border: none;
        outline: 0;
        padding: 0 !important;
        cursor: pointer;

        .anticon {
          font-size: 11px;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  &__middle {

    &__title {
      font-family: Roboto, sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 33px;
      color: #3B5C7A;
    }

    &__content {
      font-family: Roboto, sans-serif;
      font-size: 17px;
      line-height: 23px;
      font-weight: 300;
      padding: 15px 0;
    }
  }

  &__bottom {

    &__button {
      padding-top: 30px;
    }
  }
}

.ant-modal-content {
  border-radius: 5px;
}

.ant-modal-close {
  display: none !important;
}
