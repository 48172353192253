.product-card-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media all and (max-width: 1100px) {
    flex-wrap: wrap;
    padding: 0 7.5px 30px;
  }

  &__item {
    flex: 1 1 0;
    margin-left: 15px;

    .product-card__wrapper {

      .product-card {

        @media all and (max-width: 1530px) {
          image-rendering: auto;
        }
      }
    }

    @media all and (max-width: 1100px) {
      margin: 0 7.5px 15px;
      flex-basis: calc(50% - 15px);
    }

    &--first {
      background-color: #6DA7CB;
      border-radius: 10px;

      .product-card-section__item__title__icon path {
        fill: white;
        @media all and (max-width: 1100px) {
          width: 15px;
          height: 15px;
        }
      }
    }

    &__title {
      font-family: 'Montserrat', sans-serif;
      color: white;
      font-size: 25px;
      line-height: 35px;
      font-weight: 700;
      padding: 20px;
      padding-bottom: 10px;

      @media all and (max-width: 1530px) {
        padding: 15px;
        font-size: 18px;
      }

      @media all and (max-width: 1250px) {
        line-height: 17px;
      }

      @media all and (max-width: 1100px) {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 11px;
      }
    }

    &__subtitle {
      padding-left: 20px;
      font-family: 'Montserrat', sans-serif;
      color: white;
      width: 80%;
      font-size: 17px;
      font-weight: 300;
      line-height: 25px;

      @media all and (max-width: 1530px) {
        width: 90%;
        padding-left: 15px;
      }

      
      @media all and (max-width: 1250px) {
        line-height: 15px;
        font-size: 14px;
      }

      @media all and (max-width: 1100px) {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.02em;
      }

    }

    &__link {
      margin-top: 21%;
      text-align: center;
      font-family: Montserrat;

      @media all and (max-width: 1530px) {
        margin-top: 0;
      }

      @media all and (max-width: 1250px) {
        margin-top: 9vh;
      }

      @media all and (max-width: 1100px) {
        padding-bottom: 20px;
        padding-right: 20px;
        text-align: right;
      }

      &__container {
        padding: 12px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px #00000040;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #000000;

        @media all and (max-width: 1530px) {
          padding: 9px 11px;
          font-size: 11px;
        }

        @media all and (max-width: 1250px) {
          padding: 8px 9px;
          font-size: 10px;
        }
      
        @media all and (max-width: 1100px) {
          font-size: 16px;
          padding: 10px 12px;
        }
      }
    }
  }

  &__mobile {
    .slick-list {
      padding-left: 0 !important;
    }
  }
}
