.payment {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $white;
  padding: 0;

  &__box {
    margin: 80px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 1100px) {
      width: 100%;
      flex-direction: column-reverse;
    }

    &__aux {
      max-width: 700px;
      min-width: 650px;

      @media all and (max-width: 1100px) {
        max-width: 400px;
        min-width: 350px;
      }

      &__title {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        color: $black;
        margin-bottom: 15px;
      }

      &__description {
        width: 90%;
        font-size: 12px;
        color: $black;
        margin: 10px 0;
      }

      &__form {
        width: 100%;

        .white-button {
          text-transform: none;
        }

        &__input {
          display: flex;
          width: 100%;

          .advanced-input {
            padding-bottom: 0;
            width: 100%;
            margin: 15px 15px 0 0;
            border-radius: 10px;
          }
        }

        &__spin {
          width:100%;
          position: relative;
          bottom: 2.3rem;
          left: 12rem;
        }

        &__input-cep {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 80%;

          &__cep {
            color: $primary-color;
            font-size: 14px;
            font-weight: 600;
            text-decoration-line: underline;
            margin: 0 0 0 30px;
          }
        }

        &__buttons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 87%;
          margin: 30px 0 0 0;
        }

        &__obs {
          width: 85%;
          margin: 10px 0;
          font-size: 12px;
          font-weight: 400;
          color: $black;
        }

        &__title-address {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 25px;
          color: $black;
          font-weight: 700;
          margin: 55px 0;
        }

        &__button {
          width: 100%;
          background-color: $button-payment;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          height: 83px;
          color: $white;
          margin: 50px 0;
          font-size: 19px;
          font-weight: 700;
          cursor: pointer;
        }
      }

      &__cart {
        border: 2px solid $dark-gray;
        width: 100%;
        margin: 45px 0;
        border-radius: 15px;
        margin-top: 10px;

        @media all and (max-width: 1100px) {
          width: 100%;
        }

        &__title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #626262;
          padding: 30px 30px;
          margin-bottom: 0;
          padding-bottom: 20px;
        }

        &__aux {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $dark-gray;
          padding: 0 30px;
          padding-bottom: 20px;

          &--no-border {
            border: none;
          }
          
          &__item {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #626262;
            margin-bottom: 0;
          }

          &__value {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            text-decoration-line: line-through;
            color: #626262;
            margin-bottom:0;

            &--no-text-decoration {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              color: #626262;
              margin-bottom:0;
            }
          }

          &__total {
            padding-top: 30px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #626262;
          }

          &__value-total {
            padding-top: 30px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            color: #626262;
          }
        }
      }
    }
  }
}

.container-button {
  display: flex;
  justify-content: center;
  align-items: center;

  &__finished-prescription {
    width: 100%;
    height: 2.5rem;
    font-size: 1.2em;
    border-radius: 0.5rem;
    background-color: #3B5C7A;
    color: #fff;
  }
  
  &__finished-prescription:hover {
    transition: 300ms linear;
    font-weight: 500;
  }
}