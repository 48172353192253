.blured-container>* {
  filter: blur(15px);
}

.product {
  padding: 0 !important;
  position: relative;

  &__floating-bar {
    position: fixed;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    background-color: white;
    -webkit-box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.45);
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.45);
    z-index: 900;
    padding: 3px 0;

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;

      &__infos {
        flex-grow: 1;

        &__name {
          font-weight: bold;
        }

        &__pricing {

          &__installments {
            font-size: 12px;
          }

          &__price {
            font-size: 12px;
          }
        }
      }

      &__action {

        &__button {
          font-size: 23px;
          text-align: center;
          padding: 5px 15px;
          border: none;
          background-color: #25505B;
          color: white;
          border-radius: 5px;
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__carousel {
      width: 100%;
      height: 80vh;

      @media all and (max-width: 1600px) {
        height: 70vh;
      }

      &__main {
        height: 100%;

        &__image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__clickable {
        display: flex;
        margin-left: 2rem;

        &__item {
          width: 3vw;
          height: 3vw;
          margin: 0 0.5rem;
          margin-top: -10vh;

          &__img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            cursor: pointer;

            &--selected {
              box-shadow: 0px 4px 4px 0px #00000040;
              border: solid 1px white;
            }
          }
        }
      }
    }

    @media all and (max-width: 1100px) {
      display: block;
    }

    &__left {
      width: 68%;

      &__warning {
        text-align: center;
        padding: 13px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;

        &__text {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
        }
      }

      &__advantages {
        display: flex;
        padding: 5vw;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #F7FAFC;

        h2 {
          margin-bottom: 40px;
        }

        &__items-box {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        &__item {
          margin: 0 2vw;
          text-align: center;

          &__img-container {
            margin: auto;
            margin-bottom: 1vw;

            &__img {
              height: 120px;
              image-rendering: -webkit-optimize-contrast;
            }
          }

          &__text {
            font-family: Montserrat;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;

            @media all and (max-width: 1600px) {
              font-size: 0.8rem;
            }
          }
        }
      }

      &__depoiments {
        padding: 10% 0;
        text-align: center;

        &__title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 2.5rem;
          line-height: 37px;
          margin-bottom: 1%;

          @media all and (max-width: 1600px) {
            font-size: 2.1rem;
          }
        }

        &__rating {
          display: flex;
          justify-content: center;

          &__item {
            width: 23%;
            margin: 2rem;

            &__stars {
              font-size: 2rem;
              color: #FFD704;
              margin-bottom: 0.5rem;

              @media all and (max-width: 1600px) {
                font-size: 1.6rem;
              }
            }

            &__rating {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 1.2rem;
              line-height: 24px;
              text-align: center;

              @media all and (max-width: 1600px) {
                font-size: 0.8rem;
              }
            }

            &__name {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 1.2rem;
              line-height: 22px;
              color: #1D6EA3;

              @media all and (max-width: 1600px) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      &__questions {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8%;
        padding: 0 13%;

        &__image-container {
          width: 40%;
          height: 40%;

          &__img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }
        }

        &__container {
          margin-top: 3%;
          border-top: solid 0.5px #AEAEAE;
          width: 50%;

          &__item {
            width: 100%;
            padding: 4% 2%;
            border-bottom: solid 0.5px #AEAEAE;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            display: flex;
            flex-wrap: wrap;

            &__text {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 1.2rem;
              line-height: 24px;

              @media all and (max-width: 1600px) {
                font-size: 0.8rem;
              }

              &--bold {
                font-weight: bold;
              }
            }

            &__description {
              width: 100%;
              padding-top: 3%;
              font-size: 1.1rem;
              font-family: Montserrat;
              font-style: normal;
              line-height: 20px;

              @media all and (max-width: 1600px) {
                font-size: 0.7rem;
              }
            }

            &__icon {
              span svg {
                font-size: 1.2rem;

                @media all and (max-width: 1600px) {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }

      &__how-works {
        margin: 13% 0;
        padding: 0 12%;
        justify-content: center;

        .how_works_header {
          text-align: center;
        }

        &__title {
          display: flex;
          justify-content: center;
          color: #000000;
          font-size: 2.5vh;
          line-height: 37px;
          font-family: 'Montserrat';
          font-weight: 700
        }

        &__image {
          width: 100%;
          height: 229px;
        }

        &__group {
          display: flex;
          margin-top: 38px;
        }

        &__item {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 30%;

          &:first-child {
            margin-right: 100px;
          }

          &:last-child {
            margin-left: 100px;
          }

          &__content {

            &__title {
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 27px;

              @media all and (max-width: 1600px) {
                font-size: 1rem;
              }
            }

            &__text {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;

              @media all and (max-width: 1600px) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      &__about {
        display: flex;
        margin: 13% 0;
        padding: 0 10%;
        justify-content: center;

        &__item {
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
          width: 30%;
          margin: 0 3%;

          &__image-container {
            width: 10vw;
            margin-bottom: 20%;

            &__img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          &__content {

            &__title {
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 1.4rem;
              line-height: 27px;

              @media all and (max-width: 1600px) {
                font-size: 1rem;
              }
            }

            &__text {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 1.2rem;
              line-height: 24px;

              @media all and (max-width: 1600px) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }

      &__who-uses {
        width: 100%;

        &__image {
          width: 100%;
        }
      }

      &__wyntk {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__image {
          width: 65%;
          margin: auto;
        }
      }

      @media all and (max-width: 1100px) {
        width: 100%;
      }

      &__breadcrumb {
        padding: 30px;
        text-align: center;

        @media all and (max-width: 1100px) {
          padding: 0;
        }
      }

      &__mobile-title {
        padding: 15px 15px 45px;
        text-align: center;

        &__text {
          font-family: 'Montserrat', sans-serif;
          font-size: 21pt;
          line-height: 30pt;
          font-weight: bold;
        }
      }

      &__details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media all and (max-width: 1100px) {
          display: block;
        }

        &__mobile {
          padding-bottom: 30px;

          &__container {
            min-height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          &__image {
            width: 40%;
            margin: auto;
            display: block;
          }
        }

        &__left {
          width: 40%;
          padding: 700px 15px 150px;
          position: relative;

          @media all and (max-width: 1100px) {
            width: 100%;
            padding: 0;
          }

          &__image-select {
            position: absolute;
            top: 80px;
            left: 15px;
            display: flex;
            flex-direction: column;

            &__single {
              background-color: white;
              border-radius: 50%;
              border: 3px solid transparent;
              width: 60px;
              height: 60px;
              padding: 0;
              margin-bottom: 15px;
              outline: 0 !important;
              opacity: 0.7;
              cursor: pointer;

              &--active {
                border-color: $primary-color;
                opacity: 1;

                &__melatonina {
                  border: 5px solid #856499;
                }
              }

              &__img {
                width: auto;
                height: 75%;
                display: block;
                margin: auto;
                border: 1px solid transparent;
              }
            }
          }

          &__infos {
            text-align: right;

            @media all and (max-width: 1100px) {
              padding: 0 15px;
            }
          }
        }

        &__center {
          width: 20%;

          &__details {
            padding: 30px 0 90px;
            text-align: center;
            position: -webkit-sticky;
            position: sticky;
            top: 50px;
            min-height: 630px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__image {
              display: block;
              margin: auto;
              max-width: 100%;
            }
          }
        }

        &__right {
          width: 40%;
          padding: 700px 15px 150px;

          @media all and (max-width: 1100px) {
            width: 100%;
            padding: 0;
          }

          &__infos {
            text-align: left;

            @media all and (max-width: 1100px) {
              padding-left: 0;
            }
          }
        }
      }

      &__description {}
    }

    &__right {
      width: 32%;
      box-shadow: -3px -12px 24px 1px rgba(0, 0, 0, 0.1);

      @media all and (max-width: 1100px) {
        width: 100%;
        text-align: center;
      }

      &__inner {
        padding: 10% 10%;
        padding-bottom: 0;
        top: 0;
        position: sticky;

        @media all and (max-width: 1600px) {
          padding: 5% 8%;
          padding-bottom: 0;
        }

        &__calc {

          &__form {
            height: 100%;

            &__just-men{
              margin-bottom: 4%;
              width: 55%;

              &--mobile{
                width: 85%;
                margin-bottom: 4%;
              }
            }
          }
        }


        &__button {
          padding-bottom: 30px;
        }

        &__title {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          @media all and (max-width: 1100px) {
            justify-content: center;
          }

          &__rating {
            color: #9E9E9E;
            font-family: Roboto;
            font-style: normal;
            font-size: 1.2rem;
            display: flex;
            align-items: flex-end;
            padding-bottom: 1.5%;

            @media all and (max-width: 1600px) {
              font-size: 0.8rem;
            }

            &__star {
              padding-left: 2px;
              color: #FFD704;
            }
          }

          &__text {
            font-family: 'Montserrat', sans-serif;
            font-size: 2.1rem;
            padding-right: 10px;
            display: flex;
            white-space: nowrap;

            @media all and (max-width: 1900px) {
              padding-right: 5px;
              font-size: 1.6rem;
            }

            @media all and (max-width: 1600px) {
              padding-right: 5px;
              font-size: 1.3rem;
            }


            &__bold {
              font-weight: bold;
              padding-right: 5px;
            }
          }

          &__amount {
            font-family: 'Roboto', sans-serif;
            border: 1px solid #606060;
            color: #606060;
            border-radius: 10px;
            height: 37px;
            display: flex;
            flex-direction: row;
            position: relative;
            top: 3px;

            &__single {
              padding: 7px 10px 0;
              display: block;
              position: relative;

              &:after {
                content: '|';
                position: absolute;
                top: 6px;
                right: -2px;
              }

              &:last-child:after {
                content: '';
              }

              &__value {}

              &__label {}
            }
          }
        }

        &__description {
          font-size: 12pt;
          white-space: pre-wrap;
          padding: 30px 0;

          &__bold {
            font-weight: bold;
          }
        }

        &__pricing {
          color: #3E3E3E;
          padding-top: 9%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media all and (max-width: 1600px) {
            padding-top: 7%;
          }

          &__left {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 1.8rem;
            line-height: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #1D6EA3;

            &__price {

              &__label {
                width: 40%;
                padding-left: 15px;
                padding-bottom: 3px;
                image-rendering: -webkit-optimize-contrast;
              }

              &--variant {
                color: #474747;
              }

              &__box {
                display: flex;
                flex-direction: column;

                i {
                  font-size: 16px;
                  margin-bottom: 5px;
                }
              }
            }

            &__old-price {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 1.3rem;
              color: #2B2B2B;
              margin-right: auto;
              text-decoration: line-through;

              &--not-underlined {
                text-decoration: none;
              }

              @media all and (max-width: 1600px) {
                line-height: 20px;
                font-size: 1.1rem;
              }
            }

            @media all and (max-width: 1600px) {
              font-size: 1.4rem;
            }
          }

          &__right {
            position: relative;
            width: 21%;
            height: 4.5vh;

            &--sold-off {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 1rem;
              color: red;
            }

            & .amount-picker {
              box-shadow: none;
              border: 1px solid #6B6B6B;
              width: 10vw;
              max-width: none;
              min-height: none;
              width: 100%;
              height: 100%;

              &__value {
                font-size: 1rem;
                font-weight: bold;

                @media all and (max-width: 1600px) {
                  font-size: 0.9rem;
                }
              }

              &__action {
                padding: 0 10%;

                &__button {
                  font-weight: bold;
                  font-size: 1.7rem;
                  margin: 0 1%;

                  @media all and (max-width: 1600px) {
                    font-size: 1.1rem;
                  }
                }
              }
            }
          }
        }

        &__info {
          font-size: 12pt;
        }

        &__calc {
          border-top: 1px solid #9E9E9E;
          padding-top: 30px;
          margin: 30px 0;

          &--mobile {
            border: none;
            margin: 0 7vw !important;
            margin-top: 6vh !important;
            padding: 0 !important;
          }

          &__who-can-use {
            width: 75%;
            image-rendering: -webkit-optimize-contrast;
            margin-top: 8%;
          }

          @media all and (max-width: 1600px) {
            padding-top: 5%;
            margin: 5% 0;
          }

          &__safe-buy {
            width: 70%;
            margin-top: 7%;

            &__img {
              width: 100%;
            }
          }

          &__product-text {
            margin-top: 2rem;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            color: #474747;

            @media all and (max-width: 1600px) {
              font-size: 0.8rem;
              margin-top: 4%;
            }
          }

          &__qtd {
            width: 100%;

            &__container {
              margin-left: 2px;
              display: flex;
              height: 35px;

              @media all and (max-width: 1600px) {
                height: 25px;
              }

              &__img {
                image-rendering: -webkit-optimize-contrast;
              }
            }

            &__info {
              display: flex;
              align-items: center;
              padding: 1% 5%;
              height: 100%;
              text-align: center;
              font-family: Roboto;
              font-style: normal;
              font-size: 1.1rem;
              color: #6B6B6B;
              border: 1px solid #6B6B6B;
              white-space: nowrap;
              padding-top: 2%;

              @media all and (max-width: 2000px) {
                font-size: 0.8rem;
              }

              @media all and (max-width: 1800px) {
                font-size: 0.8rem;
              }

              @media all and (max-width: 1600px) {
                font-size: 0.6rem;
              }

              &--border-right {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }

              &--border-middle {
                border-left: none;
                border-right: none;
              }

              &--border-left {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
            }
          }

          &__installments {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            color: #474747;
            text-align: center;
            margin-top: 2%;
            margin-bottom: 6%;

            @media all and (max-width: 1600px) {
              font-size: 1rem;
              margin-top: 0%;
              margin-bottom: 4%;
            }

            &__add-to-cart {
              margin-bottom: 10%;

              @media all and (max-width: 1600px) {
                margin-bottom: 7%;
              }


              &__popover {
                cursor: pointer;

                &__button {
                  text-align: center;
                  background: #025175;
                  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
                  border-radius: 50px;
                  color: #fff;
                  padding: 4%;
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 1.2rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  border: none;
                  width: 100%;

                  &--disabled {
                    text-align: center;
                    background: #025175;
                    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
                    border-radius: 50px;
                    color: #fff;
                    padding: 1.5%;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 0.8vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: auto;
                    border: none;
                    width: 100%;
                    opacity: 0.5;

                    & svg {
                      transform: translate(0, -3px);

                      @media all and (max-width: 2200px) {
                        width: 15px;
                        transform: translate(0, -3px);
                      }

                      @media all and (max-width: 2000px) {
                        width: 15px;
                        transform: translate(0, -3px);
                      }

                      @media all and (max-width: 1800px) {
                        width: 14px;
                        transform: translate(0, -3px);
                      }

                      @media all and (max-width: 1600px) {
                        width: 13px;
                      }

                      & path {
                        fill: #fff
                      }
                    }

                  }

                  &--spaced {
                    padding: 6px;
                  }

                  @media all and (max-width: 2200px) {
                    font-size: 1rem;

                  }

                  @media all and (max-width: 2000px) {
                    padding: 3%;
                    font-size: 0.8rem;
                  }


                  @media all and (max-width: 1600px) {
                    padding: 1.5%;
                    font-size: 0.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }


                  & svg {
                    transform: translate(0, -3px);

                    @media all and (max-width: 2200px) {
                      width: 15px;
                      transform: translate(0, -3px);
                    }

                    @media all and (max-width: 2000px) {
                      width: 15px;
                      transform: translate(0, -3px);
                    }

                    @media all and (max-width: 1800px) {
                      width: 14px;
                      transform: translate(0, -3px);
                    }

                    @media all and (max-width: 1600px) {
                      width: 13px;
                    }

                    & path {
                      fill: #fff
                    }
                  }
                }
              }
            }
          }

          &__form {
            margin-top: 1%;

            &__container {

              display: flex;
              flex-direction: column;

              &__text-shipping {
                font-family: Montserrat;
                font-size: 0.8rem;
                font-style: normal;
                line-height: 1.3rem;
                letter-spacing: 0em;

              }
            }

            &__input {
              width: 100%;
              height: 59px;
              border: 1px solid #ACACAC;
              border-radius: 50px;
              align-items: center;
              display: flex;
              padding: 1% 8%;

              &__label {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 1.2rem;
                color: #474747;
                white-space: nowrap;

                &--mobile {
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px !important;
                  line-height: 17px;
                  white-space: nowrap;
                }

                @media all and (max-width: 1600px) {
                  font-size: 0.6rem;
                }

                &--hidden {
                  display: none;
                }
              }

              &__element {
                width: 100%;
                border: none;
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 1rem;
                color: #474747;
                position: relative;
                z-index: 2;
                background-color: transparent;

                &--mobile {
                  width: 90%;
                  height: 100%;
                  outline: none;
                }

                &--hidden {
                  left: 0;
                }

                @media all and (max-width: 1600px) {
                  font-size: 0.8rem;
                }

                &:focus-visible,
                &:focus {
                  border: none !important;
                  box-shadow: none;
                  outline: none;
                }
              }
            }

            &__button {
              position: relative;
              margin-left: auto;
              border-radius: 50%;
              background-color: #025175;
              width: 45px;
              height: 45px;
              transform: translate(-16%, -117%);
              display: flex;
              align-items: center;
              justify-content: center;

              &--mobile {
                width: 45px;
                height: 45px;
              }

              &__inner {
                background-color: transparent;
                border: none;
                color: #fff;
                font-size: 1.1rem;
                cursor: pointer;

                &--mobile {
                  font-size: 1.1rem;
                }
              }
            }

            &__freight-success {
              font-family: 'Roboto', sans-serif;
              border-top: 1px solid $light-gray;

              &__city {
                font-weight: bold;
                font-size: 12pt;
              }
            }

            &__freight-error {
              border-top: 1px solid $light-gray;
              padding-top: 15px;
              margin-top: 15px;

              a {
                color: $primary-color;
                text-decoration: underline;
              }

              @media all and (max-width: 1100px) {
                text-align: left;
              }
            }

            &__freight-normal {
              padding-top: 15px;
              margin-top: 15px;

              a {
                color: $primary-color;
                text-decoration: underline;
              }

              @media all and (max-width: 1100px) {
                text-align: left;
              }
            }
          }
        }

        &__kit-button {
          padding-top: 15px;
          text-align: center;

          &__link {
            text-align: center;
            padding: 30px 0 45px;

            &__anchor {
              color: #3E3E3E;
              text-decoration: underline;

              &:hover {
                color: lighten(#3E3E3E, 10);
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  &__recomended {
    margin: 0 8%;
    margin-top: 2%;
    margin-right: 0;
    margin-bottom: 8%;


    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 37px;
      margin-bottom: 2%;
    }

    &__container {
      display: flex;
      justify-content: left;
      width: 110vw;
      -webkit-user-drag: none;
      cursor: pointer;

      &--grabbing {
        cursor: grabbing;
      }


      &__item {
        width: 380px;
        margin: 0 35px;

        &--first {
          margin-left: 0;
        }

        &__image-container {
          width: 100%;

          &__img {
            width: 100%;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            width: 380px;
            height: 270px;
            margin-bottom: 5px;
          }
        }

        &__content {

          &__price {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 0;
            margin-top: 17px;
          }

          &__stars {
            font-size: 1.6rem;
          }

          &__title {
            font-family: Montserrat;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;
            letter-spacing: 0em;
            margin-bottom: 9px;
          }

          &__text {
            font-family: Montserrat;
            font-size: 0.8rem;
            font-style: normal;
            line-height: 1.3rem;
            letter-spacing: 0em;
            margin-bottom: 20px;
          }

          &__button {
            width: 100%;
            border: 1px solid #000;
            border-radius: 50px;
            padding: 2.5% 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: #000 !important;

              .product__recomended__container__item__content__button__text {
                color: #FFF;
              }

              & span svg path {
                fill: #FFF;
              }
            }

            & span svg {


              path {
                fill: #000
              }
            }

            &__text {
              text-align: center;
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 0.6rem;
              line-height: 20px;
              margin-right: 2%;
            }
          }
        }
      }
    }
  }

  &__desc-image {

    &__img {
      width: 100%;
      height: auto;
    }
  }

  &__promo {
    padding-top: 100px;
  }

  &__faq {
    width: 80%;
    margin: 8% auto;

    .container .row .col-lg-6 {
      padding-right: 6%;
      padding-left: 6%;
    }

    &__title {
      text-align: center;
      font-family: Montserrat;
      font-size: 2.5rem;
      font-weight: bold;
      color: black;
      margin-bottom: 3%;

      @media all and (max-width: 1600px) {
        font-size: 2.1rem;
      }
    }

    &__collapse {
      width: 100% !important;
      background-color: white;

      &__panel {
        background-color: transparent !important;
        margin-bottom: 0 !important;
      }

      .ant-collapse-content-box {
        background-color: transparent;
        font-family: Montserrat !important;
        margin: 0 1rem;
        color: #474747 !important;
        font-size: 1.2rem !important;

        div img {
          margin-top: 20px;
          width: 100%;
        }

        @media all and (max-width: 1600px) {
          font-size: 0.8rem !important;
        }
      }

      .ant-collapse-header {
        display: flex;
        align-items: center;
        font-family: Montserrat !important;
        font-size: 1.4rem !important;
        padding-left: 15px !important;
        font-weight: bold !important;
        color: black !important;
        margin-right: 10px;

        @media all and (max-width: 1600px) {
          font-size: 1rem !important;
        }

        @media all and (max-width: 1100px) {
          padding-right: 35px !important;
        }
      }

      .ant-collapse-content-box {
        white-space: pre-wrap;
      }

      .ant-collapse-arrow {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: 1px solid #474747;
        top: 30% !important;
        right: 0 !important;
        left: initial !important;
        color: #474747 !important;

        @media all and (max-width: 1600px) {
          width: 20px;
          height: 20px;
        }

        &>svg {
          position: relative;
          top: 5px;

          @media all and (max-width: 1600px) {
            top: 3px;
          }
        }
      }
    }
  }

  &__phrase {
    padding-bottom: 90px;
    margin-top: -60px;

    &__img {
      max-width: 100%;
      width: 100%;
      image-rendering: -webkit-optimize-contrast;
    }
  }
}

.product-mobile {
  position: relative;

  &__carousel {

    &__main {

      &__prev {
        width: 40vw;
        height: 61vw;
        display: block;
        position: relative;
        margin-top: -61vw;
        display: flex;
        align-items: center;

        &__icon {
          margin-left: 6px;
        }
      }

      &__next {
        width: 40vw;
        height: 61vw;
        display: block;
        position: relative;
        margin-top: -61vw;
        margin-left: 58vw;
        display: flex;
        align-items: center;
        justify-content: end;

        &__icon {
          margin-left: auto;
        }

      }

      &__image {
        width: 100%;
        height: 100%
      }
    }

    &__indicator {
      background: rgba(71, 71, 71, 0.5);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 1vw 1.2vw;
      width: fit-content;
      position: relative;
      transform: translate(0px, -8vw);
      margin-bottom: -8vw;

      &__icon {
        width: 2vw;
        height: 2vw;
        margin: 1vw 0.8vw;
        display: block;
        background-color: #9E9E9E;
        border-radius: 50%;
        cursor: pointer;

        &--active {
          background-color: #FFF;
        }
      }
    }
  }

  &__buy {
    margin: 0 3vw;
    margin-top: 7vh;

    &__who-can-use {
      width: 60%;
      margin: auto;
      margin-bottom: 50px;
      display: flex;
      image-rendering: -webkit-optimize-contrast;
    }

    &__variants {

      &__item {
        position: relative;
        display: flex;
        border: 1px solid #ACACAC;
        border-radius: 15px;
        padding: 5px 8px;
        margin-bottom: 13px;

        &--selected {
          border: 2px solid #925884;
          background: rgba(146, 88, 132, 0.1);
        }

        &__radio {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #9E9E9E;
          margin-right: 7px;
          margin-top: 21px;
          display: flex;
          align-items: center;
          justify-content: center;

          &__selected {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: #925884;
          }
        }

        &__img {
          width: 64px;
          height: 61px;
        }

        &__info {
          margin: 0 13px;
          margin-top: 15px;
          width: 98px;

          &__name {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #474747;

            &--selected {
              font-weight: 700;
              color: #925884;
            }
          }

          &__price {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #474747;
          }
        }

        &__freight {
          width: 82px;
          display: flex;
          align-items: end;
          -webkit-justify-content: flex-end;
          align-items: flex-end;
          -webkit-align-items: flex-end;
          padding-bottom: 6px;

          img {
            width: 100%;
          }
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5vh;
      flex-wrap: wrap;

      &__text {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.5rem;
        padding-right: 5px;
        display: flex;

        &__bold {
          font-weight: bold;
          padding-right: 3px;
        }
      }

      &__rating {
        color: #9E9E9E;
        font-family: Roboto;
        font-style: normal;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &__star {
          padding-left: 2px;
          color: #FFD704;
        }
      }
    }

    &__pricing {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__left {
        font-family: 'Montserrat';
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 22px;
        padding-left: 4vw;
        color: #1D6EA3;

        .product-mobile__buy__pricing__right__old-price {
          font-size: 1.2rem;
          text-decoration: line-through;
          font-weight: 500;
          color: #2B2B2B;
        }
      }

      &__right {

        .amount-picker {
          border: 1px solid #6B6B6B;
          box-shadow: none;

          &__value {
            font-weight: 600;
          }
        }
      }
    }

    &__add-to-cart {
      margin-top: 3vh;
      margin-bottom: 4vh;

      &__button {
        background: #025175;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3vw 0;
        font-family: 'Montserrat';
        color: #FFF;
        font-weight: bold;
        font-size: 0.8rem;
        letter-spacing: 0.03rem;

        svg {
          width: 0.8rem;
          padding-bottom: 4px;

          path {
            fill: #FFF;
          }
        }
      }
    }

    &__qtd {
      margin-top: 7vh;
      display: flex;
      justify-content: center;

      &__container {
        height: 29px;

        img {
          height: 100%;
        }
      }
    }

    &__product-text {
      margin: auto;
      margin-top: 3vh;
      text-align: center;
      color: #474747;
      line-height: 19px;
      font-family: Montserrat;
      width: 70vw;
      font-weight: 400;

      p {
        margin-bottom: 0;
      }
    }

    &__anvisa {
      margin: auto;
      margin-top: 2vh;
      text-align: center;
      color: #474747;
      line-height: 19px;
      font-family: Montserrat;
      font-weight: 400;
      padding-bottom: 4vh;
      border-bottom: 1px solid #9E9E9E;
    }
  }

  &__advantages {
    padding: 5vh 0;
    margin-top: 9vh;
    display: flex;
    justify-content: center;
    background: #F7FAFC;

    h2 {
      margin-left: 20px;
      margin-bottom: 25px;
    }

    &__items-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3vw;
      width: 125px;

      &__img-container {
        height: 86px;
        margin-bottom: 14px;

        &__img {
          height: 100%;
          image-rendering: -webkit-optimize-contrast;
        }
      }

      &__text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
      }
    }
  }

  &__depoiments {
    margin-top: 10vh;
    position: relative;

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      margin-bottom: 5vh;
    }

    &__container {
      text-align: center;
      width: 269px;
      margin: 0 20px;
      position: relative;

      &__stars {
        color: #FFD704;
        font-size: 24px;
        display: block;
        margin-bottom: 1vh;
      }

      &__text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 2vh;
      }

      &__name {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #1D6EA3;
      }
    }
  }

  &__questions {
    margin: 0 10px;
    margin-top: 20vh;

    &__image-container {
      margin-bottom: 5vh;

      &__img {
        width: 100%;
        height: 100%;
        border-radius: 35px;
      }
    }

    &__container {

      &__item {
        border-top: 1px solid #AEAEAE;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          &--bold {
            font-weight: bold;
          }
        }

        &__description {
          padding: 2vw;
          margin-top: 2vh;
          width: 90%;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          div img {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }

    &--faq {
      margin-top: 13vh;
      margin-bottom: 13vh;

      .product-mobile__questions__title {
        margin-left: 15px;
        margin-bottom: 4vw;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
      }

      .product-mobile__questions__container {
        margin: 0 15px;

        &__item {
          border-top: none;
          border-bottom: 1px solid #AEAEAE;
          padding: 16px 0;
          cursor: pointer;

          &__text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;

            &--bold {
              font-weight: bold;
            }
          }

          &__description {
            margin-bottom: 0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  &__how-works {
    margin: 13% 0;
    padding: 0 12%;
    justify-content: center;

    .how_works_header {
      text-align: center;
    }

    &__title {
      display: flex;
      justify-content: center;
      color: #000000;
      font-size: 2.5vh;
      line-height: 37px;
      font-family: 'Montserrat';
      font-weight: 700;
      text-align: center;
    }

    &__image {
      width: 100%;
      height: 229px;
    }

    &__group {
      margin-top: 38px;
    }

    &__item {
      margin: 7vh 10vw;

      &__content {
        text-align: center;

        &__title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
        }

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  &__about {
    margin: 5vh 0;

    &__item {
      margin: 13vh 10vw;

      &__image-container {
        border-radius: 50%;
        width: 42vw;
        height: 42vw;
        margin: 0 auto;
        margin-bottom: 4vh;

        &__img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      &__content {
        text-align: center;

        &__title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 27px;
        }

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  &__who-uses {
    margin-top: 5vh;

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      margin-bottom: 5vh;
    }

    &__img-container {
      width: 150vw;

      &__image {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__phrase {
    width: 90%;
    margin: auto;
    margin-bottom: 13vh;

    &__img {
      width: 100%;
      height: 100%;
      image-rendering: pixelated;
    }
  }

  &__recomended {
    position: relative;

    &__title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 25px;
      margin-left: 8vw;
    }

    &__container {


      .slick-slide.slick-active.slick-center.slick-current {
        margin-right: -40px;
      }

      &__item {
        width: 320px;
        max-width: 320px;
        margin: 0 auto;
        display: block !important;

        &__image-container {
          width: 320px;
          height: 240px;

          &__img {
            width: 100%;
            height: 100%;
          }
        }

        &__content {
          width: 100%;
          margin-top: 18px;

          &__stars {
            font-size: 20px;
            color: #2B2B2B;
          }

          &__price {
            margin-top: 21px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }

          &__title {
            margin-top: 3px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
          }

          &__text {
            margin-top: 17px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
          }

          &__button {
            border: 1px solid #000000;
            border-radius: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__text {
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              padding-right: 10px;
            }

            &__icon {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &__floating-button {
    position: fixed;
    z-index: 100;
    bottom: 17px;
    width: 80vw;
    display: flex;

    align-items: center;

    @media all and (max-width: 600px) {
      justify-content: space-between;
    }

    &__add-to-cart {
      background: #025175;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #FFF;
      padding: 2vh 17vw;
      max-width: 400px;
      max-height: 80px;
      margin-right: 12px;
      white-space: nowrap;

      svg {
        width: 12px;
        transform: translate(0px, -2px);

        path {
          fill: #FFFFFF;
        }
      }
    }

    &__whatsapp {
      background-color: #FFF;
      border-radius: 50%;
      height: 8.5vh;
      width: 8.5vh;
      max-width: 80px;
      max-height: 80px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: #34AF23;
        }
      }
    }
  }

  &__modal {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    z-index: 101;
    position: fixed;
    padding: 0 30px;
    padding-top: 50px;
    display: flex;
    justify-content: center;


    &__close {

      &__button {
        width: 20px;
        height: 20px;
        margin-left: auto;
        margin-bottom: 150px;

        span {

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 25px;
    }

    &__confirm {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      width: 357px;
      height: 57px;
      background: #1D6EA3;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 11px auto;
    }

    &__deny {
      width: 357px;
      height: 56px;
      background: #FFFFFF;
      border: 1px solid #626262;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #626262;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}

.ant-message-notice.ant-custom-style {
  .ant-message-notice-content {
    font-weight: bold;
    font-size: 18px;

    .ant-message-custom-content.ant-message-success {
      padding: 5px 100px;

      span {
        font-size: 17px;
      }
    }
  }
}

.other-informations-box {
  margin-bottom: 50px;

  p {
    text-align: center;
    padding: 0 30px;
  }
}

.warnings-text-box {
  align-self: center;
  margin: 50px auto;
  width: 89%;

  h1 {
    font-family: 'Montserrat';
  }

  p {
    font-family: 'Montserrat';
  }
}