.help {
  background-color: $background-gray;
  padding-bottom: 80px;

  &__breadcrumb {
    padding-top: 30px;

    @media all and (max-width: 1100px) {
      margin-top: 30px;
    }
  }

  &__title {
    padding-top: 80px;
  }

  @media all and (max-width: 1100px) {
    margin-top: 30px;
  }

  &__block {
    background: white;
    padding: 30px 50px 50px;
    border-radius: 10px;

    @media all and (max-width: 1100px) {
      padding: 15px 30px 30px;
    }

    &__title {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: 300;
      color: $title-gray;
      padding-top: 30px;

      @media all and (max-width: 1100px) {
        font-size: 15px;
        padding-top: 0;
      }
    }

    &__link {
      font-size: 16px;
      font-weight: 300;
      color: $title-gray;
      margin: 0;
      text-decoration-line: underline;
    }
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    color: $title-gray;
    margin: 0 auto 30px auto;
    text-align: left;
  }

  .ant-collapse.ant-collapse-icon-position-left {
    width: 100%;
    border: none;
    background: transparent;
  }

  .ant-collapse-item {
    background-color: $white;
  }

  .ant-collapse-content-box {
    background-color: $white;
  }

  .ant-collapse > .ant-collapse-item {
    border-radius: 0 !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    padding: 5px 15px 25px;
    font-weight: 500;

    @media all and (max-width: 1100px) {
      font-size: 13px;
      padding: 0 0 20px;
      font-weight: bold;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    top: 12px;
    right: 15px;
    left: initial;
    font-size: 13pt;

    @media all and (max-width: 1100px) {
      top: 5px;
      right: 0;
      font-size: 10pt;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      padding: 0 !important;
    }
  }
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  padding-left: 15px;
}
