.product {
  padding: 60px 0 0 0;

  @media all and (max-width: 1100px) {
    padding-top: 30px;
  }

  &__begin {
    background-image: url('../../assets/jpg/banner_begin.jpg');
    width: 100%;
    height: 500px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    text-align: center;

    @media all and (max-width: 1100px) {
      background-image: none;
      height: auto;
      display: block;
      padding: 0 30px 30px;
    }

    &__title {
      font-family: 'Roboto', sans-serif;
      color: $title-begin;
      font-size: 24px;
      font-weight: 400;
      margin: 0 0 5px 0;
      line-height: 30px;

      @media all and (max-width: 1100px) {
        font-size: 19px;
        padding: 0 15px;
      }
    }

    &__button {
      padding-top: 45px;

      @media all and (max-width: 1100px) {
        padding-top: 15px;
      }
    }
  }
}
