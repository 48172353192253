.advanced-message {
    position: absolute;
    top: -20vh;
    width: 90vw;
    left: calc(50% - 46vw);
    z-index: 9999;
    background-color: #003248;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: #FFFFFF;
    padding: 1.5vw;
    display: flex;
    font-size: 0.9rem;
    font-weight: 700;
    transition: top 0.5s ease-in-out;

    &--active {
        top: 10vh;
    }
    
    @media all and (max-width: 1100px) {
        font-size: 1rem;
        border-radius: 10px;
        padding: 3vw;
        left: calc(50% - 46vw);
    } 

    &__icon {
        margin-right: 20px;
    }

    &__message {
        font-family: 'Montserrat';
        font-style: normal;
    }

    &__close {
        margin-left: auto;
        cursor: pointer;
    }
}