.product-info {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 45px;

  &--right {
    text-align: right;
  }

  @media all and (max-width: 1100px) {
    text-align: center !important;
    padding: 0 15px;
  }

  &__title {
    font-size: 22pt;
    font-weight: bold;
    color: #476072;
    margin-bottom: 5px;

    &__melatonina {
      font-size: 22pt;
      color: #382944;
      margin-bottom: 5px;
      white-space: pre-wrap;
    }

    @media all and (min-width: 1100px) and (max-width: 1530px) {
      white-space: initial;
    }

    @media all and (max-width: 1100px) {
      white-space: initial;
    }
  }

  &__description {
    font-family: 'Roboto', sans-serif;
    font-size: 12pt;
    line-height: 17pt;
    color: #2F4C65;
    white-space: pre-wrap;

    @media all and (min-width: 1100px) and (max-width: 1530px) {
      white-space: initial;
    }

    @media all and (max-width: 1100px) {
      white-space: initial;
    }
  }
}
