.home-navigation-stack {
   
}

.home-navigation-stack--visible {
    opacity: 0.2;
    @media screen and (max-width: 600px) {
        opacity: 1;
    }
}

.floating-button {
    position: fixed;
    z-index: 100;
    bottom: 2rem;
    width: 95%;
    height: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media all and (max-width: 1100px) {          
        bottom: 1rem;
        width: 15%;
        margin-left: 82vw;
    }

    &__fab {
        height: 4rem !important;
        width: 4rem !important;

        @media all and (max-width: 1100px) {
            height: 3.5rem !important;
            width: 3.5rem !important;
        }

        &__img {
            @media all and (max-width: 1100px) {
                height: 3rem !important;
                width: 3rem !important;
            }
        }
    }
}
