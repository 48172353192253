.edit-profile {

  &__return {
    text-align: center;
    padding-top: 23px;
    padding-bottom: 31px;
  }

  &__cancel {
    color: #000000;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Montserrat';
    border-bottom: 1.5px solid #6DA7CB;
    text-transform: uppercase;

    @media all and (max-width: 1100px) {
      padding-top: 0;
      font-size: 11px;
    }
  }

  &__aux {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__input {
      width: 49% !important;
      border: 1px solid #000000 !important;
      border-radius: 15px;
      margin: 10px 0;
      height: 7vh;

      @media all and (max-width: 1100px) {
        margin: 5px 0;
      }

      input {
        outline: none;
        outline: none;
        border: none;
        background-color: transparent;
        width: 70%;
        font-size: 18px;
        font-family: 'Montserrat';
        height: 6.5vh;
        margin-left: 2.3vh;
        font-weight: 400;

        @media all and (max-width: 1100px) {
          font-size: 3vw;
          border-radius: 2vh;
          position: absolute;
          height: 7.2vh;
        }

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::placeholder {
          font-family: 'Montserrat', sans-serif;
          color: #626262;
        }

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }

    &__input-large {
      width: 100% !important;
      margin: 10px 0;
      border: 1px solid #000000 !important;
      border-radius: 15px;
      height: 7vh;

      @media all and (max-width: 1100px) {
        margin: 5px 0;
      }

      input {
        outline: none;
        outline: none;
        border: none;
        background-color: transparent;
        width: 70%;
        font-size: 18px;
        font-family: 'Montserrat';
        height: 6.5vh;
        margin-left: 2.3vh;
        font-weight: 400;

        @media all and (max-width: 1100px) {
          font-size: 3vw;
          border-radius: 2vh;
          position: absolute;
          height: 7.2vh;
        }

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::placeholder {
          font-family: 'Montserrat', sans-serif;
          color: #626262;
        }

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }

    &__button {
      width: 100%;
      height: 68px;
      background-color: #1D6EA3;
      font-weight: 700;
      font-size: 1rem;
      color: #fff;
      border: none;
      border-radius: 3.5rem;
      margin-top: 2vh;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-family: 'Montserrat';

      @media all and (max-width: 1100px) {
        height: 50px;
        margin-top: 4vh;
        font-size: 13px;
      }

      &:hover {
        opacity: 0.9;
        transition: 0.3s;
      }
    }
  }
}