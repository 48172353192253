.amount-selection {
  position: relative;
  color: black;
  text-align: center;
  padding-top: 15px;

  &__amount {
    font-size: 20pt;
    text-align: center;
    pointer-events: none;

    &__prefix,
    &__suffix {
      font-size: 8pt;
      opacity: 0;
      min-width: 12px;
      display: inline-block;
    }

    &--visible {
      opacity: 1;
    }

    &__value {
      padding: 0 5px;
      width: 30px;

      &__input {
        background-color: transparent;
        border: none;
        text-align: center;
        display: inline-block;
        outline: 0 !important;
      }
    }
  }

  &__description {
    font-size: 10pt;
    padding: 10px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__button {
      margin: 0 5px;
      position: relative;

      &--left {
        left: 15px;
      }

      &--right {
        right: 15px;
      }
    }
  }

  &--disabled {
    color: #9e9e9e !important;
  }
}

