.hair-kit {
  padding: 0;

  &__banner {
    background-image: url('../../../assets/jpg/banner-hair-kit.jpg');
    width: 100%;
    height: 672px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 0;

    @media all and (max-width: 1100px) {
      background-image: none;
      height: auto;
      padding: 60px 0;
      background-color: #F7F7F7;
    }

    &__mobile-image {

      &__img {
        width: 100%;
      }
    }

    &__breadcrumb {
      padding-top: 15px;
    }

    &__links {

      &__link {
        font-size: 16px;
        color: $black;
        font-weight: 400;
      }
    }

    &__title {
      margin: 3vw 0 0 0;
      color: $dark-blue;
      font-size: 60px;

      @media all and (max-width: 1100px) {
        font-size: 45px;
        margin-bottom: 30px;
      }
    }

    &__description {
      font-family: 'Roboto', sans-serif;
      width: 25%;
      margin: 0 0 25px 0;
      color: $description-hair-kit;
      font-size: 18px;
      line-height: 21px;
      white-space: pre-wrap;

      @media all and (max-width: 1100px) {
        width: 100%;
        white-space: initial;
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
      }

      &__mobile-img {
        width: 100%;
        height: auto;
        margin-top: 60px;
      }
    }

    &__button {
      margin: 50px 0 0 0;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
      background: $button-hair-kit;
      width: 260px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 20px;
      font-weight: 700;
      border-radius: 10px;
      font-family: 'Roboto', sans-serif;
    }
  }

  &__percent {
    padding: 90px 0 30px;

    @media all and (max-width: 1100px) {
      padding: 60px 0 0;
    }

    &__title {
      font-family: 'Roboto', sans-serif;
      font-size: 28px;
      color: $more-product-hair-kit;
      font-weight: 500;
      margin: 0 auto;
      text-align: left;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        line-height: 28px;
        padding-bottom: 15px;
      }
    }

    &__description {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      color: $more-product-hair-kit;
      font-weight: 300;
      margin: 0 auto;
      text-align: left;

      @media all and (max-width: 1100px) {
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
      }
    }

    &__aux {
      display: flex;
      justify-content: flex-start;
      margin: 20px auto;
      align-items: center;

      @media all and (max-width: 1100px) {
        display: flex;
        flex-direction: row;
      }

      &__first {
        background-color: #ADC1CE;
        height: auto;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 15px 20px 0;
        padding: 15px 30px 5px;
        transition: opacity 400ms ease-in-out;

        @media all and (max-width: 1100px) {
          margin-right: 10px;
          padding: 15px 10px;
          text-align: center;
          height: 130px;
        }

        &__product {
          color: $white;
          font-size: 18px;
          font-weight: 300;
          margin: 0;

          @media all and (max-width: 1100px) {
            font-size: 15px;
            line-height: 20px;
            font-weight: 300;
            padding-bottom: 5px;
          }
        }

        &__discount {
          color: $white;
          font-size: 50px;
          line-height: 50px;
          font-weight: 700;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          margin: 0;
          padding-bottom: 10px;

          @media all and (max-width: 1100px) {
            font-size: 23px;
            line-height: 23px;
            text-align: center;
            width: 100%;
            padding-top: 10px;
            white-space: pre-wrap;
          }
        }
      }

      &__second {
        background-color: #84A4BB;
        height: auto;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 15px 20px 0;
        padding: 15px 30px 5px;

        @media all and (max-width: 1100px) {
          margin-right: 10px;
          padding: 15px 10px;
          text-align: center;
          height: 130px;
        }

        &__product {
          color: $white;
          font-size: 18px;
          font-weight: 300;
          margin: 0;

          @media all and (max-width: 1100px) {
            font-size: 15px;
            line-height: 20px;
            font-weight: 300;
            padding-bottom: 5px;
          }
        }

        &__discount {
          color: $white;
          font-size: 50px;
          line-height: 50px;
          font-weight: 700;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          margin: 0;
          padding-bottom: 10px;

          @media all and (max-width: 1100px) {
            font-size: 23px;
            line-height: 23px;
            text-align: center;
            width: 100%;
            padding-top: 10px;
            white-space: pre-wrap;
          }
        }
      }

      &__third {
        background-color: #6C8AA5;
        height: auto;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 15px 20px 0;
        padding: 15px 30px 5px;

        @media all and (max-width: 1100px) {
          margin-right: 0;
          padding: 15px 10px;
          text-align: center;
          height: 130px;
        }

        &__product {
          color: $white;
          font-size: 18px;
          font-weight: 300;
          margin: 0;

          @media all and (max-width: 1100px) {
            font-size: 15px;
            line-height: 20px;
            font-weight: 300;
            padding-bottom: 5px;
          }
        }

        &__discount {
          color: $white;
          font-size: 50px;
          line-height: 50px;
          font-weight: 700;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          margin: 0;
          padding-bottom: 10px;

          @media all and (max-width: 1100px) {
            font-size: 23px;
            line-height: 23px;
            text-align: center;
            width: 100%;
            padding-top: 10px;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  &__products {
    padding: 0 0 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;

    &__cart {
      padding: 0;
      border: 1px solid $border-cart;
      border-radius: 10px;
      box-sizing: border-box;
      background-color: $white;
      height: auto;
      margin: 0 150px 0 0;
      width: 100%;

      &__header {
        background-color: $header-cart;
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        border-radius: 10px;

        @media all and (max-width: 1100px) {

          &__title {
            font-size: 13px;
            line-height: 15px;
            display: inline-block;
            position: relative;
            top: 2px;
          }
        }

        input[type="checkbox"] + label {
          width: 23px;
          height: 31px;
          border-radius: 5px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 400;
          background-color: $white;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          margin: -24px 5px 0 0;
          cursor: pointer;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;

          img {
            display: none;
          }
        }

        input[type="checkbox"]:checked + label {
          font-weight: bold;
          width: 23px;
          height: 31px;
          border-radius: 5px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 700;
          background-color: $input-radio;
          margin: -24px 5px 0 0;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;
          position: relative;

          img {
            right: -20%;
            top: -20%;
            height: 32px;
            position: absolute;
            display: block;
          }
        }

        input[type="checkbox"] {
          opacity: 0;
        }

        &__text {
          color: $dark-border;
          font-size: 18px;
          margin: 0 0 0 10px;
          font-weight: 500;
        }
      }

      &__body {
        padding: 30px;

        @media all and (max-width: 1100px) {
          padding: 15px;
        }

        &__title {
          font-weight: 300;
          font-size: 15px;
          color: $dark-border;
          border-bottom: 1px solid $gray-text;
          padding: 0 0 5px 0;

          @media all and (max-width: 1100px) {
            font-size: 13px;
          }
        }

        &__block {
          padding: 10px 0 30px 0;
          border-bottom: 1px solid $gray-text;

          &__aux {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;

            &__name {
              font-size: 22px;
              font-weight: 300;
              color: $product-cart;
              margin: 0;

              @media all and (max-width: 1100px) {
                font-size: 16px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &__values {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              &__old {
                margin: 0 0 0 15px;
                color: $old-value;
                font-size: 15px;
                font-weight: 500;
                text-decoration: line-through;

                @media all and (max-width: 1100px) {
                  font-size: 13px;
                  white-space: nowrap;
                }
              }

              &__current {
                margin: 0 0 0 15px;
                color: $old-value;
                font-size: 20px;
                font-weight: 700;

                @media all and (max-width: 1100px) {
                  font-size: 16px;
                  white-space: nowrap;
                }
              }

              &__close {
                margin: 0 0 0 15px;
                cursor: pointer;
                color: $old-value;
                font-size: 15px;
                font-weight: 400;
              }
            }
          }
        }

        &__total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 25px 0;
          border-bottom: 1px solid $gray-text;

          &__title {
            font-size: 22px;
            font-weight: 300;
            color: $product-cart;
            margin: 0;
          }

          &__values {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &__old {
              margin: 0 0 0 15px;
              color: $dark-border;
              font-size: 15px;
              font-weight: 500;
              text-decoration: line-through;
            }

            &__current {
              margin: 0 0 0 15px;
              color: $dark-border;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }

        &__freight {
          padding: 30px 0;
          border-bottom: 1px solid $gray-text;

          &__title {
            font-size: 16px;
            color: $product-cart;
            margin: 0 0 10px 0;
            font-weight: 300;
          }

          &__aux {
            display: flex;
            justify-content: space-between;
            align-items: center;

            input[type=text] {
              box-shadow: inset 1px 5px 7px -1px rgba(0, 0, 0, 0.15);
              border-radius: 10px;
              border: 1px solid $border-input;
              width: 335px;
              height: 58px;
            }

            input[type=button] {
              background-color: $button-form !important;
              width: 135px;
              height: 58px;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $white;
              font-size: 16px;
              font-weight: 700;
              border: none;
              margin: 0 0 0 15px;
              cursor: pointer;
            }
          }
        }

        &__submit {
          padding-top: 30px;
        }

        &__atention {
          padding: 40px 0 0 0;

          &__aux {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__description {
              color: $blue;
              font-size: 16px;
              font-weight: 500;
              margin: 0 0 0 10px;
              text-transform: uppercase;
              line-height: 26px;
              display: flex;
              flex-direction: row;

              @media all and (max-width: 1100px) {
                flex-direction: column;
                text-align: center;
              }

              &__warning {
                padding-right: 15px;
                position: relative;
                top: 12px;

                @media all and (max-width: 1100px) {
                  top: 0;
                  display: block;
                  margin: 0 auto 15px;
                  padding: 0;
                }
              }

              @media all and (max-width: 1100px) {
                font-size: 13px;
                line-height: 21px;
                margin: 0;
              }
            }
          }

          &__aux-input {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 15px 0 0 0;

            input[type="radio"] + label {
              font-weight: bold;
              width: 23px;
              height: 31px;
              border-radius: 5px;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $text-input;
              font-size: 22px;
              font-weight: 400;
              background-color: $white;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              margin: 0 30px 0 0;
              cursor: pointer;
              transition: all 0.3s ease-out;
              text-align: center;
              padding: 0 20px;

              img {
                display: none;
              }
            }

            input[type="radio"]:checked + label {
              font-weight: bold;
              width: 23px;
              height: 31px;
              border-radius: 5px;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $text-input;
              font-size: 22px;
              font-weight: 700;
              background-color: $input-radio;
              margin: 0 30px 0 0;
              transition: all 0.3s ease-out;
              text-align: center;
              padding: 0 20px;
              position: relative;

              img {
                right: -20%;
                top: -20%;
                height: 32px;
                position: absolute;
                display: block;
              }
            }

            input[type="radio"] {
              opacity: 0;
            }

            &__text {
              color: $blue;
              font-size: 16px;
              font-weight: 400;
              margin: 0 0 -23px 0;
            }
          }

          &__button {
            width: 100%;
            height: 58px;
            margin: 40px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            background-color: $primary-color;
            font-size: 18px;
            font-weight: 700;
            box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
            border-radius: 10px;
          }

          &__description {
            color: $gray-text;
            font-size: 12px;
            font-weight: 400;
            margin: 15px 0;
            text-align: center;

            @media all and (max-width: 1100px) {
              font-size: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__blocks {
      margin: 0;
      padding-right: 30px;

      @media all and (max-width: 1100px) {
        padding-right: 0;
      }

      &__item {
        background-color: $background-item;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 30px;
        margin: 0 30px 30px 0;
        transition: all 0.5s ease-out;
        transform-style: preserve-3d;
        position: relative;
        flex: 1 1 0;

        @media all and (max-width: 1100px) {
          margin-right: 0;
        }

        &__active {
          padding: 30px;
          transition: all 0.5s ease-out;
        }

        &__aux {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          margin: 0;
          transform-style: preserve-3d;

          @media all and (max-width: 1100px) {
            align-items: flex-start;
          }

          img {
            max-width: 30%;

            /*  @media all and (max-width: 1100px) {
                max-width: initial;
                max-height: 175px;
              }*/
          }

          &__active {
            display: none;
          }

          &__title {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            font-weight: 300;
            font-size: 30px;
            margin: 0 0 15px 0;
            text-align: right;
            color: #474747 !important;

            @media all and (max-width: 1100px) {
              margin-top: -10px;
              font-size: 23px;
            }

            &__value {
              color: $dark-border;
              font-weight: 700;
              font-size: 24px;

              @media all and (max-width: 1100px) {
                font-size: 23px;
              }
            }
          }

          &__amount {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;

            @media all and (max-width: 1100px) {
              padding-top: 15px;
              flex-direction: column-reverse;
            }

            &__i {
              font-family: 'Aleo';
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              background-color: $white;
              width: 50px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              font-size: 30px;
              color: $black;
              margin: 0 0 5px 0;
              position: absolute;
              bottom: -5%;
              right: -4%;

              @media all and (max-width: 1100px) {
                font-size: 20px;
                position: relative;
                bottom: initial;
                right: initial;
                width: 35px;
                height: 35px;
                margin-top: 15px;
              }
            }
          }
        }

        &__aux-two {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          height: 100%;
          margin: 0;
          overflow: visible;
          opacity: 1;
          position: relative;
          z-index: 50;
          transition: transform 0.8s;
          padding: 0 15px 15px;

          &__title {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: 300;
            font-size: 30px;
            margin: 0 0 15px 0;
            background: transparent;

            &__value {
              color: $dark-border;
              font-weight: 700;
              font-size: 24px;

              @media all and (max-width: 1100px) {

              }
            }
          }

          &__description {
            width: 100%;
            background-color: transparent;
            padding: 0;

            @media all and (max-width: 1100px) {
              font-size: 11px
            }
          }

          &__amount {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__i {
              font-family: 'Aleo';
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              background-color: $white;
              width: 45px;
              height: 45px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              font-size: 30px;
              color: $black;
              margin: 18px 30px 5px 0;
              position: absolute;
              bottom: -4.5%;
              right: -13%;

              @media all and (max-width: 1100px) {
                font-size: 20px;
                position: relative;
                bottom: initial;
                right: initial;
                width: 35px;
                height: 35px;
                margin-top: 15px;
              }
            }
          }
        }
      }

      .amount-selection__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 132px;
        height: 42px;
        border-radius: 200px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 1px 0 0 0;
        background: $white;
      }

      button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-containedSizeLarge.MuiButton-sizeLarge {
        background: transparent;
        color: black;
        width: 0;
        border: none;
        box-shadow: none;
        margin: -9px;
      }

      .MuiButton-containedSizeLarge {
        padding: 0;
        font-size: 22px;
        font-weight: 700;
      }

      input.amount-selection__amount__value__input {
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  &__wait {
    background: $white;
    padding: 100px 0;

    @media all and (max-width: 1100px) {
      padding: 60px 0;
    }

    &__subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 23px;
      font-weight: 500;
      color: $border-ask;
      text-align: left;
      margin-bottom: 0;

      @media all and (max-width: 1100px) {
        font-size: 16px;
        text-align: center;
      }
    }

    &__title {
      color: $dark-blue;
      font-size: 40px;
      font-weight: 700;
      margin: 0 auto 60px auto;
      text-align: left;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        text-align: center;
      }
    }

    &__aux {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 40px auto 0;
      text-align: left;

      @media all and (max-width: 1100px) {
        display: block;
        text-align: center;
      }

      &__block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 40px 0 0;

        @media all and (max-width: 1100px) {
          margin-right: 0;
          justify-content: center;
          align-items: center;

        }

        &__title {
          color: $primary-color;
          font-size: 20px;
          font-weight: 700;
          margin: 15px 0;
        }

        &__description {
          width: 80%;
          color: $product-cart;
          font-size: 18px;
          font-weight: 300;
          line-height: 21px;

          @media all and (max-width: 1100px) {
            width: 100%;
            padding-bottom: 30px;
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }

  &__how-work {
    background: $white;
    padding: 100px 0;

    @media all and (max-width: 1100px) {
      padding: 0;
    }

    &__subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 23px;
      font-weight: 500;
      color: $border-ask;
      margin: 0 0 5px;
      text-align: left;

      @media all and (max-width: 1100px) {
        font-size: 16px;
        text-align: center;
      }
    }

    &__title {
      color: $dark-blue;
      font-size: 40px;
      font-weight: 700;
      margin: 0 auto 60px;
      text-align: left;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        text-align: center;
      }
    }

    &__aux {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      margin: 40px auto 0 auto;

      &__block {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 40px 0 0;
        flex: 1 1 0;

        @media all and (max-width: 1100px) {
          justify-content: center;
          align-items: center;
          margin-right: 0;
        }

        &__image {

          @media all and (max-width: 1100px) {
            // width: 50%;
          }

          &__wrapper {
            min-height: 234px;

            @media all and (max-width: 1100px) {
              text-align: center;
              min-height: initial;
            }
          }
        }

        &__title {
          color: $primary-color;
          font-size: 20px;
          font-weight: 700;
          margin: 15px 0 15px 0;
          text-align: left;

          @media all and (max-width: 1100px) {
            text-align: center;
          }
        }

        &__description {
          color: $product-cart;
          font-size: 18px;
          font-weight: 300;
          text-align: left;
          line-height: 21px;
          width: 80%;

          @media all and (max-width: 1100px) {
            text-align: center;
            padding-bottom: 30px;
            font-size: 15px;
            line-height: 20px;
          }

          a {
            font-weight: bold;
            color: #3C5B7A;
          }
        }
      }
    }
  }


  &__effect {
    background: $white;
    padding: 100px 0;

    @media all and (max-width: 1100px) {
      padding: 30px 0;
    }

    &__title {
      color: #0C1931;
      font-size: 40px;
      line-height: 50px;
      font-weight: 700;
      margin: 0 auto 60px;
      text-align: left;
      white-space: pre-wrap;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        line-height: initial;
        text-align: center;
        white-space: initial;
        padding-top: 30px;
      }
    }

    &__aux {

      &__block {

        &__title {
          color: #383838;
          font-size: 20px;
          font-weight: 700;
          margin: 15px 0;
          text-align: left;

          @media all and (max-width: 1100px) {
            text-align: center;
          }
        }

        &__description {
          color: #383838;
          font-size: 18px;
          font-weight: 300;
          text-align: left;
          line-height: 21px;
          width: 80%;

          @media all and (max-width: 1100px) {
            text-align: center;
            margin-bottom: 30px;
            font-size: 15px;
            line-height: 20px;
            width: 100%;
          }
        }

        &__image {
          width: 200px;
          display: block;
          margin-bottom: 30px;

          @media all and (max-width: 1100px) {
            width: 50%;
            text-align: center;
            margin: auto;
          }
        }
      }
    }
  }


  &__easy {
    background: rgba(249, 244, 232, 0.35);
    padding: 100px 0;

    @media all and (max-width: 1100px) {
      padding: 30px 0;
    }

    &__subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 23px;
      font-weight: 500;
      color: #D4B494;
      margin: 0 0 5px;
      text-align: left;
      padding-bottom: 10px;

      @media all and (max-width: 1100px) {
        font-size: 16px;
        text-align: center;
        padding-top: 30px;
      }
    }

    &__title {
      color: $dark-blue;
      font-size: 40px;
      line-height: 50px;
      font-weight: 700;
      margin: 0 auto 60px;
      text-align: left;
      white-space: pre-wrap;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        line-height: initial;
        text-align: center;
        white-space: initial;
      }
    }

    &__aux {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 40px auto 0;

      &__block {

        @media all and (max-width: 1100px) {
          text-align: center;
        }

        &__description {
          color: $text-product;
          font-size: 18px;
          line-height: 21px;
          font-weight: 300;
          margin: 25px 0;
          white-space: pre-wrap;

          @media all and (max-width: 1100px) {
            white-space: initial;
            font-size: 15px;
            line-height: 20px;
          }
        }

        &__image {
          width: 80%;
        }
      }
    }
  }

  &__ask-hair {
    border: 1px solid $border-ask;
    border-radius: 45px;
    padding: 70px;

    @media all and (max-width: 1100px) {
      padding: 30px 15px;
    }

    &__wrapper {
      width: 60%;
      margin: 90px auto 0;

      @media all and (max-width: 1100px) {
        width: 100%;
        padding: 30px;
        margin-top: 0;
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $title-ask;
      font-weight: 700;
      font-size: 55px;
      line-height: 75px;
      margin: 0;

      @media all and (max-width: 1100px) {
        font-size: 20px;
        line-height: initial;
        text-align: center;
      }

      img {
        margin: 15px 0;

        @media all and (max-width: 1100px) {
          display: none !important;
        }
      }
    }

    .ant-collapse.ant-collapse-icon-position-left {
      width: 100%;
      border: none;
      background: transparent;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      background-color: $white;
      color: $title-ask;
      font-size: 16px;
      font-weight: 600;
    }

    .ant-collapse-content-box {
      background-color: $white;
    }
  }

  &__ask-prescription {
    border: 1px solid $border-ask;
    border-radius: 45px;
    padding: 70px;
    background-color: #F1F7F8;

    @media all and (max-width: 1100px) {
      padding: 30px 15px;
    }

    &__wrapper {
      width: 60%;
      margin: 90px auto 0;

      @media all and (max-width: 1100px) {
        width: 100%;
        padding: 30px;
        margin-top: 0;
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $title-ask;
      font-weight: 700;
      font-size: 55px;
      line-height: 75px;
      margin: 0;

      @media all and (max-width: 1100px) {
        font-size: 23px;
        line-height: initial;
        text-align: center;
      }

      img {
        margin: 15px 0;

        @media all and (max-width: 1100px) {
          display: none !important;
        }
      }
    }

    .ant-collapse.ant-collapse-icon-position-left {
      width: 100%;
      border: none;
      background: transparent;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: $title-ask;
      font-size: 16px;
      font-weight: 600;
      background-color: #F1F7F8;
    }

    .ant-collapse-content > .ant-collapse-content-box{
      background-color: #F1F7F8;
    }

    .ant-collapse-content-box {
      background-color: #F1F7F8;
    }
  }
}


h2.amount-selection__amount {
  margin: 0;
}

.react-card-flip {
  width: 100%;
  height: 410px;

  @media all and (max-width: 1100px) {
    height: auto;
  }
}
