.ant-input-affix-wrapper-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px transparent;
  box-shadow: 0 0 0 2px transparent;
}

.ant-input:focus {
  -webkit-box-shadow: 0 0 0 2px transparent;
  box-shadow: 0 0 0 2px transparent;
}

.ant-input-affix-wrapper {
  padding: 4px;
}

.ant-btn-circle {
  border: none;
}

.ant-input {
  padding: 8px 4px;
}