.advanced-select {
  padding: 15px 0;
  width: 100% !important;
  margin-right: 15px;

  @media all and (max-width: 1100px) {
    // margin-right: 0;
  }

  & > .MuiFormControl-root {
    margin: 0 !important;
  }

  @media all and (max-width: 1100px) {
    & > .MuiFormControl-root {
      margin: 0 !important;
    }
  }
}

.MuiListItem-root {
  width: 100% !important;
}
