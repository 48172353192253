.auto-complete-dropdown-container {
  position: relative;

  ul {
    border: 1px solid #000000;
    list-style: none;
    padding: 0;
    border-end-end-radius: 5px;
    border-end-start-radius: 5px;
    position: absolute;
    width: 100%;
    background-color: #FFFFFFFF;
    max-height: 200px;
    overflow-y: scroll;

    .active-item {
      background-color: #00000019;
    }

    li {
      padding: 5px 10px;
      font-size: 15px;

      &:hover {
        cursor: pointer;
        background-color: #0000000a;
      }
    }
  }
}