.loading {

  &__wrapper {
    width: 100%!important;
    height: 100%!important;
    background-color: rgba(255, 255, 255, 0.3)!important;
    display: flex!important;
    padding: 5px;
    justify-content: flex-end!important;
    z-index: 1000!important;
  }
}
