.checkout {

  &__empty {
    text-align: center;
    padding-top: 50px;

    &__title {
      margin-bottom: 0;
    }

    &__subtitle {

    }
  }

  &__upper {

    &--prescription {
      display: block !important;
      margin-bottom: 0;

      &__cancel {

        &__button {
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          background-color: white;
          color: #626262;
          border: 1px solid #626262;
          border-radius: 5px;
          padding: 5px 10px;
          display: inline-block;
          margin-top: 30px;
          cursor: pointer;
          transition: all 200ms ease-in-out;

          &:hover {
            color: white;
            background-color: #626262;
          }
        }
      }
    }

    &__button {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
      display: block;
      width: 100%;
      color: white;
      border: 1px solid transparent;
      background-color: #77B8E1;
      border-radius: 10px;
      cursor: pointer;
      padding: 10px 0;
      min-height: 60px;
      transition: background-color 200ms ease-in-out;
      margin-top: 15px;

      @media all and (max-width: 1100px) {
        font-size: 13px;
      }

      &:hover {
        border: 1px solid #77B8E1;
        background-color: white;
        color: #77B8E1;
      }

      &--ghost {
        border: 1px solid #77B8E1;
        background-color: white;
        color: #77B8E1;

        &:hover {
          color: white;
          border: 1px solid transparent;
          background-color: #77B8E1;
        }
      }
    }
  }

  &__coupon-modal {

    .ant-btn-primary {
      background-color: #5db95d;
      border-radius: 5px;
      border: none !important;
    }
  }

  &__coupon {

    &--invalid {
      color: #f30000;
      display: block;
      margin-bottom: 15px;
    }

    &--valid {
      display: block;
      padding-top: 15px;
      color: #28A745;
    }
  }
}
