.checkout-status {
  background-color: #f2f2f2;
  padding-bottom: 80px;

  @media all and (min-width: 1100px) {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 60px;
    margin-top: 45px;
  }

  &__top-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;

    &__inner {
      background: #e2ccb4;
      padding: 15px 0;
    }

    &__img {
      width: 100%;
      height: auto;
    }

    &--desktop {
      font-size: 30px;
      font-weight: bold;
      color: white;
      padding-right: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 200px;
      justify-content: flex-end;
      background-size: cover;
    }
  }

  &__info {
    padding-bottom: 25px;
    font-family: 'Montserrat', sans-serif;

    &__container {
      justify-content: center !important;
      display: flex !important;
    }

    @media all and (min-width: 1100px) {
      border-radius: 30px;
      background-color: white;
      margin-top: 2vw;
    }

    @media all and (max-width: 1100px) {
      padding-bottom: 25px;
    }

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ededed;
      margin-bottom: 15px;
      background-color: #e5e6e5;
      border-radius: 30px 30px 0 0;
      height: 5rem;
      padding: 0 43px;
      font-family: 'Montserrat';

      @media all and (max-width: 1100px) {
        margin-bottom: 0;
        gap: 0;
        margin-top: 60px;
      }

      &__icon {
        width: 45px;

        @media all and (max-width: 1100px) {
          width: 35px;
        }
      }

      &__text {
        font-size: 20px;
        letter-spacing: 2px;

        &__strong {
          @media all and (max-width: 1100px) {
            font-size: 3.5vw;
            letter-spacing: 0;
            flex: none;
          }
        }
      }

      &--credit-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ededed;
        margin-bottom: 15px;
        background-color: #e0efe5;
        border-radius: 30px 30px 0 0;
        height: 5rem;
        padding: 0 43px;

        @media all and (max-width: 1100px) {
          margin-bottom: 0;
          margin-top: 60px;
        }

        &__icon {
          width: 45px;

          @media all and (max-width: 1100px) {
            width: 35px;
          }
        }

        &__text {
          font-size: 20px;
          letter-spacing: 2px;

          &__strong {
            @media all and (max-width: 1100px) {
              font-size: 3.5vw;
              letter-spacing: 0;
            }
          }
        }
      }
    }

    &__body {
      padding: 50px 50px 0 50px;
      text-align: center;
      font-family: 'PrimeformProRegular';

      @media all and (max-width: 1100px) {
        padding: 20px 20px 0 20px; 
        background-color: #fff;
        border-radius: 0 0 18px 18px;
      }

      &__link {
        font-weight: bold;
        text-decoration: underline;
        color: black;
      }

      &__greet {
        text-transform: capitalize;
      }

      &__may-also-like {
        @media all and (min-width: 1100px) {
          padding-top: 45px;
        }

        &__title {
          font-family: 'Montserrat', sans-serif;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          padding: 30px 0 15px;
        }
      }
    }
  }

  .checkout {
    &__content {
      &__info {
        &__qr-code {
          &__url {
            color: #000;

            &:hover {
              opacity: 0.7;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }

  .home-dots {
    bottom: -50px;
  }

  .home-dots li button:before {
    opacity: 0.2;
    color: $primary-color;
  }

  .home-dots li.slick-active button:before {
    opacity: 0.75;
    color: $primary-color;
  }
}

.checkout {
  &__content {
    &__info {
      &__qr-code {
        &__image {
          @media all and (max-width: 1100px) {
            height: 70vw;
          }
        }
      }
    }

    &__return {
      text-align: center;
      margin-top: 90px;
      text-decoration-line: underline;

      &__text {
        color: #474747;
        font-weight: 700;
        font-size: 17px;
        line-height: 33px;
        font-family: 'PrimeformPro';
      }
    }
  }
}

.anticon.anticon-clock-circle {
  font-size: 1.5vw;
  color: red;

  @media all and (max-width: 1100px) {
    font-size: 4vw;
  }
}

.checkout__order {
  &--pix {
    background: #1d6ea3;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 7px 0;
    font-family: 'PrimeformProSemiBold';

    @media all and (max-width: 1100px) {
      font-size: 14px;
    }
  }

  &--credit-card {
    background: #36b477;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 7px 0;
    font-family: 'PrimeformProSemiBold';

    @media all and (max-width: 1100px) {
      font-size: 14px;
    }
  }

  &__blog {
    display: flex;
    justify-content: center;
    margin-top: 150px;

    @media all and (max-width: 1100px) {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: #7d7d7d;
      font-family: 'PrimeformPro';
    }

    &__items {
      display: flex;
      justify-content: space-between;
      gap: 21px;
      flex-wrap: wrap;

      @media all and (max-width: 1100px) {
        gap: 0;
      }

      &__card {
        background: #ffffff;
        border-radius: 0 0 15px 15px;
        height: 142px;

        &__text {
          width: 300px;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          padding: 15px 30px;
          margin-bottom: 0;
        }

        &__link {
          text-decoration-line: underline !important;
          color: #000000;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          font-family: 'Montserrat';
          padding-left: 30px;
        }
      }

      &__inner {
        position: relative;

        @media all and (max-width: 1100px) {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
        }

        &__img {
          position: relative;

          &__image {
            width: 100%;
          }
        }
      }

      &__button {
        width: 92px;
        height: 12px;
        cursor: pointer;
        top: 16.5rem;
        left: 2rem;
        position: absolute;

        @media all and (max-width: 1100px) {
          top: 13.5rem;
          left: 1rem;
          width: 123px;
        }

        &--last {
          width: 92px;
          height: 18px;
          cursor: pointer;
          top: 18rem;
          left: 2rem;
          position: absolute;

          @media all and (max-width: 1100px) {
            top: 15rem;
            left: 1rem;
            width: 122px;
          }
        }
      }
    }
  }
}
