.white-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  border: none;
  border-radius: 10px;
  color: white;
  box-shadow: $default-shadow;
  opacity: 1;
  padding: 17px 30px !important;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  display: inline-block;
  outline: 0 !important;
  line-height: initial !important;
  text-align: center !important;

  @media all and (max-width: 1100px) {
    font-size: 13px;
  }

  &:hover {
    color: white;
    box-shadow: $hover-shadow
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    box-shadow: $default-shadow;
  }
}
