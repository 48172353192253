@font-face {
  font-family: MontSerrat;
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
}

.register {
  padding: 100px 0 100px;
  display: flex;
  justify-content: space-between;

  &__form-container {
    width: 33%;

    @media all and (max-width: 1100px) {
      margin-top: 50px !important;
      width: 100%;
    }


  }

  &__title {
    font-family: MontSerrat, sans-serif;
    color: $dark-blue;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 0 100px;
    text-transform: uppercase;

    &__secondary {
      font-family: MontSerrat, sans-serif;
      color: $text-register;
      font-weight: 400;
      font-size: 15px;
      margin: 0 0 40px 100px;
    }
  }

  &__sub-title {
    font-size: 18px;
    color: $black;
    font-weight: 700;
    letter-spacing: 5px;
    margin: 0 0 10px 100px;
    display: flex;
    align-items: center;

    img {
      margin: -5px 0 0 20px;
    }
  }

  &__sub-title-two {
    font-size: 18x;
    color: $black;
    font-weight: 700;
    letter-spacing: 5px;
    margin: 30px 0 10px 100px;
    display: flex;
    align-items: center;
  }

  &__description {
    margin: 10px 0 15px;
    color: $gray-text;
    font-weight: 700;
    font-size: 16px;
  }

  &__aux {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 100px;

    &__input {
      width: 48% !important;
      margin: 10px 0;
    }

    &__input-large {
      width: 100% !important;
      margin: 10px 0;
    }

    &__input-button {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      &__button {
        width: 100%;
        margin: 30px 0;
        background-color: $dark-blue;
        height: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 18px;
        font-weight: 900;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        border: none;
        cursor: pointer;
      }
    }

  }

  &__image-container {
    width: 66%;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &__image {
      width: 100%;
    }

    &__title {
      margin-top: 50px;
      text-align: center;
      font-size: 50px;

      @media all and (max-width: 1100px) {
        margin-top: 10px;
        font-size: 30px !important;
      }
    }
  }
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.PrivateNotchedOutline-root-1 {
  border-radius: 10px;
}

@media screen and (max-width: 995px) {

  .register {
    flex-direction: column;

    .vertical-box-slider {
      display: none;
    }

    &__title {
      margin: 0;

      &__secondary {
        margin: 0 0 30px 0;
      }
    }

    &__sub-title {
      margin: 0;
    }

    &__aux {
      margin: 0;
    }

    &__sub-title-two {
      margin: 30px 0 10px 0;
    }
  }
}
