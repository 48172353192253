.lightning-offer {

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
      background-color: #FFFFFF;
      height: 9rem;
      width: 38.45rem;
      border-radius: 25px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      box-shadow: 1px 2px 11px rgb(168, 164, 164);

      @media all and (max-width: 1100px) {
        height: 7rem;
        margin-top: 0.5rem;
        width: 90%;
        box-shadow: 1px 2px 11px rgb(168, 164, 164);
      }

      &__container {
        display: flex;
        align-items: center;
        height: 100%;

        &__content-image {
          padding-right: 1.5rem;
          padding-left: 1rem;

          @media all and (max-width: 1100px) {
            padding-right: 1rem;
          }
        }

        &__content {
          width: 100%;
          height: 100%;

          &__description {
            margin-top: 0.8rem;

            @media all and (max-width: 1100px) {
              margin-top: 0.5rem;
            }

            &__title {
              color: #000;
              font-family: 'Montserrat';
              font-weight: 700;
              font-size: 1vw;
              margin-bottom: 0;

              @media all and (max-width: 1100px) {
                font-size: 0.5rem;
              }
            }

            &__text {
              color: #000000;
              font-family: 'Montserrat';
              font-weight: 400;
              font-size: 1.07rem;
              margin-bottom: 0;
              width: 29rem;
              line-height: 1.3rem;

              @media all and (max-width: 1100px) {
                font-size: 0.7rem;
                width: 46vw;
                line-height: 0.85rem;
              }
            }
          }

          &__buy {
            display: flex;
            position: absolute;
            top: 5.8rem;
            gap: 2.8rem;
            width: 3.5%;

            @media all and (max-width: 1100px) {
              flex-direction: column;
              position: inherit;
              grid-gap: 0;
              gap: 0;
              margin-top: 0.3rem;
              width: 100%;
            }

            &__product-value {
              width: 33%;

              @media all and (max-width: 1100px) {
                width: 100%;
              }

              &__content-descount {
                display: flex;
                align-items: center;
                width: 9vw;
                gap: 5px;
                margin-bottom: 5px;

                @media all and (max-width: 1100px) {
                  width: 100%;
                  margin-bottom: 0;
                }

                &__of-price {
                  font-family: 'Montserrat';
                  font-weight: 400;
                  font-size: 0.8rem;
                  text-decoration: line-through;
                  margin-bottom: 0;

                  @media all and (max-width: 1100px) {
                    font-size: 0.75rem;
                  }
                }

                &__descount {
                  background-color: #61BE91;
                  padding: 1px 4px;
                  border-radius: 5px;
                  color: #FFFFFF;
                  font-family: 'Montserrat';
                  font-weight: 700;
                  font-size: 0.6rem;
                  height: 100%;

                  @media all and (max-width: 1100px) {
                    font-size: 0.5rem;
                  }
                }
              }

              &__price {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 1rem;
                margin-bottom: 0;
                width: 9vw;
                margin-top: -0.5vw;

                @media all and (max-width: 1100px) {
                  font-size: 0.83rem;
                  width: 100%;
                }
              }
            }

            &__container-button {
              height: 100%;
              width: 65%;

              &__button {
                background-color: #1D6EA3;
                border: none;
                color: #FFFFFF;
                width: 100%;
                height: 2.4rem;
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 0.85rem;
                border-radius: 30px;
                margin-top: 0.4rem;
                cursor: pointer;

                @media all and (max-width: 1100px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.container-mobile {
  color: #000;
  width: 92%;
  margin-left: 2rem;
}

.shampoo {
  height: 7.5rem;
  width: 7.8rem;
}

.image {
  width: 7rem;
  height: 7rem;

  @media all and (max-width: 1100px) {
    width: 6.5rem;
    height: 6rem;
  }
}