.cookies-message {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(59, 92, 122, 0.73);
  width: 100%;

  @media all and (max-width: 1100px) {
    text-align: center;
  }

  &__phrase {
    font-size: 10pt;
    padding: 25px 0 15px;
    color: white !important;

    @media all and (max-width: 1100px) {
      padding-top: 15px;
    }

    p {
      a {
        color: white !important;
        text-decoration: underline !important;
        text-underline-position: under;
      }
    }

    strong {
      font-size: 13pt;

      @media all and (max-width: 1100px) {
        display: block;
        margin-bottom: 15px;
      }
    }
  }

  &__button {
    padding-top: 30px;
    text-align: center;

    @media all and (max-width: 1100px) {
      padding: 0 0 15px;
    }

    &__inner {
      background-color: $primary-color !important;

      &:hover {
        background-color: lighten($primary-color, 8) !important;
      }
    }
  }
}
