.carousel-icons {
  margin: auto;
  margin-top: 150px;
  border: 3px solid black;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  padding-top: 20px;
  margin-bottom: -45px;
  position: relative;
  
  @media all and (max-width: 1100px) {
    margin: 50px auto -30px;
    width: 70px;
    height: 70px;

    & > svg {
      width: 30px;
      height: 30px;
    }
  }
}

.feature-carousel {
  padding: 0;
  background-color: $gray-bg;
  margin: 0 auto 100px auto;
  width: 1200px;
  height: 414px;
  overflow: hidden;
  border-radius: 10px;

  @media all and (max-width: 1100px) {
    width: 100vw;
    height: auto;
  }

  &__wrapper {
    background-color: $gray-bg;
    margin-top: 90px;

    @media all and (max-width: 1100px) {
      margin-top: 30px;
    }
  }

  &__inner {
    text-align: center;

    &__icon {
      border: 3px solid black;
      width: 100px;
      height: 100px;
      background-color: white;
      border-radius: 50%;
      text-align: center;
      padding-top: 20px;
      margin: -150px auto 100px;

      @media all and (max-width: 1100px) {
        margin: -150px auto 0;
        width: 70px;
        height: 70px;

        & > svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    &__content {

      &__title {

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 30px;
          line-height: 37px;
          margin: 57px 0 65px;

          @media all and (max-width: 1100px) {
            margin-bottom: 0;
            padding-bottom: 15px;
            font-size: 17px;
          }
        }
      }
    }

    &__indicator {
      background-color: $gray-bg;
      padding-bottom: 45px;

      @media all and (max-width: 1100px) {
        padding: 30px 0;
        padding-bottom: 60px;
      }

      &__menu {
        padding: 0;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        @media all and (max-width: 1100px) {
          width: 100%;
          position: relative;
          top: -10px;
        }

        &__item {
          list-style: none;
          padding: 0 8px;

          &__button {
            background-color: white;
            width: 53px;
            height: 53px;
            border-radius: 50%;
            border: none;
            outline: 0 !important;
            padding: 0;
            position: relative;
            cursor: pointer;

            @media all and (max-width: 1100px) {
              width: 30px;
              height: 30px;
            }

            &--active {
              background-color: black;
            }

            &__icon {
              width: 53px;
              height: 53px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              padding-top: 6px;

              @media all and (max-width: 1100px) {
                width: 30px;
                height: 30px;
              }

              svg {
                width: 30px;
                height: 30px;

                @media all and (max-width: 1100px) {
                  width: 15px;
                  height: 15px;
                  position: relative;
                  top: -3px;
                }
              }
            }
          }
        }
      }
    }
  }

  .slick-list {
    overflow: visible !important;
  }
}
