.top-bar-appointment {
  background-color: black;
  padding: 20px 0;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--1 {
    background-color: #6DA7CB;
  }

  &--2 {
    background-color: #8371B9;
  }

  &--3 {
    background-color: #4C9C9C;
  }

  &--4 {
    background-color: #296F6F;
  }

  &--5 {
    background-color: #626262;
  }

  &--policy {
    background: #7D7D7D!important;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: 100%;

    &__go-back {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &__img {
        width: 10px;
        margin-left: 15px;
        margin-right: auto;
        cursor: pointer;
      }
    }
  }

  &__fix-style {
    margin-right: -20px;
  }
  
  &__logo {
    margin: 0 auto;

    &__text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;

      &--policy {
        display: none;
      }
    }

    &__img {
      width: 35px;
    }
  }
}

.prescription-switch__header__bg {
  position: relative;
}
