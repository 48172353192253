.prescription-switch {
  color: black;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    &__loading {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    padding-top: 180px;
    @media all and (max-width: 1081px) {
      margin: 0 30px;
    }

    &__step-one {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;

        @media all and (max-width: 1081px) {
          text-align: center;
        }
      }

      &__subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;

        @media all and (max-width: 1081px) {
          text-align: center;
        }
      }

      &__switch-container {
        display: flex;

        @media all and (max-width: 1100px) {
          flex-direction: column;
          margin-top: 1rem;
        }

        &__switch {
          margin-top: 50px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          @media all and (max-width: 1100px) {
            margin: 0;
          }
  
          &__item {
            width: 250px;
            height: 162px;
            margin: 0 10px;
            border-radius: 20px;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0px 1px 20px rgba(0,0,0,0.25);
            background-color: transparent;
  
            &--hair {
              border-style: solid;
              border-width: 3px;
              border-color: $price-color;
            }
  
            &--sleep {
              border-style: solid;
              border-width: 3px;
              border-color: $purple-light;
            }
  
            &--ed {
              border-style: solid;
              border-width: 3px;
              border-color: $light-green;
            }
  
            &--ej {
              border-style: solid;
              border-width: 3px;
              border-color:$wild-green;
            }

            &--be {
              border-style: solid;
              border-width: 3px;
              border-color: #626262;
              background-color: #E0E0E0;
              cursor: not-allowed;
            }
  
            @media all and (max-width: 1081px) {
              flex-direction: row;
              width: 85vw;
              height: 80px;
              margin: 15px 0px;
              justify-content: space-between;
            }
  
            &__img {
              height: 57px;
              margin-bottom: 15px;
  
              @media all and (max-width: 1081px) {
                margin-bottom: 0;
                height: 49px;
                margin: 15px 30px;
              }
            }
  
            &__text {
              text-align: center;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              color: #474747;
              margin-bottom: -15px;
              @media all and (max-width: 1081px) {
                margin-bottom: 0;
                margin-right: auto;
                margin-left: 10vw;
              }

              @media all and (max-width: 425px) {
                margin-bottom: 0;
                margin-right: auto;
                margin-top: 2vh;
                margin-bottom: 2vh;
                margin-left: 0vw;
              }

              &__subtitle {
                font-size: 16px;
                margin-top: 0.5em;
              }
            }

            &__title {
              line-height: normal;
            }

            &__description {
              font-size: 17px;
              font-weight: 300;
            }
          }
        }
      }

    }

    &__step-two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 55px;

        @media all and (max-width: 1081px) {
          text-align: center;
          margin-bottom: 0px;
        }
      }

      &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 0px;
        margin-bottom: 75px;

        @media all and (max-width: 1100px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 55px;
        }
        
        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 20%;
          margin: 0 25px;

          &--desktop {
            width: 15vw;

            img {
              width: 100%;
              height: auto;
              image-rendering: -webkit-optimize-contrast;
            }
          }

          @media all and (max-width: 1100px) {
            width: 70%;
            max-width: 650px;
            text-align: center;
          }

          &__img-container {
            border-radius: 50%;
            border: 1px solid #E0E0E0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            padding: 20px;

            @media all and (max-width: 1100px) {
              width: 65px;
              height: 65px;
              margin: auto;
              margin-top: 45px;
              margin-bottom: 15px;
            }
          }

          &__img {
            height: 50px;
            
            @media all and (max-width: 1100px) {
              height: 29px;
            }
          }

          &__title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 30px;

            @media all and (max-width: 1100px) {
              display: none;
            }
          }

          &__desc {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
          }
        }
      }

      &__button {
        cursor: pointer;
        background: #1D6EA3;
        border: none;
        border-radius: 0.8rem;

        font-family: Montserrat;
        font-size: 1em;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        text-align: center;
        font-weight: 700;

        width: 24rem;
        height: 2.5rem;
      }
    }

    &__step-three {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 50px;
        max-width: 600px;
      }

      &__form {
        width: 100%;
        max-width: 600px;

        &__items {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          width: 100%;

          @media all and (max-width: 1100px) {
            display: block;
          }

          .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl {
            border-radius: 12px;
          }

          .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
            font-family: Montserrat;
          }

          &--single {
            display: block;
          }
          
          &__item {
            width: 48%;

            @media all and (max-width: 1100px) {
              margin-bottom: 15px;
              width: 100%
            }
          }
        }
      }

      &__policy {
        margin-top: 10px;
        max-width: 600px;
        display: flex;

        &__text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #000000;
          cursor: pointer;
          line-height: 30px;

          a {
            color: #000000;
          }
        }

        &__input {
          margin-top: 5px;
        }
      }

      &__button {
        margin-top: 35px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        background-color: #1D6EA3;
        border-radius: 15px;
        width: 100%;
        max-width: 600px;
        padding: 1.3%;
        cursor: pointer;
        margin-bottom: 60px;

        @media all and (max-width: 1100px) {
          margin-top: 50px;
          margin-bottom: 50px;
          
          height: 63px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &__step-four {
      display: flex;
      flex-direction: column;
      max-width: 500px;
      margin: 0 auto;
      margin-top: 20px;

      &__item {
        text-align: left;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 15px;
      }
    }
  }
}

.prescription-switch-container {
  max-width: 73.375rem;

  height: 38.125rem;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  &__title {
    font-weight: 700;
    font-size: 1.5em;
  }

  &__box-content {
    display: flex;
    
    gap: 2rem;
    width: 60%;
    font-family: 'Montserrat', sans-serif;
    
    &__content-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3rem;
      margin-top: 1.5rem;

      h2 {
        text-align: start;
        font-size: 1em;
        font-weight: 600;
      }

      p {
        text-align: start;
        font-size: 0.8em;
        font-weight: 500;
      }

      &__circle-content {
        width: 4.813rem;
        height: 4.813rem;
        border-radius: 50%;
        background-color: #F8F8F8;
        border: 1px solid #E9E9E9;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
