html,
body {
  font-family: 'Roboto', sans-serif !important;
}

@import '~antd/dist/antd.css';
@import '~react-credit-cards/es/styles-compiled.css';

@import './_parameters';
@import './_globals';

// stacks
@import 'src/app/pages/Home/HomeNavigationStack.scss';
@import 'src/app/pages/Profile/ProfileNavigationStack.scss';

// pages
@import 'src/app/pages/Auth/Login/Login.scss';
@import 'src/app/pages/Auth/PasswordRecovery/PasswordRecovery.scss';

@import 'src/app/pages/Home/Home/Home.scss';
@import 'src/app/pages/Home/HairKit/HairKit.scss';
@import 'src/app/pages/Home/Register/Register.scss';
@import 'src/app/pages/Home/Help/Help.scss';
@import 'src/app/pages/Home/About/About.scss';
@import 'src/app/pages/Home/Product/Product.scss';
@import 'src/app/pages/Home/Cart/Cart.scss';
@import 'src/app/pages/Home/Approved/Approved.scss';
@import 'src/app/pages/Home/Prescription/Prescription.scss';
@import 'src/app/pages/Home/Search/Search.scss';
@import 'src/app/pages/Home/Clinic/Clinic.scss';
@import 'src/app/pages/Home/BannerCarousel/BannerCarousel.scss';
@import 'src/app/pages/Home/ClinicSuccess/ClinicSuccess.scss';
@import 'src/app/pages/Home/Recurrence/Recurrence.scss';
@import 'src/app/pages/Home/Subscription/Subscription.scss';
@import 'src/app/pages/Home/MoneyBack/MoneyBack.scss';
@import 'src/app/pages/Home/SleepGummy/SleepGummy.scss';
@import 'src/app/pages/Home/LpSleepGummy/LpSleepGummy.scss';
@import 'src/app/pages/Auth/Login/Login.scss';
@import 'src/app/pages/Auth/PasswordRecovery/PasswordRecovery.scss';
@import 'src/app/pages/TrackOrder/TrackOrderScreen/TrackOrderScreen.scss';

@import 'src/app/pages/Profile/ProfileRegister/ProfileRegister.scss';
@import 'src/app/pages/Profile/ProfileRegister/CollapseProfile/CollapseProfile.scss';
@import 'src/app/pages/Profile/ProfileRegister/CollapseProfile/CollapseEdit.scss';
@import 'src/app/pages/Profile/ProfileRegister/CollapseAddress/CollapseEdit.scss';
@import 'src/app/pages/Profile/ProfileRegister/CollapseAddress/CollapseAddress.scss';
@import 'src/app/pages/Profile/ProfileRegister/CollapsePrescription/CollapsePrescription.scss';
@import 'src/app/pages/Profile/ProfileHelp/ProfileHelp.scss';
@import 'src/app/pages/Profile/ProfileTalk/ProfileTalk.scss';
@import 'src/app/pages/Profile/ProfileSignature/ProfileSignature.scss';
@import 'src/app/pages/Profile/ProfileRequests/ProfileRequests.scss';
@import 'src/app/pages/Profile/ProfileChat/ProfileChat.scss';

// components
@import 'src/app/components/AdvancedInput/AdvancedInput.scss';
@import 'src/app/components/AdvancedButton/AdvancedButton.scss';
@import 'src/app/components/AdvancedForm/AdvancedForm.scss';
@import 'src/app/components/AdvancedSelect/AdvancedSelect.scss';
@import 'src/app/components/AlertCard/AlertCard.scss';
@import 'src/app/components/AdvancedFreight/AdvancedFreight.scss';
@import 'src/app/components/VerticalBoxSlider/VerticalBoxSlider.scss';
@import 'src/app/components/ProductDisplay/ProductDisplay.scss';
@import 'src/app/components/FeatureSection/FeatureSection.scss';
@import 'src/app/components/FeatureSectionFull/FeatureSectionFull.scss';
@import 'src/app/components/FeatureCarousel/FeatureCarousel.scss';
@import 'src/app/components/PromoSection/PromoSection.scss';
@import 'src/app/components/PromoSectionTwo/PromoSectionTwo.scss';
@import 'src/app/components/PageFooter/PageFooter.scss';
@import 'src/app/components/CheckoutFooter/CheckoutFooter.scss';
@import 'src/app/components/ProductBegin/ProductBegin.scss';

@import 'src/app/components/PageContent/PageContent.scss';
@import 'src/app/components/CartContent/CartContent.scss';
@import 'src/app/components/PageContentHeader/PageContentHeader.scss';
@import 'src/app/components/ProfileContentHeader/ProfileContentHeader.scss';
@import 'src/app/components/ProfileContent/ProfileContent.scss';
@import 'src/app/components/PageContentClinic/PageContentClinic.scss';
@import 'src/app/components/HomeSlider/HomeSlider.scss';

@import 'src/app/components/PanelContent/PanelContent.scss';
@import 'src/app/components/PanelContentWhithoutNavbar/PanelContentWhithoutNavbar.scss';
@import 'src/app/components/PanelContentHeader/PanelContentHeader.scss';

@import 'src/app/components/TopBar/TopBar.scss';
@import 'src/app/components/TopBarPanel/TopBarPanel.scss';
@import 'src/app/components/BottomPanel/BottomPanel.scss';
@import 'src/app/components/HeaderPanel/HeaderPanel.scss';
@import 'src/app/components/HeaderCarousel/HeaderCarousel.scss';
@import 'src/app/components/LightningOffer/LightningOffer.scss';
@import 'src/app/components/OfferCarousel/OfferCarousel.scss';
@import 'src/app/components/Counter/Counter.scss';
@import 'src/app/components/PanelPagination/PanelPagination.scss';
@import 'src/app/components/Loading/Loading.scss';
@import 'src/app/components/WhiteButton/WhiteButton.scss';
@import 'src/app/components/Breadcrumb/Breadcrumb.scss';
@import 'src/app/components/ProductInfo/ProductInfo.scss';
@import 'src/app/components/AmountSelection/AmountSelection';
@import 'src/app/components/AutocompleteDropdown/AutocompleteDropdown.scss';
@import 'src/app/components/AmountPicker/AmountPicker';
@import 'src/app/components/GhostButton/GhostButton';
@import 'src/app/components/AdvancedFileUploader/AdvancedFileUploader';
@import 'src/app/components/AdvancedDatePicker/AdvancedDatePicker';
@import 'src/app/components/AdvancedCheckbox/AdvancedCheckbox';
@import 'src/app/components/AdvancedRadio/AdvancedRadio';
@import 'src/app/components/SubscriptionWarningModal/SubscriptionWarningModal';
@import 'src/app/components/CookiesMessage/CookiesMessage';
@import 'src/app/components/HomeSliderMobile/HomeSliderMobile';
@import 'src/app/components/NewsletterModal/NewsletterModal';
@import 'src/app/components/PageHeader/PageHeader';
@import 'src/app/components/ProductCard/ProductCard';
@import 'src/app/components/ProductCardSection/ProductCardSection';
@import 'src/app/components/CheckoutCart/CheckoutCart';
@import 'src/app/components/MobileHeaderMenu/MobileHeaderMenu';
@import 'src/app/components/RotativeBanner/RotativeBanner';
@import 'src/app/components/BestSeller/BestSeller';
@import 'src/app/components/RecomendedProducts/RecomendedProducts';
@import 'src/app/components/OurProducts/OurProducts';
@import 'src/app/components/OurProductsCard/OurProductsCard';
@import 'src/app/components/AdvancedMessage/AdvancedMessage';
@import 'src/app/components/CartModal/CartModal.scss';
@import 'src/app/components/LoginModal/LoginModal';
@import 'src/app/components/RegisterModal/RegisterModal';
@import 'src/app/components/OrderBump/OrderBump';
@import 'src/app/components/PasswordModal/PasswordModal';
@import 'src/app/components/RecoveryPasswordModal/RecoveryPasswordModal';
@import 'src/app/components/RegisterPasswordModal/RegisterPasswordModal';
@import 'src/app/components/AdvancedInputPassword/AdvancedInputPassword';
@import 'src/app/components/MobileCart/MobileCart';
@import 'src/app/components/InfoCheckoutMessage/InfoCheckoutMessage';
@import 'src/app/components/TrackerOrderSteps/TrackerOrderSteps';
@import 'src/app/components/FreightOption/FreightOption';
@import 'src/app/components/PageMaintenceIndicator/PageMaintenceIndicator';
@import 'src/app/components/UnauthenticatedBlockingModal/UnauthenticatedBlockingModal';
@import 'src/app/components/BannerHome/BannerHome';
@import 'src/app/components/BannerCreatina/BannerCreatina';
// new cart

@import 'src/app/pages/Checkout/CheckoutNavigationStack';

@import 'src/app/pages/Checkout/CheckoutStepOne/CheckoutStepOne';
@import 'src/app/pages/Checkout/CheckoutStepTwo/CheckoutStepTwo';
@import 'src/app/pages/Checkout/CheckoutStepThree/CheckoutStepThree';
@import 'src/app/pages/Checkout/CheckoutStepFour/CheckoutStepFour';
@import 'src/app/pages/Checkout/CheckoutStepFive/CheckoutStepFive';
@import 'src/app/pages/Checkout/CheckoutPaginated/CheckoutPaginated';

@import 'src/app/components/CheckoutItemCard/CheckoutItemCard';
@import 'src/app/components/CheckoutFloatingBar/CheckoutFloatingBar';
@import 'src/app/components/CouponCard/CouponCard';
@import 'src/app/components/PrescriptionCard/PrescriptionCard';
@import 'src/app/components/WhatsappHelpInfo/WhatsappHelpInfo';
@import 'src/app/components/TopBarPrescription/TopBarPrescription';
@import 'src/app/components/PrescriptionContent/PrescriptionContent';
@import 'src/app/components/PromotionCardCheckout/PromotionCardCheckout';
@import 'src/app/components/ProductCheckoutPayment/ProductCheckoutPayment';
@import 'src/app/components/CheckoutInput/CheckoutInput';

@import 'src/app/pages/Prescription/PrescriptionNavigationStack';
@import 'src/app/pages/Prescription/PrescriptionStep/PrescriptionStep';
@import 'src/app/pages/Prescription/PrescriptionAllow/PrescriptionAllow';
@import 'src/app/pages/PrescriptionSwitch/PrescriptionSwitch/PrescriptionSwitch';
@import 'src/app/pages/Prescription/PrescriptionPayment/PrescriptionPayment';
@import 'src/app/pages/Prescription/PrescriptionFeedback/PrescriptionFeedback';
