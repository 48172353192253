.page-in-maintence-indicator-box {
  height: 50vh;
  min-height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    text-align: center;
    vertical-align: middle;
    font-family: Montserrat;
    font-weight: 700;
    color: $black;
    font-size: 40px;
  }
}