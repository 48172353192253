.clinic-success {

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    background-color: $white;
    padding: 50px 0;

    &__title {
      font-family: 'Montserrat', sans-serif;
      color: $title-clinic;
      font-size: 35px;
      text-align: center;
      font-weight: 800;
      margin-bottom: 0;

      @media all and (max-width: 1100px) {
        font-size: 23px;
      }
    }
  }

  &__footer {
    background-color: $clinic-footer;
    height: 241px;
    width: 100%;
  }

  &__links {
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    flex-direction: column;

    &__box {
      width: 100%;
      padding: 100px 0;
      border-bottom: 1px solid $border-box;
      margin: 0;
      display: flex;
      justify-content: center;

      &__active {
        background-color: $background-clinic;
        width: 100%;
        padding: 0;
        border-bottom: 1px solid $border-box;
        margin: 0;

        &__title {
          display: none;
        }

        &__aux {
          padding: 90px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: 100%;

          &__description {
            margin: 0 100px 0 0;
            width: 35%;

            &__title {
              color: $name-clinic;
              font-size: 30px;
              font-weight: 700;
              margin: 0 0 15px 0;
            }

            &__text {
              color: $name-clinic;
              font-size: 26px;
              font-weight: 400;
              margin: 0 0 20px;
            }

            &__text-green {
              color: $green-success !important;
              color: $name-clinic;
              font-size: 26px;
              font-weight: 500;
            }

            &__button {
              margin: 80px 0 0 0;
              background-color: $background-second-hair-kit;
              width: 358px;
              height: 74px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              color: $white;
              border: none;
              font-weight: 700;
              font-size: 22px;
              cursor: pointer;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &__content {
    background-image: url("../../../assets/jpg/img_medical_consultant.jpg");
    background-size: cover;
    background-position: top center;
    height: 550px;

    @media all and (max-width: 1100px) {
      height: auto;
      text-align: center;
    }

    &__right {
      height: 550px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__title {
        font-family: 'Roboto', sans-serif;
        font-size: 26px;
        font-weight: bold;
        color: #68696B;
        margin-bottom: 30px;

        &__strong {
          color: #68A9D9;
        }
      }

      &__phrase {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 23px;
        line-height: 30px;
        white-space: pre-wrap;
        color: #68696B;

        @media all and (max-width: 1100px) {
          white-space: initial;
        }
      }

      &__button {
        padding-top: 100px;
      }
    }
  }
}
