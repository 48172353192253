.money-back {
    position: relative;

    &__content-one {
        width: 100vw;

        &__img {
            width: 100%;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    &__content-two {
        position: absolute;
        top: 16vh;
        left: 61vw;
        width: 26%;

        @media all and (max-width: 1100px) {
            top: 63vw;
            left: 50vw;
            width: 165px;       
        }

        &__img {
            width: 100%;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    &__content-three {
        width: 100vw;

        &__img {
            width: 100%;
            image-rendering: -webkit-optimize-contrast;
        }

        &__container {
            background-color: #D5DEE7;
            padding: 30px;

            &__one {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                margin-bottom: 30px;
                width: 126px;
                color: #1D6CA3;
            }

            &__two {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                width: 150px;
                margin-bottom: 35px;
                width: 162px;
            }

            &__three {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #000000;

                &__text {
                    margin-bottom: 20px;
                    width: 329px;
                }
            }

            &__four {
                width: 284.79px;
                height: 226px;
                margin: auto;

                img {
                    width: 100%;
                }
            }
        }
    }

    &__content-four {
        width: 100vw;

        &__img {
            width: 100%;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    &__button {
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8vh;

        &__link {
            background-color: #000;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 1.1vw;
            color: #fff;
            border-radius: 50px;
            padding: 1.5% 7.5%;
            margin-left: -12px;

            
            @media all and (max-width: 1100px) {
                font-size: 15px;
                padding: 3% 15.5%;
                margin-left: 0;
            }

            &:hover {
                color: #fff;
            }
        }
    }

    &__faq {
        width: 100vw;

        &__title {
            margin-left: -12px;
            color: #2B2B2B;
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            margin-top: 150px;
            text-align: center;
            font-size: 2vw;
            margin-bottom: 60px;

            @media all and (max-width: 1100px) {
                margin-bottom: 40px;
                font-size: 22px;
                text-align: left;
                margin-left: 31px;
            }
        }

        &__questions {
            display: flex;
            width: 55vw;
            margin: 0 auto;
            justify-content: space-between;

            @media all and (max-width: 1100px) {
                display: block; 
                width: auto; 
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;

            
            @media all and (max-width: 1100px) {
                display: block;  
                width: auto;
                margin-left: 31px;
                margin-right: 31px; 
            }

            &__item {
                position: relative;
                margin-top: 1vw;
                display: flex;
                justify-content: space-between;
                width: 23vw;
                border-bottom: 1px solid #AEAEAE;

                @media all and (max-width: 1100px) {   
                    width: 100%;  
                    margin-bottom: 19px 
                }

                &__content {
                    width: 85%;

                    @media all and (max-width: 1100px) {   
                        width: 90%
                    }

                    &__title {
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        color: #474747;
                        margin-bottom: 1vw;

                        @media all and (max-width: 1100px) {   
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 16px;
                            margin-bottom: 21px;
                        }
                    }

                    &__text {
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        color: #474747;
                        margin-bottom: 1vw;

                        @media all and (max-width: 1100px) {   
                            font-size: 11px;
                            margin-bottom: 21px;
                        }
                    }
                }

                .product-mobile__questions__container__item__icon {
                    position: absolute;
                    cursor: pointer;
                    border: 0.5px solid #474747;
                    border-radius: 50%;
                    width: 1.6vw;
                    height: 1.6vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 2px;
                    left: 90%;

                    @media all and (max-width: 1100px) {   
                        width: 20px;
                        height: 20px;
                        border: none;
                    }

                    & svg {
                        width: 0.7vw;
                        height: 0.7vw;

                        @media all and (max-width: 1100px) {   
                            width: 17px;
                            height: 17px;
                        }

                        path {
                            fill: #474747;

                            @media all and (max-width: 1100px) {   
                                fill: #AEAEAE;
                            }
                        }
                    }
                }
            }
        }
    }
}