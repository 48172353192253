.prescription {
  padding: 20px 0 0;

  &__banner {
    background-color: $banner-prescription;
    background-image: url("../../../assets/png/banner_medical_evaluation.png");
    background-repeat: no-repeat;
    background-attachment: relative;
    background-size: 100% 100%;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;


    &__title {
      padding: 50px 120px;
      color: $white;
      font-size: 50px;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  @media all and (max-width: 1100px) {
    &__banner {
      background-image: url("../../../assets/png/banner_medical_evaluation_responsive.png");
      margin-top: 20%;
      background-size: 100% 100%;

      &__title {
        text-align: center;
      }
    }
  }

  &__numbers {
    width: 100%;
    margin: 65px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;

    &__block {
      display: flex;
      align-items: center;
      justify-content: center;

      &__number {
        color: $number;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        font-weight: 800;
        width: 40px;
        height: 76px;
      }

      &__description {
        color: $text-number;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        margin: 0 0 0 15px;
        width: 76%;
      }
    }
  }

  @media all and (max-width: 1100px) {
    &__numbers {
      width: 94%;
      margin: 40px auto;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 20px;

      &__block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;

        &__number {
          color: $number;
          background-color: $white;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          margin: 0;
          font-size: 45px;
          font-weight: 800;
          width: 60px;
          height: 60px;
        }

        &__description {
          color: $text-number;
          width: 80%;
        }
      }
    }
  }

  &__buttons {
    margin: 80px auto;
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 200px;

    &__active {
      display: none;
    }

    &__login {
      width: 100%;
      height: 60px;
      background-color: $primary-color;
      border: none;
      border-radius: 10px;
      color: $white;
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 15px;
    }

    &__create {
      width: 100%;
      height: 60px;
      background-color: $white;
      border: 1px solid $primary-color;
      border-radius: 10px;
      color: $primary-color;
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  @media all and (max-width: 1100px) {
    &__buttons {
      margin: 50px auto;
      width: 94%;
    }
  }

  &__talk {
    padding: 100px 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    background: linear-gradient(90.16deg, #ecf4f6 -0.9%, rgba(236, 244, 246, 0) 100.77%);

    &__description {
      width: 460px;

      &__title {
        color: $dark-blue;
        font-size: 35px;
        font-weight: 800;
        margin: 0;
      }

      &__text {
        color: $text-talk;
        font-size: 18px;
        font-weight: 300;
        margin: 10px 0;
      }

      &__image {
        width: 70%;
      }
    }

    &__image {
      width: 40%;
    }
  }

  @media all and (max-width: 1100px) {
    &__talk {
      padding: 20px 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 96%;
      margin: 0 0 0 0;
      background: linear-gradient(90.16deg, #ecf4f6 -0.9%, rgba(236, 244, 246, 0) 100.77%);

      @media all and (max-width: 1100px) {
        width: 100%;
      }

      &__description {
        width: 96%;

        &__title {
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-size: 30px;
        }

        &__text {
          text-align: center;
        }

        &__image {
          width: 60%;
        }
      }

      &__image {
        width: 94%;
        margin: 0 0 0 15px;

        @media all and (max-width: 1100px) {
          width: 90%;
          margin: 15px auto;
        }
      }
    }
  }

  &__register {
    display: none;
    width: 600px;
    margin: 0 auto;

    &__active {
      display: block;
      width: 600px;
      margin: 80px auto;
    }

    &__title {
      color: $product-cart;
      font-size: 18px;
      text-align: start;

      &__blue {
        color: $blue-title;
        font-weight: bold;
      }
    }

    &__aux {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__input {
        width: 48% !important;
        margin: 10px 0;
      }

      &__input-large {
        width: 100% !important;
        margin: 10px 0;
      }
    }

    &__input-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 0;
    }

    &__description {
      width: 100%;
      margin: 10px 0 0 0;
      color: $text-number;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
    }
  }

  @media all and (max-width: 1100px) {
    &__register {
      display: none;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &__active {
        display: block;
        width: 100%;
        margin: 80px auto;
      }

      &__aux {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        &__input {
          width: 100% !important;

          .advanced-input {
            width: 100% !important;
          }
        }
      }
    }
  }

  &__login {
    display: none;
    width: 400px;
    margin: 100px auto;

    &__back {
      display: block;
      margin-bottom: 30px;
      cursor: pointer;
    }

    &__active {
      display: block;
      width: 500px;
      margin: 100px auto;

      @media all and (max-width: 1100px) {
        width: 100%;
        padding: 0 15px;
      }
    }

    &__title {
      font-weight: 800;
      color: $blue-title;
    }

    &__aux {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;

      @media all and (max-width: 1100px) {
        justify-content: flex-end;
      }

      &__recover{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        cursor: pointer;

        &--link{
          font-size: 12pt;
          color: $primary-color;
          font-weight: 500;
          cursor: pointer;
        }
      }


      &__input {
        margin: 20px 0 0 0;
      }

      &__link {
        cursor: pointer;
        text-transform: uppercase;
        color: $black;
        font-size: 13px;
        letter-spacing: 0.5px;
        transition: all 0.3s ease-out;
        font-weight: 600;
        margin: 0 20px 0 0;
      }

      &__link:hover {
        text-decoration: underline;
        transition: all 0.3s ease-out;
        margin: 0 20px 0 0;
      }
    }
  }

  @media all and (max-width: 1100px) {
    &__login {
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin: 0px !important;

      &__active {
        display: block;
        width: 100%;
        margin: 50px auto;
      }

      &__title {
        font-weight: 800;
        text-align: center;
      }

      &__aux {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        margin: 15px 0 0 30px;

        @media all and (max-width: 1100px) {
          margin: 15px 0 0;
        }

        &__input {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 0 0 20px;
          width: 90%;

          @media all and (max-width: 1100px) {
            width: 100%;
            margin-left: 0;
          }

          .advanced-input {
            width: 90%;

            @media all and (max-width: 1100px) {
              width: 100%;
            }
          }
        }

        &__link {
          cursor: pointer;
          text-transform: uppercase;
          color: $black;
          font-size: 13px;
          letter-spacing: 0.5px;
          transition: all 0.3s ease-out;
          font-weight: 600;
          margin: 40px 20px 40px 0;
        }

        &__link:hover {
          text-decoration: underline;
          transition: all 0.3s ease-out;
          margin: 0 20px 0 0;
        }

        .advanced-button {
          width: 90%;
          margin: 0 26px 0 0;

          @media all and (max-width: 1100px) {
            width: 100%;
            margin: 0;
          }

          .advanced-button__inner__text {
            width: 100%;
            // height: 53px;
            // background-color: $primary-color;
            border: none;
            border-radius: 10px;
            color: $white;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          .MuiButton-fullWidth {
            box-shadow: none;
            border-radius: 10px;
            margin-bottom: 20px;
          }

          .MuiButton-fullWidth:hover {
            background-color: $primary-color;
            box-shadow: none;
            border-radius: 10px;
          }
        }
      }

      .MuiFormControl-root {
        width: 300px !important;
      }

      .MuiTextField-root {
        width: 300px !important;
      }

      .MuiFormControl-fullWidth {
        width: 300px !important;
      }
    }
  }

  &__welcome {
    display: none;
    width: 600px;
    margin: 100px auto;

    &__active {
      display: block;
      width: 600px;
      margin: 100px auto;
    }

    &__title {
      font-weight: 800;
      margin: 0 0 35px 0;
      text-transform: uppercase;
      font-size: 30px;
      text-align: center;
    }

    &__input-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 0;
    }

    &__description {
      width: 100%;

      color: $text-number;
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      text-align: center;
    }
  }

  @media all and (max-width: 1100px) {
    &__welcome {
      display: none;
      width: 94%;
      margin: 00px;

      &__active {
        display: block;
        width: 90%;
        text-align: center;
        margin: 50px auto;
      }

      &__title {
        font-weight: 800;
        margin: 0 0 35px 0;
        text-transform: uppercase;
        font-size: 30px;
        text-align: center;
      }

      &__input-button {
        width: 100%;
        border: none;
        margin: 20px 0 0 0;

        .MuiButton-fullWidth {
          box-shadow: none;
        }

        .MuiButton-fullWidth:hover {
          background-color: $primary-color;
          box-shadow: none;
        }
      }

      &__description {
        width: 100%;
        margin: 10px 0 0 0;
        color: $text-number;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
      }
    }
  }

  input.MuiInputBase-input.MuiOutlinedInput-input {
    width: 100%;
  }

  .advanced-button {
    width: 100%;
    margin-top: 20px;

    .advanced-button__inner__text {
      width: 100%;
      // height: 53px;
      // background-color: $primary-color;
      border: none;
      color: $white;
      font-size: 16px;
      font-weight: 800;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .MuiButton-fullWidth {
      box-shadow: none;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    .MuiButton-fullWidth:hover {
      background-color: $primary-color;
      box-shadow: none;
      border-radius: 10px;
    }
  }
}

