.checkout-paginated {
  display: flex;
  align-items: center;
  background: white;
  position: sticky;
  top: 69px;
  z-index: 2;

  @media all and (max-width: 1100px) {
    top: 54px;
  }

  &__checkout {
    width: 640px;
    text-align: center;

    &__text {
      margin: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #7d7d7d;
      font-family: 'PrimeformProRegular';

      @media all and (max-width: 1100px) {
        font-size: 13px;
      }

      &--active {
        color: #000000;
        font-weight: 900;
        font-family: 'PrimeformProHeavy';
      }
    }

    &--active {
      background: #94ffcc;
    }
  }

  &__delivery {
    width: 640px;
    text-align: center;

    &__text {
      margin: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #7d7d7d;
      font-family: 'PrimeformProRegular';


      @media all and (max-width: 1100px) {
        font-size: 13px;
      }

      &--active {
        color: #000000;
        font-weight: 900;
        font-family: 'PrimeformProHeavy';
      }
    }

    &--active {
      background: #94ffcc;
    }
  }

  &__payment {
    width: 640px;
    text-align: center;

    &__text {
      margin: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #7d7d7d;
      font-family: 'PrimeformProRegular';

      @media all and (max-width: 1100px) {
        font-size: 13px;
      }

      &--active {
        color: #000000;
        font-weight: 900;
        font-family: 'PrimeformProHeavy';
      }
    }

    &--active {
      background: #94ffcc;
    }
  }
}
