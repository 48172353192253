.radio_freight_option {
  font-weight: 400;
  font-size: 17px;
  white-space: normal;

  &__free {
    color: $green-success;
  }

  &__unavailable {
    color: $red;
  }
}