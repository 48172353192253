$primary-color: #3B5C7A;
$border-color: #F2F2F2;
$gray-text: #919191;
$gray-bg: #E5EBEB;
$dark-blue: #0C1931;
$white: #ffffff;
$black: #000000;
$gray-item: #F8F8F8;
$dark-gray: #DEDEDE;
$light-blue: #77B8E1;
$light-gray: #F8F8F8;
$red : #FF0000;
$text-blue: #1045AA;
$text-green: #20E036;
$text-purple: #8534C5;
$text-yellow: #B5A93E;
$dark-red: #B53E3E;
$pink-red: #ffe1e1;
$primary-profile: #F7F7F7;
$background-gray: #F8F8F8;
$accordion-blue: #E8F5FF;
$dark-border: #474747;
$button-form: #8DAABC;
$title-gray: #424242;
$background-first: #F2F5F6;
$background-second: #EFF0F1;
$title-about: #3B5C7A;
$text-product: #474747;
$dark-blue: #0C1931;
$title-add: #2B2B2B;
$color-ml: #6B6B6B;
$description-hair-kit: #343434;
$background-first-hair-kit: #7C93A2;
$background-second-hair-kit:#506B7E;
$background-third-hair-kit:#355572;
$border-cart: #8DAABC;
$header-cart: #DCE6EF;
$product-cart: #626262;
$old-value: #8D8D8D;
$border-input: #E7E7E7;
$blue: #336ACE;
$background-item: #F2F2F2;
$backgroun-begin: #C4C4C4;
$background-work: #F6F6F6;
$background-easy: #496B80;
$border-ask: #8DAABC;
$title-ask: #575757;
$background-prescription: #E8F0EE;
$banner: #C8C8C8;
$gold: #FFE9AF;
$gray-cart: #DEDEDE;
$gray-product: #626262;
$gray-atention: #818181;
$border-request: #DDDDDD;
$title-request: #7D7D7D;
$link-request: #8DAABC;
$background-approved: #EDDED0;
$background-block: #F1F3F4;
$banner-prescription: #375C7E;
$number: #98B1BE;
$text-number: #6F6F6F;
$text-talk: #4A4A4A;
$border-input: #8C8C8C;
$cart-button: #3E5976;
$text-cart: #848484;
$text-submenu: #333333;
$title-clinic: #646464;
$clinic-footer: #74B1E0;
$border-box:#A3A3A3;
$title-box: #E3E3E3;
$background-clinic: #EDEDED;
$title-done: #65C4FF;
$name-clinic: #68696B;
$step-clinic: #F6F6F6;
$background-checkbox: #E7F2FA;
$text-input: #757575;
$text-italic: #8F8F8F;
$background-image: #EAEAEA;
$button-payment: #136395;
$green-success: #10BD74;
$text-register: #797979;
$title-profile: #7B9CB0;
$button-password: #909090;
$backgroun-password: #F1F1F1;
$button-recurrence: #12213C;
$blue-banner: #223D4E;
$gray-chat-bg: #F2F2F2;
$gray-chat-msg-bg: #EBEBEB;
$background-checkbox: #e0efff;
$blog-title: #ADADAD;
$border-blog: #B9B9B9;
$article: #373737;
$product-gray: #E0E5EA;
$feature-section: #DCDFDE;
$input-radio: #496D8E;
$background-easy: #F3F8FB;
$background-effect: rgba(249, 244, 232, 0.5);
$button-next: #CECECE;
$background-pic: #09A0FD;
$button-footer: #102940;
$button-hair-kit: #1D6EA3;
$more-product-hair-kit: #656565;
$title-begin: #484848;
$default-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$hover-shadow: 0px 8px 12px rgba(0, 0, 0, 0.36);
$mobile-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
$button-color: #355572;
$price-color: #6DA7CB;
$feature-bg: #D8D9DE;
$blue-title: #355572;
$banner-appointment: #F0F0F0;

$cute-blue: #6DA7CB;
$purple-light: #B4AAD0;
$light-green: #4C9C9C;
$wild-green: #296F6F;