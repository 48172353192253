.best-seller {
  margin: 0 auto;
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1155px;

  @media all and (max-width: 1100px) {
    justify-content: space-around;
  }

  &__item {
    position: relative;

    @media all and (max-width: 1100px) {
      width: 45%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }


    &__sold-off {
      position: absolute;
      top: 68px;
      left: 18px;
      background: red;
      border-radius: 50px;
      padding: 4px 12px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      color: #FFF;
      font-size: 8px;

      @media all and (max-width: 1100px) {
        font-size: 6px;
        top: 43px;
        left: 15px;
        padding: 2px 8px;
      }
    }

    &__img-container {
      position: relative;
      cursor: pointer;
      margin-bottom: 0.5rem;

      &__label {
        position: absolute;
        top: 75px;
        left: 26px;
        background: red;
        padding: 0px 6px;
        font-size: 9px;
        font-family: 'Montserrat';
        color: #FFF;
        border-radius: 10px;

        @media all and (max-width: 1100px) {
          top: 45px;
          left: 15px;
        }
      }

      &__img {
        height: 500px;
        width: 276px;
        image-rendering: -webkit-optimize-contrast;

        @media all and (max-width: 1100px) {
          width: 100%;
          height: auto;
          margin-bottom: 44px;
        }
      }
    }

    &__ghost-button {
      width: 280px;
      height: 43px;
      cursor: pointer;
      top: 362px;
      left: 6px;
      position: absolute;

      @media all and (max-width: 1100px) {
        width: 100%;
        height: 9%;
        cursor: pointer;
        top: 48%;
        left: 1px;
        position: absolute;
      }
    }

    span {
      text-align: center;
      font-weight: 700;
      font-family: 'PrimeformPro';
      padding: 0.5rem;
      border-radius: 0.5rem;
      background: red;
      color: #FFF;

      @media all and (max-width: 1100px) {
        position: relative;
        bottom: 2.813rem;
      }
    }
  }
}

.best-seller-header {
  margin-top: 9rem;
  margin-bottom: 3rem;
  text-align: center;

  @media all and (max-width: 600px) {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 43px;

    @media all and (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}