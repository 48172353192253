.promotion-card-checkout {
  background: linear-gradient(180deg, #000000 0%, #3d3b3b 100%);

  width: 35.688rem;
  height: 14.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 1100px) {
    width: auto;
    height: 11.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__title {
    font-weight: 700;
    font-size: 1.375em;
    text-align: center;
    font-family: 'PrimeformPro';

    @media all and (max-width: 1100px) {
      font-size: 0.875em;
    }

    &--tadalaspray {
      color: #aaffd6;
    }

    &--sleep-gummy {
      color: #de7fff;
    }

    &--vita-gummy {
      color: #f4b411;
    }

    &--shampoo,
    &--minoxidil,
    &--finasterida {
      color: #aadefe;
    }

    &--tonico {
      color: #00a3ff;
    }

    &--anti-aging {
      color: #f48b11;
    }

    &--creatina {
      color: #fff;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.708em;
    text-align: center;
    color: #ffffff;
    padding: 0.1rem 2rem;
    font-family: 'PrimeformProRegular';

    @media all and (max-width: 1100px) {
      font-family: 'PrimeformPro';
      font-weight: 900;
      font-size: 0.75em;
      line-height: 1.025em;
      text-align: inherit;
      color: #ffffff;
      margin-top: 0.5rem;
    }
  }

  &__button {
    background: #ffffff;
    text-align: center;
    margin: 8px 60px;
    cursor: pointer;
    border-radius: 3.125rem;
    width: 26.438rem;
    height: 3.5rem;
    border: none;

    @media all and (max-width: 1100px) {
      width: 15.688rem;
      height: 2.813rem;
    }

    &--tadalaspray {
      box-shadow: 3px 3px 20px #aaffd6;
    }

    &--sleep-gummy {
      box-shadow: 3px 3px 20px #de7fff;
    }

    &--vita-gummy {
      box-shadow: 3px 3px 20px #f4b411;
    }

    &--shampoo,
    &--minoxidil,
    &--finasterida {
      box-shadow: 3px 3px 20px #aadefe;
    }

    &--tonico {
      box-shadow: 3px 3px 20px #00a3ff;
    }

    &--anti-aging {
      box-shadow: 3px 3px 20px #f48b11;
    }

    &--creatina {
      box-shadow: 3px 3px 20px #fff;
    }

    &__text {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000000;
      padding: 9px 0;
      font-family: 'PrimeformPro';

      @media all and (max-width: 1100px) {
        font-size: 0.813em;
        line-height: 1.103em;
      }
    }
  }

  &__price {
    padding-left: 5px;
    letter-spacing: -1px;
    font-family: 'PrimeformProHeavy';
  }
}

.promotion-card-checkout-mobile {
  &__background {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;

    &--tadalaspray {
      background-color: #000000;
    }

    &--sleep-gummy {
      background-color: #4c0864;
    }

    &--vita-gummy {
      background-color: #f4b411;
    }

    &--shampoo,
    &--minoxidil,
    &--finasterida {
      background-color: #aadefe;
    }

    &--tonico {
      background-color: #00a3ff;
    }

    &--anti-aging {
      background-color: #f48b11;
    }

    &--creatina {
      background-color: #FFF;
    }
  }

  &__title {
    color: #ffffff;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    padding: 3px 0px;
    display: flex;
    justify-content: center;
    font-family: 'PrimeformPro';
  }
}
