.page-content-header {
  @media all and (max-width: 1100px) {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-color: white;
    top: 0;
  }

  &__inner {
    &__logo {
      text-align: center;
      padding: 60px 0;

      @media screen and (max-width: 1100px) {
        text-align: left;
        padding: 15px 0 30px;
      }

      &__img {
        @media all and (max-width: 1100px) {
          width: 45px !important;
          position: relative;
          top: 7px;
        }
      }
    }

    &__menu {
      display: flex;
      justify-content: space-between;

      &__inner {
        padding: 0;
        margin: 0;

        @media all and (max-width: 1100px) {
          &__mobile {
            width: 100%;

            &__right {
              padding-top: 30px !important;

              &__item {
                padding-bottom: 10px;
              }
            }

            &__soon {
              left: 0;
              right: initial;
              text-align: center;
            }

            &__wrapper {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            }

            &__close {
              position: absolute;
              top: 15px;
              right: 15px;

              &__button {
                font-size: 15pt;
                background-color: transparent;
                border: none;
              }
            }

            &__logo {
              text-align: left;
              padding: 15px 0 30px;

              &__img {
                @media all and (max-width: 1100px) {
                  width: 45px !important;
                  position: relative;
                  top: 7px;
                }
              }
            }
          }
        }

        &__item {
          display: inline-block;
          list-style: none;
          margin-right: 45px;
          position: relative;

          &__soon {
            font-size: 10pt;
            text-transform: uppercase;
            position: absolute;
            top: -18px;
            right: 35%;
            background-color: $button-form;
            color: $white;
            padding: 0 12px;
            border-radius: 3px;

            @media all and (max-width: 1100px) {
              margin-left: 10px;
              position: initial;
            }

            &__inner {
              position: relative;
              top: 1px;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &__link {
            font-size: 15px;
            text-transform: uppercase;
            color: $black;
            letter-spacing: 2.5px;
            border-bottom: 2px solid transparent;

            @media all and (min-width: 1100px) and (max-width: 1680px) {
              font-size: 13px;
              letter-spacing: 2px;
            }

            &:hover {
              color: $primary-color;
              border-bottom: 2px solid $primary-color;
              padding: 0;
            }
          }
        }

        @media screen and (max-width: 1100px) {
          &__item {
            margin: 23px 0 0 0;
          }
        }

        @media screen and (max-width: 1560px) {
          &__item {
            margin-right: 15px;
          }
        }
      }

      &__right {
        padding: 0;
        display: flex;
        justify-content: flex-end;
        margin: 0;
        position: relative;

        &__item {
          display: inline-block;
          list-style: none;
          position: relative;
          margin: 0 15px 0 10px;

          &__soon {
            font-size: 8.7pt;
            text-transform: uppercase;
            position: absolute;
            top: -18px;
            right: 0;
            background-color: $dark-blue;
            color: $white;
            padding: 0 5px;
          }

          &:last-child {
            margin-right: 0;
          }

          &__link {
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            color: $black;
            letter-spacing: 2px;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            padding: 0 0 3px 0;

            @media all and (min-width: 1100px) and (max-width: 1680px) {
              font-size: 13px;
              letter-spacing: 2px;
            }

            &__submenu {
              display: none;
            }

            &:hover {
              color: $primary-color;
              border-bottom: 2px solid $primary-color;

              &__submenu {
                display: block;
              }
            }

            &__icon {
              position: relative;

              &__img {
                width: 20px;
                height: 20px;
                margin-right: 6px;
              }
            }

            &__title {
              position: relative;
              top: 1px;
              border-bottom: 2px solid transparent;

              @media all and (max-width: 1100px) {
                font-size: 12px;
              }
            }
          }
        }

        @media screen and (max-width: 995px) {
          &__item {
            display: inline-block;
            list-style: none;
            position: relative;
            margin: 0 12px 20px 0;
          }
        }

        &__total-cart {
          display: none;

          &__active {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $dark-blue;
            color: $white;
            font-size: 12px;
            font-weight: 700;
            position: absolute;
            right: -5px;
            top: -15px;
            text-align: center;
            padding: 1px 0 0 0;
          }
        }

        @media screen and (max-width: 1560px) {
          &__total-cart {
            &__active {
              right: 7%;
            }
          }
        }

        @media screen and (max-width: 1280px) {
          &__total-cart {
            &__active {
              right: 9%;
              bottom: 21%;
            }
          }
        }

        @media screen and (max-width: 1368px) {
          &__total-cart {
            &__active {
              bottom: 11%;
              right: 7%;
            }
          }
        }

        @media screen and (max-width: 1368px) {
          &__total-cart {
            &__active {
              bottom: 11%;
              right: 7%;
            }
          }
        }

        @media screen and (max-width: 1100px) {
          &__total-cart {
            &__active {
              right: 4%;
            }
          }
        }
      }

      @media screen and (max-width: 1560px) {
        &__right {
          width: 29%;
        }
      }

      @media screen and (max-width: 1300px) {
        &__right {
          width: 25%;
        }
      }

      &__active {
        flex-direction: column;
        display: none;
      }

      @media screen and (max-width: 1100px) {
        &__active {
          flex-direction: column;
          display: block;
          position: absolute;
          top: 26%;
          right: 0;

          &__cart {
            background-color: transparent;
            border: none;
            outline: 0 !important;
            display: inline-block;
            margin-right: 15px;
            position: relative;

            &--item {
              margin-right: 6px;
            }

            &__img {
              position: relative;
              left: -3px;
            }

            &__amount {
              background-color: black;
              color: white;
              position: absolute;
              top: -7px;
              right: -7px;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              text-align: center;

              &__inner {
                position: relative;
                top: 1px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 995px) {
      &__menu {
        display: none;
      }
    }

    &__menu-hamburguer {
      display: none;
    }

    @media screen and (max-width: 1100px) {
      &__menu-hamburguer {
        display: block;
        background-color: transparent;
        padding: 11px 0 4px 8px;
        border-radius: 10px;
        margin-top: -5px;

        & > img {
          width: 80%;
        }
      }
    }
  }

  &__popover {
    box-shadow: $default-shadow;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $white !important;
}

/*
.ant-popover {
  width: 0px;
}
*/

.ant-popover-arrow {
  display: none;
}

.makeStyles-list-35 {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MuiList-padding {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.little {
  padding: 0 !important;
  width: 149px;
  height: 288px;
  border: none !important;

  &__aux {
    max-width: 550px;
    width: 550px;
    padding: 0;
    position: relative;

    &__block {
      max-width: 500px;
      width: 500px;

      &__checkout {
        margin: 0;

        &__white {
          border: none;
          padding: 15px;
          width: 649px;
          height: 288px;
          margin-left: 46px;
          border-radius: 50px;

          &__buying {
            text-align: center;
            margin: 132px 105px;
            color: $cart-button;
            font-size: 15px;
            font-weight: 400;
            width: 438px;
            line-height: 24px;

            @media all and (max-width: 1100px) {
              padding: 30px 0;

              h1 {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.little-cart {
  padding: 0 !important;
  width: 633px;
  border: none !important;

  &__products {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    &__cart {
      padding: 0;
      box-sizing: border-box;
      background-color: $white;
      height: auto;
      word-wrap: break-word !important;

      &__body {
        padding: 33px;
        width: 50px;
        word-wrap: break-word !important;

        &__atention {
          padding: 0;

          &__aux {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            word-wrap: break-word !important;

            &__description {
              color: $blue;
              font-size: 16px;
              font-weight: 500;
              margin: 0 0 0 10px;
              text-transform: uppercase;
              line-height: 50px;
            }
          }

          &__aux-input {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 15px 0 0 0;

            input[type='radio'] + label {
              width: 23px;
              height: 31px;
              border-radius: 5px;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $text-input;
              font-size: 22px;
              font-weight: 400;
              background-color: $white;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              margin: 0 30px 0 0;
              cursor: pointer;
              transition: all 0.3s ease-out;
              text-align: center;
              padding: 0 20px;

              img {
                display: none;
              }
            }

            input[type='radio']:checked + label {
              font-weight: bold;
              width: 23px;
              height: 31px;
              border-radius: 5px;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $text-input;
              font-size: 22px;
              font-weight: 700;
              background-color: $input-radio;
              margin: 0 30px 0 0;
              transition: all 0.3s ease-out;
              text-align: center;
              padding: 0 20px;
              position: relative;

              img {
                right: -20%;
                top: -20%;
                height: 32px;
                position: absolute;
                display: block;
              }
            }

            input[type='radio'] {
              opacity: 0;
            }

            &__text {
              color: $blue;
              font-size: 16px;
              font-weight: 400;
              margin: 0 0 -23px 0;
              line-height: 50px;
            }
          }

          &__button {
            height: 58px;
            margin: 40px 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            background-color: $primary-color;
            font-size: 18px;
            font-weight: 700;
            box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
            border-radius: 10px;
          }

          &__description {
            color: $gray-text;
            font-size: 12px;
            font-weight: 400;
            margin: 15px 0;
          }
        }
      }

      &__header {
        background-color: $header-cart;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 100%;

        input[type='checkbox'] + label {
          font-weight: bold;
          width: 23px;
          height: 31px;
          border-radius: 5px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 400;
          background-color: $white;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          margin: -24px 5px 0 0;
          cursor: pointer;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;

          img {
            display: none;
          }
        }

        input[type='checkbox']:checked + label {
          font-weight: bold;
          width: 23px;
          height: 31px;
          border-radius: 5px;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-input;
          font-size: 22px;
          font-weight: 700;
          background-color: $input-radio;
          margin: -24px 5px 0 0;
          transition: all 0.3s ease-out;
          text-align: center;
          padding: 0 20px;
          position: relative;

          img {
            right: -20%;
            top: -20%;
            height: 32px;
            position: absolute;
            display: block;
          }
        }

        input[type='checkbox'] {
          opacity: 0;
        }

        &__text {
          color: $dark-border;
          font-size: 16px;
          margin: 0 0 0 10px;
          font-weight: 500;
        }
      }
    }
  }

  .ant-dropdown-open:hover {
    background-color: $white;
  }

  &__aux {
    max-width: 550px;
    width: 550px;
    padding: 0;
    position: relative;

    &__block {
      max-width: 500px;
      width: 500px;

      &__checkout {
        margin: 0;

        &__blue {
          max-width: 100%;
          width: 100%;
          background-color: $header-cart;
          border: none;
          border-radius: 10px;
          padding: 15px;
          position: relative;

          &__title {
            display: flex;
            align-items: flex-start;
            max-width: 90%;
            width: 90%;

            &__checkbox {
              width: 20px;
              height: 20px;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;
              border: none;
              margin: 2px 10px 0 0;
              background-color: $white;
            }

            &__text {
              color: $text-product;
              font-size: 16px;
              font-weight: 500;
              max-width: 150px;
              width: 150px;
              word-wrap: break-word;
              margin: 0;
            }
          }

          &__link {
            color: $text-product;
            text-decoration: underline;
            font-size: 15px;
            font-weight: 600;
            margin: 5px 0 0 30px;
          }
        }

        &__white {
          border: none;
          padding: 15px;
          margin-left: 46px;

          .amount-selection__actions {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 132px;
            height: 42px;
            border-radius: 200px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 1px 0 0 0;
            background: $white;
          }

          button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-containedSizeLarge.MuiButton-sizeLarge {
            background: transparent;
            color: black;
            width: 0;
            border: none;
            box-shadow: none;
            margin: -9px;
          }

          .MuiButton-containedSizeLarge {
            padding: 0;
            font-size: 22px;
            font-weight: 700;
          }

          input.amount-selection__amount__value__input {
            border: none;
            padding: 0 9px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          &__product {
            border-bottom: 2px solid $gray-cart;
            display: flex;
            justify-content: space-between;

            &__aux {
              padding: 30px 0;
              display: flex;

              &__first {
                display: flex;

                &__img {
                  height: 100px;
                  margin-right: 20px;
                  border-radius: 10px;

                  @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance: none) and
                      (stroke-color: transparent) {
                      width: auto;
                      max-height: 70px;
                    }
                  }
                }

                &__aux {
                  margin: 0 0 0 15px;

                  &__text {
                    font-size: 20px;
                    font-weight: 300;
                    color: $black;
                    margin: 0 0 30px;
                    line-height: 20px;
                    font-weight: bold;
                  }
                }
              }

              &__second {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                padding: 30px 0;

                &__values {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  width: 250px;

                  &__old-value {
                    font-size: 13px;
                    font-weight: 500;
                    color: $dark-border;
                    margin: 0 15px 0 0;
                    text-decoration: line-through;
                  }

                  &__new-value {
                    font-size: 20px;
                    font-weight: 400;
                    color: $black;
                    margin: 15px 10px;
                  }
                }

                &__remove {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border: 1px solid $gray-product;
                  border-radius: 17px;
                  width: 108px;
                  height: 33px;
                  padding: 0 15px;
                  font-size: 12px;
                  font-weight: 700;
                  color: $gray-product;
                  background-color: white;
                  cursor: pointer;
                  outline: 0 !important;
                }
              }
            }
          }

          &__total {
            border-bottom: 1px solid $gray-cart;
            padding-top: 30px;

            &__aux {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              padding-bottom: 15px;

              &__text {
                font-size: 25px;
                font-weight: 400;
                color: $dark-border;
              }

              &__text-value {
                font-size: 25px;
                font-weight: 400;
                color: $dark-border;
              }

              &__text-value-item {
                margin-top: -10px;
                margin-bottom: 48px;
                margin-left: 450px;
                font-size: 16px;
                font-weight: 400;
                width: 100%;
                color: $dark-border;
              }
            }
          }

          &__buying {
            text-align: center;
            margin: 132px 105px;
            color: $cart-button;
            font-size: 15px;
            font-weight: 400;
            width: 438px;
            line-height: 24px;

            @media all and (max-width: 1100px) {
              padding: 30px 0;

              h1 {
                font-size: 20px;
              }
            }
          }

          &__button {
            width: 100%;
            text-align: center;
            margin: 0 auto;
            background-color: $cart-button;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 15px;
            height: 50px;
            text-transform: uppercase;
            border-radius: 10px;
          }

          &__bottom {
            &__button {
              text-align: center;
              background: #025175;
              box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29);
              border-radius: 50px;
              color: #fff;
              padding: 4%;
              font-family: Montserrat;
              font-style: normal;
              font-weight: bold;
              font-size: 1.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border: none;
              width: 100%;
            }
          }

          &__obs {
            color: $text-cart;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            margin: 30px 0 15px 0;
          }
        }
      }
    }
  }
}

.little-cart-form {
  width: 600px;
  border-right: none !important;
}

.submenu-product {
  background-color: $white;
  padding: 25px;
  position: relative;
  width: 550px;
  height: 330px;

  &__aux {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      &__img {
        width: 240px;
        height: auto;
      }

      &__link {
        color: $text-submenu;
        text-decoration: underline;
        font-size: 17px;
        margin: 30px 0;
        font-weight: 700;
        letter-spacing: 0.08em;
      }

      &__link-products {
        color: $text-submenu;
        font-size: 14px;
        margin: 0 0 42px 40px;
        font-weight: 700;
        letter-spacing: 0.08em;
        transition: all 0.5s ease-out;
        border: none;
      }

      &__link-products:hover {
        text-decoration: underline;
        transition: all 0.5s ease-out;
        border: none;
      }
    }
  }
}

.little-cart-shadow {
  border-radius: 6px;
  box-shadow: $default-shadow;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $white;
}

.ant-menu-item a::before {
  display: none;
}

.none {
  display: none !important;
}
