.carousel {
  margin-bottom: 4rem;
}

.slick-dots li button:before {
  color: #000000 !important;
  font-size: 10px !important;
}

.slick-dots li.slick-active button:before {
  color: #000000 !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: transparent;
}

.ant-carousel .slick-dots {
  top: 31vw;
  margin-right: 0 !important;
  margin-left: 0 !important;
  z-index: 0;

  @media all and (max-width: 1100px) {
    top: 96.5%;
  }
}