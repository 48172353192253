.unauthenticated-blocking-modal-box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  filter: none;
  margin: 0 5px;

  .modal-content {
    background-color: $white;
    position: fixed;
    margin: auto;
    align-self: center;
    margin-top: 30vh;
    padding: 40px 30px;
    border-radius: 5px;
    box-shadow: 0 3px 10px $black;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    h2 {
      font-size: 22px;
      margin: 0;
      padding: 0;
      max-width: 500px;
      text-align: center;

      font-family: 'Montserrat';
    }

    .buttons-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      width: 100%;

      button {
        font-family: 'Montserrat';
        cursor: pointer;
        flex: 1;

        &:hover {
          opacity: 0.8;
        }
      }

      .register-button {
        background-color: $dark-blue;
        color: $white;
        font-size: 20px;
        padding: 13px 5px;
        border-radius: 50px;
      }

      .cancel-button {
        background-color: rgba(0,0,0,0);
        color: $black;
        font-size: 20px;
        padding: 13px 5px;
        border-radius: 50px;
      }
    }
  }
}