.register__profile {
  background-color: #F2F2F2;
  
  &__prescription-container {
    width: 49%;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &__box {
      border: 1px solid #000000;
      border-radius: 5px;
      padding: 30px 0 57px 31px;
      margin-bottom: 31px;
      display: flex;

      @media all and (max-width: 1100px) {
        padding: 17px 0 25px 10px;
      }

      &__active {
        font-size: 1.5vh;
        font-weight: 700;
        color: #ffffff;
        font-family: 'Montserrat';
        background-color: #61BE91;
        border-radius: 5px;
        width: 80px;
        text-align: center;
        margin-top: 1vh;
        text-transform: uppercase;
        height: 2.5vh;

        @media all and (max-width: 1100px) {
          font-size: 11px;
          width: 51px;
          height: 17px;
          line-height: 16px;
          margin-right: 15px;
        }
      }

      &__disabled {
        font-size: 1.5vh;
        font-weight: 700;
        color: #ffffff;
        font-family: 'Montserrat';
        background-color: #C4C4C4;
        border-radius: 5px;
        width: 110px;
        text-align: center;
        margin-top: 1vh;
        text-transform: uppercase;
        height: 2.5vh;
        margin-right: 15px;

        @media all and (max-width: 1100px) {
          font-size: 11px;
          width: 69px;
          height: 17px;
          line-height: 16px;
          margin-right: 15px;
        }
      }
    }

    &__link{
      font-size: 1.7vh;
      color: #1d70a3;
      display: flex;
      line-height: 22px;
      position: absolute;
      font-weight: 600;
      font-family: 'Montserrat';
      padding-top: 22px;
      text-decoration: underline;

      @media all and (max-width: 1100px) {
        font-size: 1.5vh;
        line-height: 16px;
        padding-top: 15px;
        position: relative;
      }
    }


    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      font-family: 'Montserrat';
      color: #000000;
      margin-bottom: 5px;

      @media all and (max-width: 1100px) {
        font-size: 1.6vh;
        line-height: 15px;
      }
    }

    &__subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      font-family: 'Montserrat';
      color: #0c1931;

      @media all and (max-width: 1100px) {
        font-size: 1.6vh;
        line-height: 15px;
      }
    }

    &__no-prescription{
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      font-family: 'Montserrat';
      color: #0c1931;
      border-top: 1px solid #868686;
      padding-top: 35px;

      @media all and (max-width: 1100px) {
        font-size: 15px;
      }
    }
  }


  .ant-collapse.ant-collapse-icon-position-left {
    width: 100%;
    border: none;
    background: transparent;
    margin-bottom: 35px;
  }

  .ant-collapse-item {
    background-color: $white;
    border-radius: 25px;
  }

  .ant-collapse-content-box {
    background-color: $white;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 7px;
    padding-top: 0;
    border-radius: 25px !important;

    @media all and (max-width: 1100px) {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 27px;
    }
  }

  .ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 25px;
  }

  .ant-collapse>.ant-collapse-item {
    border-radius: 25px;
    border: none;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-family: 'Montserrat';
    font-size: 28px;
    font-weight: 700;
    padding: 3vh 40px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      font-weight: bold;
      padding: 3vh 15px;
    }
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    top: 3.5vh;
    right: 30px;
    left: initial;
    font-size: 15pt;

    @media all and (max-width: 1100px) {
      right: 18px;
      font-size: 10px;
    }
  }

  .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      padding: 0 !important;
    }
  }
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-left: 15px;
}