.register__profile {
  padding-bottom: 530px;
  
  &__drop {
    display: flex;
    justify-content: space-between;
    padding: 82px 0px 0px;

    @media all and (max-width: 1100px) {
      width: 100%;
      display: inline-block;
      padding: 33px 0px 0px;
    }
  }

  &__dropdown {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 1100px) {
      width: 100%;
      display: inline-block;
    }
  }
}