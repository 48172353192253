.prescription-allow {

  &__header {
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.34);
    background-color: white;
    padding: 35px 0;

    &__title {
      font-family: Poppins, sans-serif;
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      color: #757575;
    }
  }

  &__body {
    background-color: #F0F0F0;
    min-height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @media all and (max-width: 1100px) {
      flex-direction: column;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 100px 0 100px;
      border-color: #f0f0f0 transparent transparent transparent;
      position: absolute;
      right: 0;
      bottom: -30px;
      left: 0;
      margin: 0 auto;
    }

    &__img {
      max-width: 100%;
    }

    &__left {
      flex: 1 0 0;
    }

    &__right {
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 0;

      @media all and (max-width: 1100px) {
        text-align: center;
        padding: 0 15px;
      }

      &__inner {
        font-family: Roboto, sans-serif;
        color: #68696B;

        &__name {
          font-size: 30px;
          font-weight: bold;
        }

        &__title {
          font-size: 23px;
          padding-bottom: 15px;

          @media all and (max-width: 1100px) {
            font-size: 20px;
          }
        }

        &__items {

          &__single {
            font-size: 17px;
            padding-bottom: 15px;

            @media all and (max-width: 1100px) {
              font-size: 15px;
            }

            &__link {
              color: #68696B;
              text-decoration: underline;

              &:hover {
                color: #68696B;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }


  &__footer {
    padding-top: 30px;

    &__button {
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      border: none;
      padding: 15px 30px;
      align-items: center;
      cursor: pointer;

      @media all and (max-width: 1100px) {
        margin: auto;
      }

      &__title {
        font-family: Roboto, sans-serif;
        font-weight: bold;
        font-size: 15px;
        color: #fff;
      }

      &__icon {
        padding-left: 30px;
      }
    }
  }

  &__actions {
    font-family: Poppins, sans-serif;
    height: 240px;
    color: #E3E3E3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 35px;
    border-bottom: 1px solid #A3A3A3;
  }
}
