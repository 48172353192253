.request-profile {
  padding: 30px;
  padding-top: 89px;
  display: flex;
  height: auto;
  background-color: #F2F2F2;
  padding-bottom: 700px;

  @media all and (max-width: 1100px) {
    padding-top: 31px;
    padding-left: 0;
    padding-bottom: 500px;
    padding-right: 0;
  }

  &__title {
    color: #000000;
    font-weight: 700;
    font-size: 28px;
    font-family: 'MontSerrat';
    text-transform: uppercase;
    margin-bottom: 21px;
    margin-left: 20.5vw;

    @media all and (max-width: 1100px) {
      font-size: 15px;
      margin-bottom: 9px;
      margin-left: 0;
    }
  }

  &__item {
    margin: 0 auto;
    width: 42vw;
    background-color: #ffffff;
    border-radius: 25px;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &__box {
      padding: 20px 45px 0px 45px;

      @media all and (max-width: 1100px) {
        padding: 10px 13px 0px 23px;
      }

      &__text {
        font-weight: 400;
        font-size: 24px;
        font-family: 'MontSerrat';
        color: #000000;
        align-items: center;
        line-height: 29px;

        @media all and (max-width: 1100px) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }

  &__aux {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;


    @media all and (max-width: 1100px) {
      flex-direction: column;
      align-items: initial;
    }

    &__requests {
      @media all and (max-width: 1100px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      &__aux {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin: 0 140px 0 0;
        height: auto;
        border-radius: 10px;
        padding: 15px 30px;

        @media all and (max-width: 1100px) {
          width: 100%;
          padding: 8px 0;
        }
      }

      &__title {
        font-size: 1.7vh;
        color: #7d7d7d;
        font-weight: 600;
        line-height: 20px;
        font-family: 'Montserrat';

        @media all and (max-width: 1100px) {
          line-height: 16px;
          font-size: 1.5vh;
          margin-bottom: 0px;
        }
      }

      &__text {
        margin: 0;
        font-size: 1.7vh;
        font-weight: 400;
        color: #0c1931;
        line-height: 22px;
        font-family: 'Montserrat';
        width: 10vw;

        @media all and (max-width: 1100px) {
          line-height: 16px;
          font-size: 1.5vh;
          width: 20vh;
        }
      }

      &__status {
        margin: 0;
        font-size: 2vh;
        line-height: 29px;
        font-family: 'Montserrat';
        padding-bottom: 13px;

        @media all and (max-width: 1100px) {
          line-height: 18px;
          font-size: 1.7vh;
        }

        &--cancelled {
          color: #d70202;
          font-weight: 600;
        }

        &--done {
          color: #46C057;
          font-weight: 600;
        }

        &--shipped {
          color: #F39B17;
          font-weight: 600;
        }

        &--in-analysis {
          color: #1d70a3;
          font-weight: 600;
        }

        &--created {
          color: #cccc00;
          font-weight: 600;
        }

        &--processing-payment {
          color: #cccc00;
          font-weight: 600;
        }

        &--paid {
          color: #119911;
          font-weight: 600;
        }

      }

      &__product {
        font-family: 'MontSerrat';
        line-height: 15px;
        font-size: 18px;
        color: #0c1931;
        font-weight: 400;

        @media all and (max-width: 1100px) {
          line-height: 15px;
          font-size: 13px;
        }
      }

      &__line {
        @media all and (max-width: 1100px) {
          border-bottom: 1px dashed #acacac;
          margin-bottom: 15px;
          margin-top: 15px;
        }
      }
    }

    &__no-request {
      margin-left: 20.5vw;
      margin-top: 34px;
      line-height: 24px;
      font-weight: 400;
      font-family: 'MontSerrat';
      font-size: 20px;

      @media all and (max-width: 1100px) {
        margin-left: 0;
        font-size: 12px;
      }
    }
  }

  a {
    text-decoration: underline !important;
    color: #0C1931;
  }
}

.button-buy-again{
  padding: 15px 30px
}
.product-name{
  margin-top: 10px;
}

.collapse-request{
  margin-left: 20.5vw;
  width: 43vw;
  background-color: #ffffff;
  border-radius: 25px;
  font-size: 17px;
  font-weight: 600;
  font-family: 'MontSerrat';
  padding: 0.5vh 0;
  border: none;

  @media all and (max-width: 1100px) {
    margin-left: 0;
    width: 90vw;
    font-size: 15px;
  }

  &__panel{
    border: none !important;
  }
}