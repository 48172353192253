.promo-section {
  padding: 0 0 75px 0;
  width: 70%;
  margin: 0 auto 60px auto;

  @media all and (max-width: 1100px) {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  &__wrapper {

    @media all and (max-width: 1100px) {
      display: flex;
      flex-direction: column-reverse !important;
    }
  }

  &__inner {

    &__title {
      font-family: 'Roboto', sans-serif;
      font-size: 16pt;
      line-height: 30pt;
      font-weight: bold;
      text-align: center;
      padding-bottom: 30px;

      &__text{
        font-weight: 700;
        font-size: 30pt;
        color: #0C1931;

        @media all and (max-width: 1100px) {
          font-size: 20pt;
        }
      }

      @media all and (max-width: 1100px) {
        font-size: 11px;
        line-height: 23px;
        padding-bottom: 15px;
      }
    }

    &__product {
      border: 1px solid $gray-text;
      border-radius: 10px;
      padding: 50px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &__item {
        margin: 0;

        &__title {
          color: $dark-blue;
          font-weight: 700;
          font-size: 50px;
          margin: 0;

          @media all and (max-width: 1100px) {
            font-size: 30px;
            padding-top: 15px;
          }
        }

        &__description {
          color: $dark-blue;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin: 0 0 15px 0;
          width: 60%;

          @media all and (max-width: 1100px) {
            width: 100%;
          }

          &--bottom {
            font-size: 15px;
            line-height: 18px;
          }
        }

        &__price {
          padding-top: 15px;

          &__gray {
            font-size: 25px;
            color: $gray-product;
            text-decoration: line-through;
            margin-bottom: -10px;

            @media all and (max-width: 1100px) {
              font-size: 20px;
            }
          }

          &__black {
            font-size: 30px;
            color: $price-color;
            font-weight: 700;

            @media all and (max-width: 1100px) {
              font-size: 25px;
            }
          }
        }

        &__button {
          background-color: $primary-color;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          font-weight: 700;
          font-size: 18px;
          height: 57px;
          width: 70%;
          margin: 45px 0 0 0;
          border-radius: 10px;
          transition: all ease-in-out 200ms;

          @media all and (max-width: 1100px) {
            margin: 30px 0 0 0;
          }

          &:hover {
            background-color: lighten($primary-color, 10);
          }
        }

        &__obs {
          font-size: 15px;
          color: $gray-text;
          margin: 15px 0 0 0;
          font-weight: 300;
        }
      }
    }

    @media screen and (max-width: 1100px) {

      &__product {
        padding: 15px;
        flex-direction: column;

        &__item {
          margin: 0;

          &__button {
            width: 100%;
            font-size: 13px;
          }
        }

        img {
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1368px) {

  .promo-section {
    width: 100%;
  }
}
