.checkout-floating-bar {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: $default-shadow;
  z-index: 500;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 1100px) {
    position: relative;
    box-shadow: none;
    padding: 15px 0;
    margin: 15px 0 0;
    justify-content: flex-end;
    background-color: transparent;
  }

  &__meta {
    border-top: 1px solid #d1d1d1;

    @media all and (max-width: 1100px) {
      padding: 0;
    }

    &__value-single {
      padding: 0 30px;
      font-family: 'PrimeformProRegular', sans-serif;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 18px;
      padding-bottom: 5px;

      @media all and (max-width: 1100px) {
        font-size: 14px;
      }

      &__subtotal {
        padding: 25px 30px 0;
        font-family: 'PrimeformProRegular', sans-serif;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
        padding-bottom: 5px;

        @media all and (max-width: 1100px) {
          font-size: 14px;
        }

        &__title {
          color: #626262;
        }

        &__value {
          color: #474747;
          font-weight: 500;
          letter-spacing: -2px;
        }
      }

      &__title {
        color: #626262;
      }

      &__value {
        color: #474747;
        font-weight: 500;
        font-family: 'PrimeformProSemiBold';
        letter-spacing: -2px;

        &__text-green {
          color: #61be91;
          font-weight: 600;
          font-family: 'PrimeformProSemiBold';
          letter-spacing: 0;
        }

        &__inner {
        }
      }

      &--total {
        margin-top: 30px;
        padding: 25px;
        border-top: 1px solid #d1d1d1;

        .checkout-floating-bar__meta__value-single__title {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &--value {
          font-size: 30px;
          font-family: 'PrimeformPro';
          letter-spacing: -3px;

          &__small {
            font-size: 20px;
            font-weight: normal;
          }
        }
      }
    }

    &__subtotal {
      text-decoration: line-through;

      &__title {
      }
    }

    &__total {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      display: flex;
      flex-direction: row;

      @media all and (min-width: 1100px) {
        font-size: 15px;
      }

      &__coupon {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    &__quantity {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      padding: 0 !important;
    }
  }

  &__actions {
    &__button {
      font-size: 13px;
      color: white;
      font-weight: bold;
      background-color: $primary-color;
      border: none;
      border-radius: 6px;
      outline: 0;
      padding: 5px 10px;
      text-transform: uppercase;
      cursor: pointer;

      @media all and (min-width: 1100px) {
        font-size: 16px;
        padding: 10px 25px;
      }

      &:disabled {
        background-color: #999;
        opacity: 0.3;
        cursor: default;
      }

      &__icon {
        margin-left: 5px;
      }
    }
  }
}
