.count-down {
  width: 71%;

  @media all and (max-width: 1100px) {
    width: 89%;
  }

  &__exclusive-offer {
    color: #0AA979;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 0.9rem;

    @media all and (max-width: 1100px) {
      font-size: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__time {
    display: flex;
    justify-content: center;
    width: 70%;

    &__inner {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @media all and (max-width: 1100px) {
        height: 4rem;
        width: 66%;
      }

      &__text {
        font-size: 1.5vw;
        color: #0AA979;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        margin-top: 1.5vw;

        @media all and (max-width: 1100px) {
          font-size: 4vw;
          margin-top: 3.6vw;
        }
      }

      &__icon {
        margin-left: 5px;
        margin-top: 2px;

        @media all and (max-width: 1100px) {
          margin-top: 0;
        }
      }
    }
  }

}

.ant-statistic {
  display: flex;
}

.ant-statistic-content-value {
  display: block;
  direction: ltr;
  color: #0AA979;
  font-size: 15px;
  padding-left: 6px;
  font-weight: 700;

  @media all and (max-width: 1100px) {
    font-size: 0.5rem;
  }
}

.ant-statistic-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  color: #61BE91;
  font-size: 15px;

  @media all and (max-width: 1100px) {
    font-size: 0.5rem;
  }
}