.track-order {

  &__header {
    z-index: 1;
    position: sticky;
    background: #1C6B9E;
    top: 0;
    left: 0;
    width: 100%;
    height: 69px;
    transition: all 200ms ease-in-out;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

    &__inner {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 20px 0 20px;
      width: 100%;
      justify-content: space-between;

      &__menu {

        &__profile {
          font-family: 'PrimeformPro';
          font-style: normal;
          font-weight: 700;
          color: white;
          letter-spacing: 2px;
          cursor: pointer;
          white-space: nowrap;
          display: flex;
          align-items: center;
          font-size: 17px;
          margin-top: 15px;

          &:hover {
            color: white;

            @media all and (max-width: 1100px) {
              border-bottom: 2px solid transparent;
            }
          }
        }
      }
    }
  }

  &__content {
    height: 100vh;

    &__tracker {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 7vw;

      &__inner {
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &__title {
  
          &__text {
            font-family: 'PrimeformPro';
            font-weight: 700;
            font-size: 20px;
          }

          &__delivery-forecast{
            font-family: 'PrimeformPro';
            font-size: 15px;
          }
        }

        &__input {
          width: 30vw;

          @media all and (max-width: 1100px) {
            width: 100%;
          }
        }

        &__button {
          @media all and (max-width: 1100px) {
            margin-bottom: 10px;
          }
          &__text {
            font-size: 15px;
            text-transform: none;
          }
        }
      }
    }
  }

  &__steps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

    &__content {
      width: 90%;
      margin-top: 10px;

      &__info {
        background-color: #ffe58f;
        font-size: 0.8em;
        padding: 0.5rem;
        border-radius: 0.2rem;
        width: 65%;
        margin: 1rem;

        @media all and (max-width: 1100px) {
          width: 95%;
          font-size: 1em;
        }
      }
    }
  }
}

.content-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.container-buttons {
  display: flex;
  gap: 20px;

  @media all and (max-width: 1100px) {
    display: block;
  }
}

.ant-steps-item-process.ant-steps-item-icon {
  border-color: #1746E0 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #1746E0 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1746E0 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #1746E0 !important;
}

.anticon.anticon-check.ant-steps-finish-icon {
  color: #1746E0 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #c4c0c0 !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #c4c0c0 !important;
}