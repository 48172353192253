.about {
  padding: 50px;
  max-width: 1600px;
  margin: auto;

  @media all and (max-width: 1100px) {
    max-width: 600px;
    padding: 15px;
  }

  &__banner, &__end-banner {
    width: 100%;
    position: relative;

    &__text {
      position: absolute;
      z-index: 100;
      left: 10%;
      top: 28%;
    
      @media all and (max-width: 1100px) {
        text-align: center;
        top: 8%;
        left: 0;
        width: 100%;

      }

      &__blue {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 55px;
        color: #1D6EA3;
        margin-bottom: 0px;

        @media all and (max-width: 1400px) {
          font-size: 32px;
        }
        
        @media all and (max-width: 1300px) {
          font-size: 28px;
        }

        @media all and (max-width: 1100px) {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          padding: 0 10%;
          margin-bottom: 5px;
        }
      }

      &__sub-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 43px;
        color: #000000;

        @media all and (max-width: 1400px) {
          font-size: 25px;
        }

        @media all and (max-width: 1300px) {
          font-size: 22px;
        }
        
        @media all and (max-width: 1100px) {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;         
          color: #000000;
        }
      }
    }

    &__img {
      width: 100%;
    }
  }

  &__end-banner {
    padding: 0 110px;
    margin-top: 160px;

    @media all and (max-width: 1100px) {
      padding: 0 12px;
      margin-top: 117px;
    }

    &__img {
      image-rendering: -webkit-optimize-contrast;
    }
  }

  &__info {
    margin-left: 110px;
    width: 55%;

    @media all and (max-width: 1100px) {
      margin-left: 0px;
      margin: 0 12px;
      width: auto;
    }

    &__text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #000000;

      @media all and (max-width: 1100px) {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px; 
        color: #000000;
      }
    }
  }

  &__investment {
    margin-top: 120px;
    margin-left: 110px;
    width: 35%;

    @media all and (max-width: 1100px) {
      margin-left: 12px;
      width: 90%;
      margin-top: 80px;
    }

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 40px;
    }

    &__images {
      display: flex;
      justify-content: space-between;
      
      a {
        width: 45%;
        display: flex;
        align-items: center;
      }
    }

    &__img {
      image-rendering: -webkit-optimize-contrast;
      width: 100%;
    }
  }

  &__timelapse {
    margin-top: 230px;
    margin-left: 110px;
    margin-right: -40px;
    
    @media all and (max-width: 1100px) {
      display: none;
    }

    &--mobile {
      margin-left: 60px;
      margin-top: 80px;
    }

    &__img {
      width: 100%;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  &__media {
    margin: 0 110px;
    margin-top: 200px;

    @media all and (max-width: 1100px) {
      margin: 0 25px;
      margin-top: 150px;
    }

    &__title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 70px;

      @media all and (max-width: 1100px) {
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 40px;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media all and (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content: space-around;
      }

      a {
        img {
          image-rendering: -webkit-optimize-contrast;
          height: 55px;

          @media all and (max-width: 1100px) {
            height: 23px;
            margin-bottom: 30px;
          }
        }
      }
    }

    &__tec {
      margin: 0 110px;
      margin-top: 230px;

      @media all and (max-width: 1100px) {
        margin: 0 12px;
        margin-top: 110px;
      }

      &__img {
        width: 100%;

        @media all and (max-width: 1100px) {
          image-rendering: -webkit-optimize-contrast;
        }
      }
    }
    
    &__rating {
      position: relative;
      margin-top: 230px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 110px;

      @media all and (max-width: 1100px) {
        margin-top: 100px;
        padding: 0 12px;
      }

      &__arrow {
        position: absolute;
        cursor: pointer;

        &--back {
          left: 0;
          top: 50%;
        }
        &--forward {
          right: 0;
          top: 50%;
        }
      }

      &__slick {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media all and (max-width: 1100px) {
          margin-top: 100px;
          display: block;

          .slick-slider .slick-list {
            padding-left: 0px !important;
          }
        }
      }

      &__item {
        max-height: 350px;
        image-rendering: -webkit-optimize-contrast;

        @media all and (max-width: 1400px) {
          max-height: 250px;
        }

        @media all and (max-width: 1100px) {
          padding-right: 20%;
          max-height: 400px;
        }
      }
    }
  }
}
