.talk-profile {
  padding: 50px;
  margin: 50px auto;
  background-color: $background-gray;

  &__column {
    margin: 0 auto;
  }

  &__title {
    margin: 0 auto 30px auto;
    text-align: center;
    font-size: 48px;
    color: $primary-color;
    font-weight: 700;
    letter-spacing: 7px;
  }

  &__spin {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 1em;
  }

  &__aux {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__btn {
      width: 13% !important;
      margin: 10px 40px;

      &__button {
        width: 195px;
        height: 52px;
        background-color: $primary-color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        cursor: pointer;
        border-radius: 10px;
      }
    }

    &__input {
      width: 48% !important;
      margin: 10px 15px;
    }

    &__input-large {
      width: 100% !important;
      margin: 10px 15px;

    }

    &__textarea {
      width: 100% !important;
      margin: 10px 15px;

      input {
        height: 200px !important;
      }
    }

    &__input-button {
      width: 100%;
      margin: 30px 0;
      display: flex;
      justify-content: center;
    }
  }
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.PrivateNotchedOutline-root-1 {
  border-radius: 10px;
}

.container {
  width: 90%;
  max-width: 90% !important;

  @media all and (max-width: 1100px) {
    width: 100%;
    max-width: 100% !important;
  }
}
