.search {
    padding: 0;
    width: 100%;
    height: 100%;

    &__row {
        margin: 40px auto 80px auto;

        &__input {
            background-color: $white;
            width: 100%;
            border-bottom: 1px solid $border-input;
            padding: 10px;
            color: $border-input;
            border-top: none;
            border-left: none;
            border-right: none;
            margin: 50px 0 0 0;
            background-image: url(../../../assets/svg/ic_search.svg)!important;
            background-repeat: no-repeat;
            background-position-x: 90%!important;
            background-position-y: 45%!important;
        }
    }

}
