.promo-section-two {
  padding: 0;
  width: 70%;
  margin: 0 auto 60px auto;

  &__inner {

    &__title {
      font-size: 16pt;
      font-weight: bold;
      text-align: center;
    }

    &__product {
      border: 1px solid $gray-text;
      border-radius: 10px;
      padding: 60px;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;

      &__item {
        margin: 0 80px 0 0;
        order: 1;
        margin: 0 0 0 60px;

        &__title {
          color: $dark-blue;
          font-weight: 700;
          font-size: 60px;
          margin: 0;
        }

        &__description {
          color: $dark-blue;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin: 0 0 15px 0;
        }

        &__price {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &__gray {
            font-size: 25px;
            color: $gray-text;
            font-weight: 600;
            margin: 0 20px 0 0;
          }

          &__black {
            font-size: 30px;
            color: $dark-blue;
            font-weight: 700;
            margin: 0;
          }
          
        }

        &__button {
          background-color: $primary-color;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          font-weight: 700;
          font-size: 18px;
          height: 57px;
          width: 70%;
          margin: 70px 0 0 0;
          border-radius: 10px;
        }

        &__obs {
          font-size: 15px;
          color: $gray-text;
          margin: 15px 0 0 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1368px){
  
  .promo-section-two {
    width: 100%;
  }
}