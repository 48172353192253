.advanced-freight {
	width: 40vw;
	display: flex;
	border-radius: 2rem;
	background-color: #FFFFFF;
	flex-direction: column;
	padding: 2rem;

	&__content-first-line {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	
		&__street {
			width: 80%
		}
	
		&__addressNumber {
			width: 20%;
		}
	}

	&__content-second-line {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		margin-top: 1rem;

		&__neighborhood {
			width: 60%;
		}
	
		&__city {
			width: 40%;
		}
	}

	&__content-third-line {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		margin-top: 1rem;

		&__uf {
			width: 30%;
		}
	
		&__complement {
			width: 80%;
		}
	}

	&__freight-options {
		display: flex;
		width: 100%;
		padding: 1rem;
		background-color: $primary-color;
		border-radius: 1.5rem;
		margin-top: 1rem;
		flex-direction: column;

		h4 {
			color: #fff;
		}
	}

	@media screen and (max-width: 1100px) {
		width: 100%;
    }

	@media screen and (max-width: 995px) {
		width: 100%;
		padding: 0.5rem;
	}
}	