.checkout-item-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid #ededed;

  &--last {
    margin-bottom: 0;
    border-bottom: none;
  }

  &__thumb {
    &__img {
      width: 190px;
      height: 190px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      @media all and (max-width: 1100px) {
        width: 122px;
        height: 132px;
      }
    }
  }

  &__meta {
    flex: 1 1 0;
    margin: 0 15px 0;

    &__title {
      font-family: 'PrimeformProMedium';
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 5px;
      color: #626262;
      letter-spacing: 0.05em;
    }

    &__tag {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      font-family: 'PrimeformPro';
    }

    &__price {
      &__old {
        font-family: 'PrimeformPro', sans-serif;
        font-size: 11px;
        color: #666;
        text-decoration: line-through;
      }

      &__final {
        font-family: 'PrimeformPro', sans-serif;
        font-size: 14px;

        &__old {
          font-size: 15px;
          color: #666;
          text-decoration: line-through;
        }
      }
    }

    &__actions {
      margin-top: 112px;

      @media all and (max-width: 1100px) {
        margin-top: 58px;
      }
    }
  }

  &__remove {
    transform: translate(0, 0);
  }
}

.checkout-item-card-mobile {
  display: flex;
  flex-direction: row;
  padding: 0 0 10px 20px;

  &--last {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 30px;
    padding-top: 5px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  &__quantity {
    position: absolute;
    left: -11px;
    padding: 4px;
    background: #f2f2f2;
    color: #474747;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 26px;
    height: 26px;
    text-align: center;
    font-weight: 700;
  }

  &__thumb {
    position: relative;
    &__img {
      width: 65px;
      height: 70px;
      border-radius: 10px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &__meta {
    flex: 1 1 0;
    margin: 0 18px 0 10px;
    display: flex;
    flex-direction: column;
    padding: 15px 0 0 0;

    &__title {
      font-family: 'PrimeformProMedium';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #626262;
    }

    &__tag {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      font-family: 'PrimeformPro';
    }

    &__price {
      &__old {
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        color: #666;
        text-decoration: line-through;
      }

      &__final {
        font-family: 'PrimeformPro';
        display: block;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px;

        &__old {
          font-size: 15px;
          color: #666;
          text-decoration: line-through;
        }
      }
    }

    &__actions {
      margin-top: 112px;

      @media all and (max-width: 1100px) {
        margin-top: 58px;
      }
    }

    &__button {
      display: flex;
      justify-content: space-between;
      gap: 13px;
      &__whats {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background: #ffffff;
        border: 1px solid #000000;
      }

      &__cart {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background: #0f6ca8;
        box-shadow: 3px 3px 20px #1d6ea3;
        border: 1px solid #0f6cab;
      }
    }
  }

  &__remove {
    transform: translate(0, 0);
  }
}
