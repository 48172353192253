.coupon-card {
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &_meta {

    &__title {
      color: $primary-color;
      font-size: 15px;
      font-weight: bold;
    }

    &__value {
      font-size: 15px;
      font-weight: bold;
    }
  }

  &__remove {

    &__button {
      font-size: 20px;
    }
  }
}
