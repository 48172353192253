.checkout__footer__top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F2F2F2;

  &__guarantee {
    margin-bottom: 6px;

    &__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #626262;
      font-family: 'PrimeformProMedium';
      margin-top: 1rem;
    }
  }
}

.checkout__footer__bottom {
  background: linear-gradient(180deg, #1d6ea3 0%, #025175 100%);
  padding: 0 0 70px 0;

  &__address {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 57px);
    font-family: 'PrimeformProMedium';

    @media all and (max-width: 1100px) {
      transform: translate(0, 0);
      padding: 0 40px;
      padding-bottom: 20px;
    }

    &__icon {
      padding-right: 5px;

      @media all and (max-width: 1100px) {
        width: 35px;
        height: 35px;
        padding-right: 0px;
        margin-right: 20px;
      }
    }
  }

  &__payment {
    display: grid;
    justify-content: center;
    align-items: center;
    transform: translate(0, 105px);

    &__title {
      margin-left: 90px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      font-family: 'PrimeformProRegular';
    }
  }

  &__terms {
    display: flex;
    justify-content: center;
    text-decoration-line: underline;
    color: #ffffff;
    margin: 150px 0 50px 0;

    &__link {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-decoration: underline;
      color: #ffffff;
      font-family: 'PrimeformProRegular';
    }

    &__items {
      margin: 0 25px;
    }
  }

  &__info {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin: 0 89px;
    font-family: 'PrimeformProRegular';
  }

  &__rights {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    font-family: 'PrimeformProRegular';
  }
}

.checkout__footer__mobile__top {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;

  &__guarantee {
    margin-left: 58px;
    margin-bottom: 6px;

    img {
      @media all and (max-width: 1100px) {
        margin-top: 3rem;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #626262;
      font-family: 'PrimeformProMedium';
    }
  }
}

.checkout__footer__mobile__top {
  text-align: center;

  &__guarantee{
    margin-left: 0;
  }
}

.checkout__footer__mobile__bottom {
  background: linear-gradient(180deg, #1d6ea3 0%, #025175 100%);
  padding: 0 0 15px 0;
  margin-top: 30px;

  @media all and (max-width: 1100px) {
    margin-top: 0;
  }

  &__address {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0, 57px);

    @media all and (max-width: 1100px) {
      transform: translate(0, 0);
      padding: 30px 40px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &__icon {
      padding-right: 5px;

      @media all and (max-width: 1100px) {
        width: 35px;
        height: 35px;
        padding-right: 0px;
        margin-right: 20px;
      }
    }

    &__text {
      color: #ffffff;
      text-align: center;
      line-height: 16px;
      font-size: 14px;
      padding: 15px 0;
      font-family: 'PrimeformProMedium';
    }
  }

  &__payment {
    display: grid;
    justify-content: center;
    align-items: center;
    transform: translate(0, 105px);

    @media all and (max-width: 1100px) {
      transform: translate(0, 0px);
    }

    &__title {
      text-align: center;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      font-family: 'PrimeformProRegular';
    }

    @media all and (max-width: 1100px) {
      &__cards {
        width: 332px;
      }
    }
  }

  &__terms {
    &__link {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-decoration: underline;
      color: #ffffff;
      text-align: center;
      font-family: 'PrimeformProRegular';
    }

    &__items {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      text-decoration-line: underline;
      color: #ffffff;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }

    &__item {
      display: flex;
      justify-content: center;
      text-decoration-line: underline;
      color: #ffffff;
      margin-bottom: 28px;
    }
  }

  &__info {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin: 0 89px;
    font-family: 'PrimeformProRegular';

    @media all and (max-width: 1100px) {
      color: #ffffff;
      font-size: 10px;
      letter-spacing: 0.03em;
      font-weight: 400;
      line-height: 11px;
      text-align: center;
      padding: 0 25px;
      margin: 0;
    }
  }

  &__rights {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    font-family: 'PrimeformProRegular';
  }
}
