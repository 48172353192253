.profile-content {
  display: flex;
  flex-direction: column;

  &__aux {

    @media all and (max-width: 1100px) {
      flex-direction: column;
    }
  }
}