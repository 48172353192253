.our-products {
    @media all and (max-width: 1100px) {
        margin-left: -15px;
        margin-right: -15px;
    }

    .slick-list {
        padding-left: 0 !important;
    }

    &__title {
        margin-top: 9rem;
        margin-bottom: 3rem;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 43px;
        text-align: center;

        @media all and (max-width: 600px) {
            font-size: 20px;
            line-height: 24px;
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
    }

    &__container {
        display: grid;
        max-width: 850px;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        @media all and (max-width: 1100px) {
            display: block;
        }
    }

}