.top-bar {
  background-color: #1D6EA3;
  padding: 7px 0;
  transition: height 230ms ease-in-out;

  &__purple {
    background-color: #924DA9;
    padding: 7px 0;
    transition: height 230ms ease-in-out;
  }

  &__blue {
    background-color: #6DA7CB;
    padding: 7px 0;
    transition: height 230ms ease-in-out;
  }

  &__logo {
    text-align: center;

    &__wrapper {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      display: block;
      cursor: pointer;
      color: $white;
      font-weight: bold;

      &__text {
        color: #AAFFD6;

        &__purple {
          color: #de8ef8;
        }
      }

      &:hover {
        color: $white;
      }

      &__button {
        color: #000;
        border-radius: 50px;
        border: none;
        font-size: 12px;
        margin-left: 17px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        background-color: #fff;
        cursor: pointer;

        @media all and (max-width: 1100px) {
          display: none;
        }
      }
    }

    &__img {

      @media all and (max-width: 1100px) {
        height: 15px;
      }
    }
  }
}