.our-products-card {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 265px;
    height: 300px;
    margin: 0 auto;
    background-position: center;
    border-radius: 15px;

    @media all and (max-width: 1100px) {
        width: 154px;
        height: 174px;
    }

    &__top {
        position: absolute;
        top: 15px;
        left: 18px;

        @media all and (max-width: 1100px) {
            top: 12px;
            left: 15px;
        }

        &__title {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
            margin-bottom: -8px;

            @media all and (max-width: 1100px) {
                font-size: 12px;
                margin-bottom: 0;
            }
        }

        &__price {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #000000;

            @media all and (max-width: 1100px) {
                font-size: 12px;
            }
        }
    }

    &__label {
        position: absolute;
        top: 68px;
        left: 18px;
        background: white;
        border-radius: 50px;
        padding: 4px 12px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 8px;

        &--sold-off {
            background: red;
            color: #FFF;
        }

        @media all and (max-width: 1100px) {
            font-size: 6px;
            top: 55px;
            left: 15px;
            padding: 2px 8px;
        }
    }

    &__bottom {
        position: absolute;
        top: 76%;
        left: 72%;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        display: flex;
        align-self: center;
        justify-content: center;
        cursor: pointer;

        @media all and (max-width: 1100px) {
            width: 50px;
            height: 50px;
            top: 65%;
            left: 60%;
        }

        &__icon {
            margin: auto;
            width: 20px;
            height: 28px;

            @media all and (max-width: 1100px) {
                width: 14px;
                height: 18px;
            }
        }
    }
}