@font-face {
  font-family: MontSerrat;
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
}

.email_field {
  width: 100%;
}

.register-modal {
  padding: 100px 0 100px;
  display: flex;
  justify-content: space-between;
  height: 100vh;

  @media all and (max-width: 1100px) {
    height: 140vh;

  }

  &__title {
    text-align: center;
    font-weight: bolder;
    font-size: 2vw;
    line-height: 2rem;
    color: #000;
    font-family: 'Montserrat';
    margin-top: 5vw;

    @media all and (max-width: 1100px) {
      margin-top: 15vw;
      font-size: 6vw;
  
    }
  }

  &__secondary {
    font-weight: 400;
    font-size: 1vw;
    text-align: center;
    font-family: 'Montserrat';

    @media all and (max-width: 1100px) {
      text-align: center !important;
      font-size: 3vw;

    }
  }

  &__account {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    margin-top: 3rem;
    margin-bottom: 0px;
    font-family: 'Montserrat';
  }

  &__login {
    font-size: 16px;
    text-align: center;
    color: #000;
    font-weight: bolder;
    text-decoration: underline;
    font-family: 'Montserrat';
    display: list-item;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }

  &__aux {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 46px 0 46px;

    &__input {
      width: 49% !important;
      border: 1px solid #000000 !important;
      border-radius: 15px;
      margin: 10px 0;
      height: 6vh;

      @media all and (max-width: 1100px) {
        width: 36.5vw;
        justify-content: space-between;
      }

      input {
        outline: none;
        outline: none;
        border: none;
        background-color: transparent;
        width: 70%;
        font-size: 1vw;
        font-family: 'Montserrat';
        height: 5.5vh;
        margin-left: 1vh;
      
        @media all and (max-width: 1100px) {    
          font-size: 3vw;
          border-radius: 2vh;
          height: 5.2vh; 
        }

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::placeholder {
          font-family: 'Montserrat', sans-serif;
          color: #626262;
        }

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }

    &__input-large {
      width: 100% !important;
      margin: 10px 0;
      border: 1px solid #000000 !important;
      border-radius: 15px;
      height: 6vh;

      input {
        outline: none;
        outline: none;
        border: none;
        background-color: transparent;
        width: 70%;
        font-size: 1vw;
        font-family: 'Montserrat';
        height: 5.5vh;
        margin-left: 1vh;

        @media all and (max-width: 1100px) {
          font-size: 3vw;
          border-radius: 2vh;
          height: 5.2vh;   
        }

        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::placeholder {
          font-family: 'Montserrat', sans-serif;
          color: #626262;
        }

        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }

    &__button {
      width: 100%;
      height: 68px;
      background-color: #025175;
      font-weight: 700;
      font-size: 1rem;
      color: #fff;
      border: none;
      border-radius: 3.5rem;
      margin-top: 2vh;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-family: 'Montserrat';

      &:hover {
        opacity: 0.9;
        transition: 0.3s;

      }
    }
  }
}